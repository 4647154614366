<!--
Array Answer Card

Renders a card for each of the multiple answer fields in the Question's form.
Each card iterates through the incoming array and renders the contents as a list.

As Sites is an object, check id the current array is labelled 'Sites' and render it's SiteName property.
-->
<template>
	<v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">

		<v-card class="ma-1 pa-4">

			<!--Title-->
			<app-text category="text-default-bold" class="primary--text">{{ label }}</app-text>

			<!--List items-->
			<ul v-for="item in array">
				<li>
					<app-text v-if="$props.label === 'Sites'" category="text-default">{{ item.siteName }}</app-text>
					<app-text v-else category="text-default">{{ item }}</app-text>
				</li>
			</ul>

		</v-card>

	</v-col>
</template>

<script>
export default {
	props: ['label', 'array']
}
</script>
