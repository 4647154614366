import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyBeD3KjYNj866QHjRaB15aKbrOitpHJU2w",
  authDomain: "vindico-swapp.firebaseapp.com",
  projectId: "vindico-swapp",
  storageBucket: "vindico-swapp.appspot.com",
  messagingSenderId: "304222071060",
  appId: "1:304222071060:web:123583c84c054bd9ae7a01",
  measurementId: "G-4WZCV6RYB1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const fv = firebase.firestore.FieldValue;

export {
  db,
  storage,
  auth,
  currentUser,
  fv
};
