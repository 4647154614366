<template>
	<div>
		<home msg="Homepage"/>
	</div>
</template>

<script>
// @ is an alias to /src
import home from '@/components/home.vue'

export default {
	name: 'Home',
	components: {
		home
	}
}
</script>
