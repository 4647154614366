<!--
Observation

Renders the form (right side panel) for Observations which allows the user to add/edit/delete an observation.
This are split into tabs for: Report |  Response | Evidence.
-->
<template>
    <div>

        <!--Header-->
        <v-row class="grey lighten-2 ma-0 pa-0 pl-2 d-flex align-center">

            <!--Form title and Status-->
            <div class="d-flex align-center ml-2" style="height: 48px">
                <v-icon v-if="form.observationStatus === 'PENDING'" class="icons8-circle" color="accent"/>
                <v-icon v-if="form.observationStatus === 'RESOLVED'" class="icons8-checkmark-yes" color="success"/>

                <app-text v-if="!form.observationName" category="text-default" class="pl-2">
                    {{ $t(`concerns.form.title.new`) }}
                </app-text>
                <app-text v-else category="text-default" class="pl-2 d-inline-block text-truncate">
                    {{ form.observationName }}
                </app-text>
            </div>

        </v-row>

        <!--Page Content-->
        <v-row class="mt-0" no-gutters>

            <!--Tabs (Report | Response | Evidence)-->
            <v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">
                <v-tabs-slider color="primary" background-color="black"></v-tabs-slider>

                <!--Report-->
                <v-tab href="#report">
                    <app-text category="text-small">Report</app-text>
                </v-tab>

                <!--Response-->
                <v-tab href="#response">
                    <app-text category="text-small">Response</app-text>
                </v-tab>

                <!--Evidence-->
                <v-tab v-if="formMode !== 'New'" href="#evidence">
                    <app-text category="text-small">{{ $t(`concerns.form.tabs.photos.tabName`) }}
                        ({{ calculateArrayLength(files) }})
                    </app-text>
                </v-tab>

            </v-tabs>

            <!--Tab Content (Report | Response | Evidence)-->
            <v-tabs-items v-model="tabs" class="appbackground">

                <!--Reporting Tab Content-->
                <v-tab-item value="report" class="pa-0">

                    <!--Action buttons-->
                    <v-row class="ma-4 mb-0 ml-5" no-gutters>
                        <v-col class="d-flex">

                            <!--Destroy-->
                            <appbutton v-if="
								formMode !== 'New'
								&& form.delete !== undefined
								&& ['DA'].includes(GET_currentUser.userLevel)"
                                       @click.native="destroyItem(form.id)"
                                       class="mr-2"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-fire"
                                       :label="$t(`buttons.destroy`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="purple"/>

                            <!--Undelete-->
                            <appbutton v-if="
								formMode !== 'New'
								&& form.delete !== undefined
								&& ['DA'].includes(GET_currentUser.userLevel)"
                                       @click.native="undeleteItem(form.id)"
                                       class="mr-2"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-process"
                                       :label="$t(`buttons.undelete`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="purple"/>

                            <!--Delete-->
                            <appbutton v-if="
								formMode !== 'New'
								&& form.delete === undefined
								&& ['SA', 'DA'].includes(GET_currentUser.userLevel)"
                                       @click.native="deleteItem(form.id)"
                                       class="mr-1"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-trash"
                                       :label="$t(`buttons.delete`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="error"/>

                            <!--Edit-->
                            <appbutton v-if="
									formMode !== 'New'
									&& form.observationStatus === 'PENDING'
									|| (form.observationStatus === 'RESOLVED' && ['SA', 'DA'].includes(GET_currentUser.userLevel))"
                                       @click.native="editItem()"
                                       class="mr-2"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-edit-property"
                                       :label="$t(`buttons.edit`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="warning"/>

                        </v-col>
                    </v-row>

                    <!--Form-->
                    <v-form ref="form">
                        <v-row class="ma-0 px-4" no-gutters>

                            <!--Name-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="8">
                                <form-field-container>
                                    <v-text-field :background-color="formBackground"
                                                  :disabled="formReadOnly"
                                                  :error="!formReadOnly ? errors.observationName : errors.observationName = false"
                                                  :error-messages="errors.observationName ? errors.observationNameErrorMessage : ''"
                                                  filled
                                                  hide-details="auto"
                                                  label="Observation Name"
                                                  v-model="form.observationName"/>
                                </form-field-container>
                            </v-col>

                            <!--Types-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
                                <form-field-container>
                                    <v-select :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.observationType : errors.observationType = false"
                                              :error-messages="errors.observationType ? errors.observationTypeErrorMessage : ''"
                                              filled
                                              hide-details="auto"
                                              :items="observationTypesOptionsData"
                                              label="Type"
                                              v-model="form.observationType">
                                    </v-select>
                                </form-field-container>
                            </v-col>

                            <!--Description-->
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <form-field-container>
                                    <v-textarea auto-grow
                                                :background-color="formBackground"
                                                :disabled="formReadOnly"
                                                :error="!formReadOnly ? errors.observationReportingDescription : errors.observationReportingDescription = false"
                                                :error-messages="errors.observationReportingDescription ? errors.observationReportingDescriptionErrorMessage : ''"
                                                filled
                                                hide-details="auto"
                                                label="Description"
                                                rows="4"
                                                v-model="form.observationReportingDescription"/>
                                </form-field-container>
                            </v-col>

                            <!--Site-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                                <form-field-container>
                                    <v-autocomplete auto-select-first
                                                    :background-color="formBackground"
                                                    :disabled="formReadOnly"
                                                    :error="!formReadOnly ? errors.observationSite : errors.observationSite = false"
                                                    :error-messages="errors.observationSite ? errors.observationSiteErrorMessage : ''"
                                                    filled
                                                    hide-details="auto"
                                                    :items="sitesCollectionData"
                                                    item-text="siteName"
                                                    label="Site"
                                                    return-object
                                                    v-model="form.observationSite">
                                    </v-autocomplete>
                                </form-field-container>
                            </v-col>

                            <!--Response Required-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                                <form-field-container>
                                    <v-select :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.observationResponseTime : errors.observationResponseTime = false"
                                              :error-messages="errors.observationResponseTime ? errors.observationResponseTimeErrorMessage : ''"
                                              filled
                                              hide-details="auto"
                                              :items="responseTimesOptionsData"
                                              label="Response Required"
                                              v-model="form.observationResponseTime">
                                    </v-select>
                                </form-field-container>
                            </v-col>

                        </v-row>
                    </v-form>

                    <!--Action buttons-->
                    <v-row class="ma-4 mx-5" no-gutters>
                        <v-col class="d-flex">

                            <!--Cancel-->
                            <v-btn v-if="formMode === 'Edit'" @click.native="cancelItem()"
                                   color="grey" depressed :height="buttonSizeDefault">
                                <v-icon class="icons8-cancel"/>
                                <app-text category="text-default" class="ml-2">Cancel</app-text>
                            </v-btn>

                            <v-spacer/>

                            <!--Save-->
                            <v-btn v-if="formMode === 'Edit' || formMode === 'New'" @click.native="validateForm()"
                                   class="d-flex align-center" color="success" depressed :height="buttonSizeDefault">
                                <v-icon class="icons8-save"/>
                                <app-text category="text-default" class="ml-2">Save</app-text>
                            </v-btn>

                        </v-col>
                    </v-row>

                </v-tab-item>

                <!--Response Tab Content-->
                <v-tab-item value="response" class="pa-0">

                    <!--Action buttons-->
                    <v-row class="ma-4 mb-0 ml-5" no-gutters>
                        <v-col class="d-flex">

                            <!--Destroy-->
                            <appbutton v-if="
								formMode !== 'New'
								&& form.delete !== undefined
								&& ['DA'].includes(GET_currentUser.userLevel)"
                                       @click.native="destroyItem(form.id)"
                                       class="mr-2"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-fire"
                                       :label="$t(`buttons.destroy`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="purple"/>

                            <!--Undelete-->
                            <appbutton v-if="
								formMode !== 'New'
								&& form.delete !== undefined
								&& ['DA'].includes(GET_currentUser.userLevel)"
                                       @click.native="undeleteItem(form.id)"
                                       class="mr-2"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-process"
                                       :label="$t(`buttons.undelete`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="purple"/>

                            <!--Delete-->
                            <appbutton v-if="
								formMode !== 'New'
								&& form.delete === undefined
								&& ['SA', 'DA'].includes(GET_currentUser.userLevel)"
                                       @click.native="deleteItem(form.id)"
                                       class="mr-1"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-trash"
                                       :label="$t(`buttons.delete`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="error"/>

                            <!--Edit-->
                            <appbutton v-if="
									formMode !== 'New'
									&& form.observationStatus === 'PENDING'
									||
									(form.observationStatus === 'RESOLVED' && ['SA', 'DA'].includes(GET_currentUser.userLevel))"
                                       @click.native="editItem()"
                                       class="mr-2"
                                       :class="{ 'disabled': formMode !== 'View' }"
                                       icon="icons8-edit-property"
                                       :label="$t(`buttons.edit`)"
                                       :na="formMode !== 'View'"
                                       showlabel="xlOnly"
                                       type="warning"/>

                        </v-col>
                    </v-row>

                    <!--Form-->
                    <v-form ref="form">
                        <v-row class="ma-0 px-4" no-gutters>

                            <!--Manager's Name-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="8">
                                <form-field-container>
                                    <v-text-field :background-color="formBackground"
                                                  :disabled="!['SA'].includes(GET_currentUser.userLevel) || formReadOnly"
                                                  :error="!formReadOnly ? errors.observationManagersName : errors.observationManagersName = false"
                                                  :error-messages="errors.observationManagersName ? errors.observationManagersNameErrorMessage : ''"
                                                  filled
                                                  hide-details="auto"
                                                  label="Manager's Name"
                                                  v-model="form.observationManagersName"/>
                                </form-field-container>
                            </v-col>

                            <!--Reference number-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
                                <form-field-container>
                                    <v-text-field :background-color="formBackground"
                                                  :disabled="!['SA'].includes(GET_currentUser.userLevel) || formReadOnly"
                                                  :error="!formReadOnly ? errors.observationReferenceNumber : errors.observationReferenceNumber = false"
                                                  :error-messages="errors.observationReferenceNumber ? errors.observationReferenceNumberErrorMessage : ''"                                                  
                                                  filled
                                                  hide-details="auto"
                                                  label="Reference"
                                                  v-model="form.observationReferenceNumber"/>
                                </form-field-container>
                            </v-col>

                            <!--Description-->
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <form-field-container>
                                    <v-textarea auto-grow
                                                :background-color="formBackground"
                                                :disabled="!['SA'].includes(GET_currentUser.userLevel) || formReadOnly"
                                                :error="!formReadOnly ? errors.observationRespondingDescription : errors.observationRespondingDescription = false"
                                                :error-messages="errors.observationRespondingDescription ? errors.observationRespondingDescriptionErrorMessage : ''"                                                  e
                                                filled
                                                hide-details="auto"
                                                label="Description"
                                                rows="4"
                                                v-model="form.observationRespondingDescription"/>
                                </form-field-container>
                            </v-col>

                            <!--Severity-->
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                                <form-field-container>
                                    <v-select :background-color="formBackground"
                                              :disabled="!['SA'].includes(GET_currentUser.userLevel) || formReadOnly"
                                              :error="!formReadOnly ? errors.observationSeverity : errors.observationSeverity = false"
                                              :error-messages="errors.observationSeverity ? errors.observationSeverityErrorMessage : ''"                                                  e
                                              filled
                                              hide-details="auto"
                                              label="Severity"
                                              :items="severitiesOptionsData"
                                              v-model="form.observationSeverity">
                                    </v-select>
                                </form-field-container>
                            </v-col>

                        </v-row>
                    </v-form>

                    <!--Action buttons-->
                    <v-row class="ma-4 mx-5" no-gutters>
                        <v-col class="d-flex">

                            <!--Cancel-->
                            <v-btn v-if="formMode === 'Edit'" @click.native="cancelItem()"
                                   color="grey" depressed :height="buttonSizeDefault">
                                <v-icon class="icons8-cancel"/>
                                <app-text category="text-default" class="ml-2">Cancel</app-text>
                            </v-btn>

                            <v-spacer/>

                            <!--Save-->
                            <v-btn v-if="formMode === 'Edit' || formMode === 'New'" @click.native="validateForm()"
                                   class="d-flex align-center" color="success" depressed :height="buttonSizeDefault">
                                <v-icon class="icons8-save"/>
                                <app-text category="text-default" class="ml-2">Save</app-text>
                            </v-btn>

                        </v-col>
                    </v-row>

                    <!--Meta data-->
                    <v-row v-if="form.id" class="ma-4" no-gutters>
                        <v-col>

                            <v-divider class="mb-4"/>

                            <app-text category="text-default" class="grey--text">
                                Checked by: <span class="font-weight-bold">{{ form.modifiedUserData.userName }}</span>
                                <br>
                                Date: <span class="font-weight-bold">
								{{ $moment(form.modifiedDateTime, 'x').format('ddd DD MMM YYYY @ HH:MM') }}</span>
                            </app-text>
                        </v-col>
                    </v-row>

                </v-tab-item>

                <!--Evidence-->
                <v-tab-item value="evidence" class="pa-0">

                    <v-row class="pa-4" no-gutters>

                        <!--Description-->
                        <v-col class="pa-0" cols="12">

                            <!--Title-->
                            <app-text category="text-default-bold">
                                {{ $t('concerns.form.tabs.photos.tabName') }}
                            </app-text>

                            <v-divider class="my-4"/>

                        </v-col>

                        <!--Photo Upload | Deleted-->
                        <v-col class="d-flex align-center" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                            <!--Photo upload-->
                            <photoupload :allowedtypes="allowedTypes" :docLink="docLink" :folder="folder"/>

                            <!--Deleted-->
                            <div v-if="['DA'].includes(GET_currentUser.userLevel)" class="d-flex align-center">
                                <v-icon class="icons8-trash ml-4 text-h4" color="primary"/>
                                <v-switch @change="getFiles(GET_openItem)" v-model="showDeletedFiles"
                                          class="ml-4 purple--text" color="accent" inset></v-switch>
                            </div>

                        </v-col>

                    </v-row>

                    <!--Photo grid-->
                    <v-row class="ma-0">
                        <transition-group name="fade" class="row ma-0">
                            <v-col v-for="(file, index) in files" :key="file.id" class="d-flex child-flex pa-4"
                                   cols="6" xs="6" sm="6" md="3">

                                <v-img v-if="file.fileURL" @click="openPhoto(index)"
                                       :src="file.fileURL" aspect-ratio="1" class="d-flex align-end"
                                       style="border-radius: 4px !important;">

                                    <!--Photo action buttons-->
                                    <div class="pa-2">

                                        <!--Delete button-->
                                        <v-menu offset-x flat v-if="!file.hasOwnProperty('delete')">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn small v-bind="attrs" v-on="on">
                                                    <v-icon color="error" class="icons8-trash"/>
                                                </v-btn>
                                            </template>
                                            <v-btn @click="deletePhoto(file.id)" small class="error ml-2">CONFIRM
                                            </v-btn>
                                        </v-menu>

                                        <!--Process button-->
                                        <v-menu offset-x flat v-if="file.hasOwnProperty('delete')">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn small v-bind="attrs" v-on="on">
                                                    <v-icon color="primary" class="icons8-process"/>
                                                </v-btn>
                                            </template>
                                            <v-btn @click="undeletePhoto(file.id)" small class="ml-2">CONFIRM
                                            </v-btn>
                                        </v-menu>
                                    </div>

                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-progress-circular indeterminate
                                                                 color="red lighten-5"></v-progress-circular>
                                        </v-row>
                                    </template>

                                </v-img>

                            </v-col>

                        </transition-group>

                    </v-row>

                    <!--Preview image-->
                    <v-img v-if="tempImage" :src="tempImage"
                           class="d-flex justify-start align-start ml-4"
                           style="border-radius: 4px !important;"
                           height="256"
                           width="256"
                           covers/>

                </v-tab-item>

            </v-tabs-items>

        </v-row>

    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import formFieldContainer from "./commonComponents/formFieldContainer"

export default {
    name: "Observations",

    components: {
        formFieldContainer
    },

    data: () => ({
        collection: 'observations',
        collectionTitle: 'Observations',
        collectionItem: 'observation',
        collectionItemTitle: 'Observation',
        concernStatus: [],
        allowedTypes: "image/*",
        form: {
            id: '',
            observationName: '',
            observationType: '',
            observationReportingDescription: '',
            observationSite: {
                id: '',
                siteName: '',
                siteType: '',
                sitePostcode: ''
            },
            observationResponseTime: '',
            observationManagersName: '',
            observationReferenceNumber: '',
            observationRespondingDescription: '',
            observationSeverity: '',
            observationStatus: 'PENDING',
            createdUserData: {},
            createdDateTime: "",
            modifiedUserData: {},
            modifiedDateTime: "",
        },
        errors : {
            observationName: false,
            observationNameErrorMessage : "",
            observationType: false,
            observationTypeErrorMessage: "",
            observationReportingDescription: false,
            observationReportingDescriptionErrorMessage: "",
            observationSite: false,
            observationSiteErrorMessage: "",
            observationResponseTime: false,
            observationResponseTimeErrorMessage: "",

            observationManagersName: false,
            observationManagersNameErrorMessage: "",
            observationReferenceNumber: false,
            observationReferenceNumberErrorMessage: "",
            observationRespondingDescription: false,
            observationRespondingDescriptionErrorMessage: "",
            observationSeverity: false,
            observationSeverityErrorMessage: "",
        },

        formBackground: 'grey lighten-3',
        formMode: 'New', // Show Form in New Mode / Blank
        formReadOnly: false, // Make Form Editable
        files: [],
        folder: '/observations',
        hasImages: false,
        rules: {},
        showDeletedFiles: false, // Show Deleted Comments
        sitesCollectionData: [],
        tabs: 'observation',
        tempImage: '',
        types: "image/*",
    }),

    computed: {
        ...mapGetters({
            GET_openItem: 'GET_openObservation',
            GET_panelLayout: "GET_panelLayout",
            GET_openObservation: "GET_openObservation",
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
            GET_help: 'GET_help'
        }),

        // DocLink for document upload
        docLink() {
            const t = this

            return ({collection: t.collection, documentId: t.form.id})
        },
    },

    methods: {
        ...mapActions({ACT_openItem: "ACT_openObservation", ACT_openObservation: "ACT_openObservation"}),

        /**
         * Get Sites Collection Data
         *
         * Fetch all the data from the collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        getSitesCollectionData: async function() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Rest the data to avoid duplication
                t.sitesCollectionData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesCollectionData.push(document)
                    }

                })
            })
        },

        updateBreadcrumbs() {
            const t = this

            const breadcrumbs = []
            breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
            if (t.GET_openItem.process === 'New') {
                breadcrumbs.push({name: `New ${t.collectionItemTitle}`, path: `/${t.collection}`}) // Set a Secondary Path
            } else {
                breadcrumbs.push({
                    name: `${this.GET_openItem.data[t.collectionItem + 'Name']}`,
                    path: `/${t.collection}/${this.GET_openItem.data.id}`
                }) // Set a Secondary Path
            }
            this.MIX_breadcrumbs(breadcrumbs)
        },

        openItem: async function () {
            const t = this

            // Get open item data
            const openItem = await t.GET_openItem
            switch (openItem.process) {
                case 'New':
                    t.formMode = 'New'
                    t.formReadOnly = false
                    t.formBackground = 'white'
                    break
                case 'View':
                    const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item1.data
                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'
                    break
                case 'Edit':
                    const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item2.data
                    t.formMode = 'Edit'
                    t.formReadOnly = false
                    t.formBackground = 'white'
                    break
            }
        },

        editItem() {
            const t = this

            t.formMode = 'Edit'
            t.formReadOnly = false
            t.formBackground = 'white'
        },

        cancelItem() {
            const t = this

            t.formMode = 'View'
            t.formReadOnly = true
            t.formBackground = 'grey lighten-3'
            t.refreshItemView()
            t.closeRightPanel()
        },

        validateForm() {
            let t = this

            t.errors.observationName = false
            t.errors.observationType = false
            t.errors.observationReportingDescription = false
            t.errors.observationSite = false
            t.errors.observationResponseTime = false

            t.errors.observationManagersName = false
            t.errors.observationReferenceNumber = false
            t.errors.observationRespondingDescription = false
            t.errors.observationSeverity = false


            // REQUIRED - Observation Name must be present and longer than 2 characters
            if (!t.form.observationName) {
                t.errors.observationName = true
                t.errors.observationNameErrorMessage = 'Observation Name is required'
            } else if (t.form.observationName.trim().length < 2) {
                t.errors.observationName = true
                t.errors.observationNameErrorMessage = 'Observation Name must be longer than 2 characters'
            } else {
                t.form.observationName = t.form.observationName.trim()
            }

            // REQUIRED - Observation Type
            if (!t.form.observationType) {
                t.errors.observationType = true
                t.errors.observationTypeErrorMessage = 'Observation Type is required'
            } 

            // REQUIRED - Observation Description must be present and longer than 2 characters
            if (!t.form.observationReportingDescription) {
                t.errors.observationReportingDescription = true
                t.errors.observationReportingDescriptionErrorMessage = 'Observation Description is required'
            } else if (t.form.observationReportingDescription.trim().length < 2) {
                t.errors.observationReportingDescription = true
                t.errors.observationReportingDescriptionErrorMessage = 'Observation Description must be longer than 2 characters'
            } else {
                t.form.observationReportingDescription = t.form.observationReportingDescription.trim()
            }

            // REQUIRED - Observation Site 
            if (!t.form.observationSite.siteName) {
                t.errors.observationSite = true
                t.errors.observationSiteErrorMessage = 'Observation Site is required'
            }

            // REQUIRED - Observation Response Time 
            if (!t.form.observationResponseTime) {
                t.errors.observationResponseTime = true
                t.errors.observationResponseTimeErrorMessage = 'Observation Response Time is required'
            }

            // Response Tab
            // Only validate for staff admin since it is disabled for other users.

            if(['SA'].includes(t.GET_currentUser.userLevel)) {

                // REQUIRED - Manager's Name
                if (!t.form.observationManagersName) {
                    t.errors.observationManagersName = true
                    t.errors.observationManagersNameErrorMessage = 'Manager\'s Name is required'
                } else if (t.form.observationManagersName.trim().length < 2) {
                    t.errors.observationManagersName = true
                    t.errors.observationManagersNameErrorMessage = 'Manager\'s Name must be longer than 2 characters'
                } else {
                    t.form.observationManagersName = t.form.observationManagersName.trim()
                }

                // REQUIRED - Observation Response Reference Number
                if (!t.form.observationReferenceNumber) {
                    t.errors.observationReferenceNumber = true
                    t.errors.observationReferenceNumberErrorMessage = 'Observation Reference Number is required'
                } else if (t.form.observationReferenceNumber.trim().length < 2) {
                    t.errors.observationReferenceNumber = true
                    t.errors.observationReferenceNumberErrorMessage = 'Observation Reference Number must be longer than 2 characters'
                } else {
                    t.form.observationReferenceNumber = t.form.observationReferenceNumber.trim()
                }
                
                // REQUIRED - Observation Response Description
                if (!t.form.observationRespondingDescription) {
                    t.errors.observationRespondingDescription = true
                    t.errors.observationRespondingDescriptionErrorMessage = 'Observation Response Description is required'
                } else if (t.form.observationRespondingDescription.trim().length < 2) {
                    t.errors.observationRespondingDescription = true
                    t.errors.observationRespondingDescriptionErrorMessage = 'Observation Response Description must be longer than 2 characters'
                } else {
                    t.form.observationRespondingDescription = t.form.observationRespondingDescription.trim()
                }

                // REQUIRED - Observation Severity
                if (!t.form.observationSeverity) {
                    t.errors.observationSeverity = true
                    t.errors.observationSeverityErrorMessage = 'Observation Severity is required'
                }
            }
            
            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        saveDocument: async function () {
            const t = this
            let documentResult = {}

            // If this a new observation, save a new document
            if (t.formMode === 'New') {
                documentResult = await t.MIX_createDocument('observations', t.form)
            }

            // If this an existing observation, Update the existing document
            if (t.formMode === 'Edit') {

                // If a response has been added, mark as resolved
                if (t.form.observationManagersName) {
                    t.form.observationStatus = 'RESOLVED'
                }

                documentResult = await t.MIX_updateDocument('observations', t.form)
            }

            if (documentResult.code === 1) {
                t.formMode = 'View'
                t.formReadOnly = true
                t.form.id = documentResult.data.id
                await t.refreshItemView();
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(documentResult)
        },

        updateItemStatus: async function (status) {
            const t = this

            const updateResult = await t.MIX_updateDocumentFieldsById(t.collection, t.form.id, {[t.collectionItem + 'Status']: status})
            if (updateResult.code === 1) {
                t.MIX_alert(1, 'Status Updated', updateResult.data, null)
                await t.refreshItemView()
            } else {
                t.MIX_alert(-1, 'ERROR - Could not update Status', null, updateResult.error)
            }
        },

        refreshItemView: async function () {
            const t = this

            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            const payload = {}
            payload.process = 'View'
            payload.popup = false
            payload.data = itemResult.data
            t.ACT_openItem(payload)
            await t.openItem()
        },

        deleteItem: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong>?</div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        undeleteItem: async function (id) {
            const t = this

            const undeleteResult = await t.MIX_undeleteDocument(t.collection, id)
            if (undeleteResult.code === 1) {
                const unDeleteLinkedResult = await t.MIX_undeleteLinked(['files', 'comments'], t.form.id)
                if (unDeleteLinkedResult.code === 1) {
                    const comment = {}
                    comment.comment = `${t.collectionItemTitle} Restored`
                    comment.docLink = {}
                    comment.docLink.collection = 'observations'
                    comment.docLink.documentId = t.form.id
                    comment.private = false
                    await t.MIX_createDocument('comments', comment)
                    t.MIX_alert(1, `${t.collectionItemTitle} Restored`, unDeleteLinkedResult.data, null)
                    await t.refreshItemView()
                } else {
                    t.MIX_alert(-1, `Error Restoring File Record`, null, unDeleteLinkedResult.error);
                }
            } else {
                t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error);
                console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
            }
            await t.refreshItemView();
        },

        destroyItem: async function () {
            const t = this

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: 'observations/destroy',
                    id: t.form.id,
                    title: `Destroy observation`,
                    description: `Are you sure you want to destroy the observation: <br /><strong>${t.form.concernName}</strong>?`,
                    width: '300',
                    height: '300',
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        // Get Photos
        getFiles: async function (openItem) {
            const t = this

            if (JSON.stringify(openItem.data) !== '{}') {
                t.$firebase.db.collection('files').where("docLink", "==", openItem.data.id)
                    .onSnapshot(function (querySnapshot) {
                        t.files = []
                        querySnapshot.forEach(function (doc) {
                            var document = doc.data()
                            document.id = doc.id
                            if (!document.hasOwnProperty('delete')) {
                                t.files.push(document)
                            } else if (t.showDeletedFiles) {
                                t.files.push(document)
                            } else {
                                // Do not Show Notice
                            }
                        })

                        // Set hasImages to prevent a double image
                        if (t.files.length) {
                            t.hasImages = true
                        }
                    })
            }
        },

        openPhoto(index) {
            const t = this

            const payload = {}
            payload.title = t.form.concernName
            payload.index = index
            payload.collection = 'concerns'
            payload.id = t.form.id
            payload.photos = t.files
            let width, height
            switch (true) {
                case (t.$vuetify.breakpoint.name === 'xs'):
                    width = '300'
                    height = "300"
                    break
                case (t.$vuetify.breakpoint.name === 'sm'):
                    width = '300'
                    height = "300"
                    break
                default:
                    width = '600'
                    height = "580"
                    break
            }
            payload.width = width
            payload.height = height
            t.MIX_photoViewer(payload)
            t.MIX_puDialog(true, 'photoviewer', width, (parseFloat(height) + 88).toString())

        },

        deletePhoto: async function (fileId) {
            const t = this

            const deleteFileResult = await t.MIX_deleteDocument('files', fileId)
            if (deleteFileResult.code === 1) {
                t.MIX_alert(1, 'Photo Deleted', deleteFileResult.data, null) // Send Success Alert to Concern
            } else {
                t.MIX_alert(-1, 'Error Deleting Photo', null, deleteFileResult.error) // Send Error Alert to Concern
            }
        },

        undeletePhoto: async function (fileId) {
            const t = this
            const undeleteFileResult = await t.MIX_undeleteDocument('files', fileId)
            if (undeleteFileResult.code === 1) {
                t.MIX_alert(1, 'Photo Restored', undeleteFileResult.data, null) // Send Success Alert to Concern
            } else {
                t.MIX_alert(-1, 'Error Restoring Photo', null, undeleteFileResult.error) // Send Error Alert to Concern
            }
        },

        calculateArrayLength(array) {
            return array.length
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        }
    },

    watch: {

        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'observations') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument('observations', t.GET_confirmBoxResult.data.id)
                        const deleteLinkedResult = await t.MIX_deleteLinked(['files', 'comments'], t.GET_confirmBoxResult.data.id)
                        if ((deleteDocumentResult.code === 1) && (deleteLinkedResult.code === 1)) {
                            const comment = {}
                            comment.comment = `${t.collectionItemTitle} Deleted`
                            comment.docLink = {}
                            comment.docLink.collection = 'observations'
                            comment.docLink.documentId = t.GET_confirmBoxResult.data.id
                            comment.private = false
                            await t.MIX_createDocument('comments', comment)
                            t.MIX_alert(1, `Incident and Photos Deleted`, deleteDocumentResult.data, null)
                            t.refreshItemView()
                        } else {
                            t.MIX_alert(-1, `Error Deleting Incident / Photos`, null, null)
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === 'observations/destroy') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const destroyDocumentResult = await t.MIX_destroyDocument('observations', t.GET_confirmBoxResult.data.id)
                        const destroyLinkedDocs = await t.MIX_destroyLinked(['files', 'comments'], t.GET_confirmBoxResult.data.id)
                        if ((destroyDocumentResult.code === 1) && (destroyLinkedDocs.code === 1)) {
                            t.MIX_alert(1, `Observation and Photos Destroyed`, destroyDocumentResult.data, null)
                            t.MIX_setPanelLayout(12, 0, false, '', '')
                        } else {
                            t.MIX_alert(-1, `Error Destroying Observation & Photos`, null, null)
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === 'comments') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)
                        if (deleteResult.code === 1) {
                            t.MIX_alert(1, 'Comment Deleted', deleteResult.data, null)
                        } else {
                            t.MIX_alert(-1, 'Error Deleting Comment', null, deleteResult.error)
                        }
                    }
                } else {
                    // Do nothing
                }
            }, deep: true
        },

        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                const photoUploadResult = t.GET_photoUploadResult
                if (!t.hasImages) {
                    t.tempImage = photoUploadResult.fileURL
                }

                if (JSON.stringify(photoUploadResult) !== '{}') {

                    const index = t.files.findIndex(file => file === photoUploadResult.id)

                    if (index === -1) {

                        t.files.push(photoUploadResult.id)

                        const updatePhotosResult = await t.MIX_updateDocumentFieldsById('observations', t.form.id, {files: t.form.files})

                        if (updatePhotosResult.code === 1) {
                            t.MIX_alert(1, 'Photo Added', updatePhotosResult.data, null)
                            await t.getFiles()
                        } else {
                            t.MIX_alert(-1, 'Error Adding Photo', null, updatePhotosResult.error)
                        }
                    }
                }
            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get required collection data
        await t.getSitesCollectionData()

        // Empty Photo Upload Result on Load
        t.MIX_photoUploadResult({})

        // * ITEM RELATED MOUNTED
        const openItem = await t.GET_openItem // Get Open Concern Data
        t.formMode = openItem.process // Show Form in View Mode
        if (openItem.process === 'New') {
            t.formReadOnly = false
            t.formBackground = 'white'
        } else {
            t.form = openItem.data // Update the Form with the Open Concern details
            t.formReadOnly = true
            t.formBackground = 'grey lighten-3'
            await t.getFiles(openItem)
        }
        if (openItem.popup === false) {
            t.updateBreadcrumbs()
        } // Update Breadcrumbs in Title

        if (openItem.popup === false) {
            t.updateBreadcrumbs()
        } // Update Breadcrumbs in Title
    }
}
</script>

<style scoped>
.disabled >>> .v-input__slot::before {
    border-style: none !important;
}

.v-tabs-items {
    width: 100% !important;
    height: 100% !important;
}

.v-window-item {
    height: calc(100vh - 170px);
    overflow-y: auto;
}

.v-menu__content {
    box-shadow: none !important;
}

.fade-in-enter-active {
    transition: all 0.5s ease;
}

.fade-in-leave-active {
    transition: all 0.5s ease;
}

.fade-in-enter, .fade-in-leave-to {
    position: absolute; /* add for smooth transition between elements */
    opacity: 0;
}

/* v-menu__content theme--light menuable__content__active */
</style>
