<template>
    <div class="viewport-maxwidth">
        <v-progress-linear v-if="loading === true" indeterminate color="primary"></v-progress-linear>

        <!-- Start of USER-INPUT -->
        <v-row class="justify-center" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-window v-model="step">

                    <!--Step 1 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Step 1-->

                    <v-window-item :value="1">

                        <v-form ref="registerUser" v-model="valid" lazy-validation>

                            <!--Email-->
                            <v-text-field :background-color="inputBackgroundColor"
                                          class="mb-1"
                                          :error="errors.userEmail"
                                          :error-messages="errors.userEmail ? errors.userEmailErrorMessage : ''"
                                          filled
                                          hide-details="auto"
                                          :label="$t('fields.email')"
                                          required
                                          v-model.trim="form.userEmail"/>

                            <!--Name-->
                            <v-text-field :background-color="inputBackgroundColor"
                                          class="mb-1"
                                          :error="errors.userName"
                                          :error-messages="errors.userName ? errors.userNameErrorMessage : ''"
                                          filled
                                          hide-details="auto"
                                          :label="$t('fields.fullName')"
                                          required
                                          v-model="form.userName"/>

                            <!--Contact Number-->
                            <v-text-field :background-color="inputBackgroundColor"
                                          class="mb-1"
                                          :error="errors.userTelephone"
                                          :error-messages="errors.userTelephone ? errors.userTelephoneErrorMessage : ''"
                                          filled
                                          hide-details="auto"
                                          :label="$t('fields.contactNumber')"
                                          required
                                          type="number"
                                          v-model="form.userTelephone"/>

                            <!--User Type-->
                            <v-select @change="setJobTitleVisibility"
                                      :background-color="inputBackgroundColor"
                                      class="mb-1"
                                      :error="errors.tempUserLevel"
                                      :error-messages="errors.tempUserLevel ? errors.tempUserLevelErrorMessage : ''"
                                      filled
                                      hide-details="auto"
                                      :items="['Office Staff', 'Site Staff', 'Visitor']"
                                      label="User Type"
                                      v-model="tempUserLevel"/>

                            <!--Job Title-->
                            <v-text-field v-if="isJobTitleVisible"
                                          class="mb-1"
                                          :error="errors.userJobTitle"
                                          :error-messages="errors.userJobTitle ? errors.userJobTitleErrorMessage : ''"
                                          filled :background-color="inputBackgroundColor"
                                          hide-details="auto"
                                          :label="$t('fields.jobTitle')"
                                          required
                                          v-model="form.userJobTitle"/>

                            <!--Admin Reg Code-->
                            <v-text-field v-if="$route.params.id ==='admin'"
                                          :background-color="inputBackgroundColor"
                                          class="mb-1"
                                          filled
                                          label="Admin Registration Code"
                                          v-model="adminRegCode"/>

                            <!--Terms and Conditions-->
                            <v-checkbox class="d-flex align-start mt-4"
                                        color="primary"
                                        :error="errors.privacyPolicyConsent"
                                        hide-details
                                        required
                                        v-model="form.privacyPolicyConsent">
                                <template v-slot:label>
                                    <div>
										<span class="font-weight-bold"> {{ $t('firebaseAuth.register.terms') }}
											<span @click='privacyDialog = true' class="primary--text font-weight-bold">
												{{ $t('firebaseAuth.register.termsLink') }}</span>
										</span>
                                    </div>
                                </template>
                            </v-checkbox>

                        </v-form>

                        <!--Action buttons-->
                        <v-row no-gutters class="my-8">

                            <!--Login-->
                            <v-col cols="6" class="py-0 pr-2">
                                <v-btn @click="MIX_go('/login')"
                                       block color="grey" depressed :height="buttonSizeDefault">
                                    <app-text category="text-default">Back to Login</app-text>
                                </v-btn>
                            </v-col>

                            <v-spacer/>

                            <!--Next-->
                            <v-col cols="6" class="py-0 pl-2">
                                <v-btn @click="validateForm"
                                       block color="primary" depressed :height="buttonSizeDefault">
                                    <app-text category="text-default">{{ $t('buttons.next') }}</app-text>
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-window-item>

                    <!--Step 2 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Step 2-->

                    <v-window-item :value="2">

                        <div @click="isPasswordCriteriaVisible = !isPasswordCriteriaVisible" class="mb-4">

                            <div class="d-flex align-center">
                                <app-text category="text-default">Passwords must meet the following criteria:</app-text>
                                <v-icon class="ml-4" :class="isPasswordCriteriaVisible ? 'icons8-collapse-arrow' : 'icons8-expand-arrow'"/>
                            </div>

                            <ul v-if="isPasswordCriteriaVisible">
                                <li>
                                    <app-text category="text-default">Be at least 8 characters long</app-text>
                                </li>
                                <li>
                                    <app-text category="text-default">Contain at least 1 upper case character</app-text>
                                </li>
                                <li>
                                    <app-text category="text-default">Contain at least 1 lower case character</app-text>
                                </li>
                                <li>
                                    <app-text category="text-default">Contain at least 1 number</app-text>
                                </li>
                                <li>
                                    <app-text category="text-default">
                                        Contain at least 1 special character from
                                        <span class="font-weight-bold">? # @ ! £ $ % &</span>
                                    </app-text>
                                </li>
                            </ul>

                        </div>

                        <v-form ref="userPassword" v-model="valid1" lazy-validation>

                            <!--Password-->
                            <v-text-field @click:append="showPasswordIcon = !showPasswordIcon"
                                          :append-icon="showPasswordIcon ? 'icons8-eye' : 'icons8-invisible'"
                                          :background-color="inputBackgroundColor"
                                          class="mb-1"
                                          :error="passwordErrors.password"
                                          :error-messages="passwordErrors.passwordErrorMessage"
                                          filled
                                          hide-details="auto"
                                          :label="$t('fields.password')"
                                          required
                                          :type="showPasswordIcon ? 'text' : 'password'"
                                          v-model.trim="password"/>

                            <!--Confirm Password-->
                            <v-text-field @click:append="showPasswordIcon1 = !showPasswordIcon1"
                                          :append-icon="showPasswordIcon1 ? 'icons8-eye' : 'icons8-invisible'"
                                          :background-color="inputBackgroundColor"
                                          class="mb-2"
                                          :error="passwordErrors.confirmPassword"
                                          :error-messages="passwordErrors.confirmPasswordErrorMessage"
                                          filled
                                          hide-details="auto"
                                          :label="$t('fields.confirmPassword')"
                                          required
                                          :rules="[passwordConfirmationRule]"
                                          :type="showPasswordIcon1 ? 'text' : 'password'"
                                          v-model.trim="confirmPassword"/>

                        </v-form>

                        <!--
                        Buttons
                        -->
                        <v-row class="my-4">

                            <!--Back-->
                            <v-col cols="6">
                                <v-btn @click="step--"
                                       block
                                       color="grey"
                                       depressed
                                       :height="buttonSizeDefault">
                                    {{ $t('buttons.back') }}
                                </v-btn>
                            </v-col>

                            <v-spacer/>

                            <!--Register-->
                            <v-col cols="6">
                                <v-btn @click="validatePasswords"
                                       block
                                       class="primary"
                                       :disabled="loading"
                                       depressed
                                       :height="buttonSizeDefault"
                                       :loading="loading">
                                    {{ $t('buttons.register') }}
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-window-item>

                    <!-- Error message -->
                    <v-window-item :value="3">
                        <div class="pa-4 text-center" v-if="accountCreated === true">
                            <v-progress-linear indeterminate color="primary"></v-progress-linear>
                            <h3 class="title font-weight-light mb-2">Welcome</h3>
                            <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
                        </div>
                        <div class="pa-4 text-center" v-else>
                            <h3 class="title font-weight-light mb-2">Something went wrong</h3>
                            <h4 v-if="registerErrorMessage != null">{{ registerErrorMessage }}</h4>
                            <span class="caption grey--text">Try again!</span>
                        </div>
                    </v-window-item>

                </v-window>
            </v-col>
        </v-row>

        <!-- End of USER-INPUT -->
        <v-dialog style="z-index: 3000 !important;" v-model="privacyDialog" fullscreen hide-overlay
                  transition="dialog-bottom-transition">
            <v-card class="background">
                <v-toolbar dark color="secondary heading5 black--text">Privacy and Terms & Conditions

                    <v-spacer/>

                    <v-icon class="black--text" @click="privacyDialog = false">mdi-close</v-icon>

                </v-toolbar>

                <PrivacyTermsConditions/>

                <v-divider/>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import PrivacyTermsConditions from "@/components/termsAndConditions.vue";
import PhotoUpload_button_component from "../photoUpload/photoUpload_button_component";
export default {

    name: "Register",

    components: {
        PhotoUpload_button_component,
        PrivacyTermsConditions,
    },

    data() {
        return {
            isPasswordCriteriaVisible: false,
            isJobTitleVisible: false,
            tempUserLevel: '',
            inputBackgroundColor: 'white',
            step: 1,
            valid: true,
            valid1: true,
            showPasswordIcon: false,
            showPasswordIcon1: false,
            adminRegCode: '',
            errors: {
                userEmail: false,
                userEmailErrorMessage: '',
                userName: false,
                userNameErrorMessage: '',
                userTelephone: false,
                userTelephoneErrorMessage: '',
                tempUserLevel: false,
                tempUserLevelErrorMessage: '',
                userJobTitle: false,
                userJobTitleErrorMessage: '',
                privacyPolicyConsent: false,
            },
            form: {
                id: '',
                authId: '',
                userName: '',
                userAddress: {
                    userAddressLine1: '',
                    userAddressLine2: '',
                    userAddressLine3: '',
                    userTown: '',
                    userCounty: '',
                    userPostcode: ''
                },
                userTelephone: '',
                userEmail: '',
                userLevel: 'SU',
                userType: 'Staff',
                userRole: 'User',
                userStatus: 'PENDING',
                userJobTitle: '',
                privacyPolicyConsent: false,
                swappStatus: 0,
                swappMethod: null,
                usualSite: '',
                lastLocation: {
                    locationId: '',
                    locationName: ''
                },
                lastSite: {
                    siteId: '',
                    siteName: ''
                },
                profilePicFileURL: null,
                createdDateTime: '',
                createdUserData: {
                    userEmail: '',
                    userId: '',
                    userName: ''
                },
                modifiedDateTime: '',
                modifiedUserData: {
                    userEmail: '',
                    userId: '',
                    userName: ''
                }
            },
            passwordErrors: {
                password: false,
                passwordErrorMessage: '',
                confirmPassword: false,
                confirmPasswordErrorMessage: '',
                passwordMatch: false,
            },
            password: '', // password input
            confirmPassword: '', // check password
            accountCreated: true, // show thank you/ try gain message at the end
            loading: false,
            loader1: null,
            privacyDialog: false,
            registerErrorMessage: null,
            //check if user was created in admin dashboard
            userInLocalStorage: false,
            userInLocalStorageId: null
        };
    },

    computed: {
        // * Checks if the password matches the confirm password
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || 'Password must match';
        },
    },

    methods: {
        togglePasswordCriteria() {
            const t = this
        },
        /**
         * Set Job Title Visibility
         *
         * Set the user type code (Staff (SU) or Visitor (VU)) depending on the user type selection.
         * Also, toggle the job title field visibility.
         */
        setJobTitleVisibility() {
			const t = this
			if (t.tempUserLevel === 'Office Staff' || t.tempUserLevel === 'Site Staff') {
				t.form.userLevel = 'SU'
				t.form.userStatus = 'PENDING'
				t.isJobTitleVisible = true
			} else if (t.tempUserLevel === 'Visitor') {
				// Approve Visitor Users
				t.form.userLevel = 'VU'
				t.form.userStatus = 'APPROVED'
				t.isJobTitleVisible = false
			}
        },
        /**
         * Validate Form
         *
         * Validate form fields as required.
         * If there are no errors, call to next().
         * If there are errors render them in their respective fields.
         */
        validateForm() {
            const t = this
            t.errors.userEmail = false
            t.errors.userName = false
            t.errors.userTelephone = false
            t.errors.tempUserLevel = false
            t.errors.userJobTitle = false
            t.errors.privacyPolicyConsent = false
            // No email is present
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // Email is not valid
            else if (!/.+@.+\..+/.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }
            // No name is present
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name is required'
            }
            // No contact number is present
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }
            // No user type has been selected
            if (!t.tempUserLevel) {
                t.errors.tempUserLevel = true
                t.errors.tempUserLevelErrorMessage = 'User type is required'
            }
            // Is a staff member but no job title has been selected
            if (t.tempUserLevel.toUpperCase() !== 'VISITOR' && !t.form.userJobTitle.trim()) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job Title is required'
            }
            // If no job title has been selected
            if (!t.form.privacyPolicyConsent) {
                t.errors.privacyPolicyConsent = true
            }
            // If there are no errors, call for the next section
            if (!Object.values(t.errors).includes(true)) {
                t.next()
            }
        },
        next: async function () {
            if (this.$refs.registerUser.validate()) {
                if (this.$route.params.id === 'admin') {
                    let result = await this.MIX_FIREBASE_checkAdminRegCode(this.adminRegCode)
                    if ((result.code === 1) && (result.data === true)) {
                        this.form.userLevel = 'SA'
                        this.form.userRole = 'Admin';
                        this.form.userType = 'Staff';
                        this.step++; // * move to the next step
                    } else {
                        this.MIX_alert(-1, 'Admin Registration Code is Incorrect, please try again.')
                    }
                } else {
                    this.step++; // * move to the next step
                }
            } else {
                // ! did not pass validation
                this.MIX_alert(-1, 'Fill out all the fields correctly in order to proceed.', null, null);
            }
        },
        async checkUserInLocalStorage(email) {
            var t = this;
            return new Promise(function (resolve, reject) {
                t.$firebase.db.collection("users").where('userEmail', '==', email).get().then(function (querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        t.userInLocalStorage = false;
                        return resolve(t.userInLocalStorage);
                    } else {
                        t.userInLocalStorage = true;
                        t.userInLocalStorageId = querySnapshot.docs[0].id;
                        return resolve(t.userInLocalStorage);
                    }
                })
                    .catch(function (error) {
                        return reject(error)
                    });
            });
        },
        setUserDetails(uid) {
            const t = this
            t.form.id = uid // * assign firebase auth user id to firebase user in collection
            t.form.createdDateTime = t.$moment().format("x")
            t.form.createdUserData.userEmail = t.form.userEmail
            t.form.createdUserData.userName = t.form.userName
            t.form.createdUserData.userId = uid
        },
        sendVerificationEmail(currUser) {
            const t = this;
            currUser.sendEmailVerification()
                .then(function () {
                    t.MIX_alert(2, "An account verification email has been sent to you.", null, null)
                })
                .catch(error => {
                    // ! verification email error
                    console.log('Send verification email error: ', error)
                    t.MIX_alert(-1, error, null, null)
                })
        },
        redirectToHomepage() {
            const t = this
            setTimeout(() => {
                t.$router.push("/").catch(error => {
                    console.log(error.message)
                });
            }, 1500); // ? added time out to allow the current user to be set
        },
        /**
         * Validate Passwords
         *
         * Validate password fields as required.
         * If there are no errors, call to register().
         * If there are errors render them in their respective fields.
         */
        validatePasswords() {
            const t = this
            /*
            * Password must:
            * - Be at least 8 characters
            * - Contain at least 1 upper case character
            * - Contain at least 1 lower case character
            * - Contain at least 1 number
            */
            const passwordRegex = /(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9])(?=.*?[?#@!£$%&]).{8,}/
            // Reset any errors
            t.passwordErrors.password = false
            t.passwordErrors.confirmPassword = false
            // No password is present
            if (!t.password.trim()) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password is required'
            }
            // Password is less than 8 characters
            else if (t.password.trim().length < 8) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password must be at least 8 characters'
            }
            // Password must be in the expected format (regex)
            else if (!passwordRegex.test(t.password.trim())) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password is not in the expected format'
            }
            // No confirm password is present
            if (!t.confirmPassword.trim()) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password confirmation is required'
            }
            // Confirm password is less than 8 characters
            else if (t.confirmPassword.trim().length < 8) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password is not in the expected format'
            }
            // Password must be in the expected format (regex)
            else if (!passwordRegex.test(t.confirmPassword.trim())) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password is not in the expected format'
            }
            // Passwords don't match
            if (t.password !== t.confirmPassword) {
                t.passwordErrors.passwordMatch = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Passwords don\'t match'
            }
            // If there are no errors, call to register
            if (!Object.values(t.passwordErrors).includes(true)) {
                t.register()
            }
        },
        async register() {
            const t = this
            if (this.$refs.userPassword.validate()) {
                this.step++ // * move to the next step
                t.form.userEmail = t.form.userEmail.toLowerCase()
                // * CHECK IF USER WAS CREATED FROM THE ADMIN DASHBOARD
                const userExists = await this.checkUserInLocalStorage(t.form.userEmail)
                // * if the user exists in the db
                if (userExists) {
                    // * create user in authentication
                    t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password)
                        .then(user => {
                            // * set user details
                            t.setUserDetails(user.user.uid)
                            // * update the user from the collection
                            const fields = {
                                id: t.userInLocalStorageId,
                                authId: user.user.uid,
                                modifiedDateTime: t.form.modifiedDateTime,
                                modifiedUserData: t.form.modifiedUserData,
                                userName: t.form.userName,
                                userJobTitle: t.form.userJobTitle,
                                userTelephone: t.form.userTelephone,
                                userLevel: t.form.userLevel,
                                userRole: t.form.userRole,
                                userType: t.form.userType
                            };
                            t.$firebase.db.collection("users").doc(t.userInLocalStorageId).update(fields).then((Result) => {
                                this.MIX_alert(1, 'Your account was created.', null, null);
                                t.$store.commit("SET_currentUser", user);
                            }).catch((err) => {
                                this.MIX_alert(-1, err, null, null);
                                console.log(err);
                            });
                            t.accountCreated = true;
                            // * assign this user to the current logged in user
                            t.$store.commit("SET_currentUser", user);
                            this.MIX_alert(1, "Your account was created.", null, null);
                            // * send verification email to user
                            t.sendVerificationEmail(t.$firebase.auth.currentUser);
                            // * redirect user to homepage
                            t.redirectToHomepage();
                        })
                        .catch((err) => {
                            // ! firebase registering error
                            t.registerErrorMessage = err;
                            t.accountCreated = false;
                            console.log("firebase registering error: " + err);
                            t.MIX_alert(-1, err, null, null);
                        });
                } else {
                    // * create user
                    t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password).then((user) => {
                        // * set user details
                        t.setUserDetails(user.user.uid);
                        ////console.log("uid: " + user.uid + "\nsecond type: " + user.user.uid);
                        /////console.log("User: " + JSON.stringify(t.user, null, 2));
                        // * add to the users collection
                        t.form.authId = user.user.uid;
                        t.$firebase.db.collection("users").doc(user.user.uid).set(t.form).then((Result) => {
                            // console.log("result: " + Result);
                        }).catch((err) => {
                            console.log(err);
                        });
                        t.accountCreated = true;
                        // * assign this user to the current logged in user
                        t.$store.commit("SET_currentUser", user);
                        this.MIX_alert(1, "Your account was created.", null, null);
                        // * send verification email to user
                        t.sendVerificationEmail(t.$firebase.auth.currentUser);
                        // * redirect user to homepage
                        t.redirectToHomepage();
                    })
                        .catch((err) => {
                            // ! firebase registering error
                            t.registerErrorMessage = err;
                            t.accountCreated = false;
                            console.log("firebase registering error: " + err);
                            t.MIX_alert(-1, err, null, null);
                        });
                }
            } else {
                // ! user did not pass input validation
                t.accountCreated = false;
                t.MIX_alert(-1, "Fill out all the fields correctly in order to proceed.", null, null);
            }
        },
    },

    watch: {
        loader1() {
            const l = this.loader1;
            this[l] = !this[l];
            setTimeout(() => (this[l] = false), 3000);
            this.loader1 = null;
        },
    },

}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}
</style>
