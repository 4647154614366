<!--
//*TODO
copied from eihl - needs to be changed to arcms details
-->

<template>
  <v-row justify="center" >
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <div class="mx-2 heading5 robotocondensed text-left white--text">
        <app-text class="primary--text px-2 pt-2">{{ formTitle }}</app-text>
        <div class="ml-2 body1 darkgrey--text">
         If you have a problem with the App then please contact us with the form below.
        </div>


        <v-window v-model="step">
          <v-window-item :value="1" class="mt-2 mx-2">
            <v-form ref="contactForm" v-model="valid" lazy-validation @submit.prevent="sendContactMessage()">
              <!-- The subject Of the user -->
              <v-text-field class="mb-2" v-model="contact_subject" solo label="Enter subject" append-icon="icons8-name-tag" hide-details="auto" :rules="subjectRules" required></v-text-field>
              <!-- The message Of the user -->
              <v-textarea v-model="contact_message" solo label="Write Your message" hide-details="auto" :rules="messageRules" required></v-textarea>
            </v-form>
            <v-row class="mt-2 mb-4">
              <v-spacer></v-spacer>
              <v-btn class="mr-1 elevation-0" dark color="grey" @click="clear">back</v-btn>
              <v-btn class="mr-3 elevation-0" dark color="primary" @click="sendContactMessage()" type="submit">SUBMIT</v-btn>
            </v-row>
          </v-window-item>

          <v-window-item :value="2">
            <v-row class="justify-left" no-gutters>
              <p class="ml-2 my-3 heading6 white--text">Thank you for contacting us, we will get back to you as soon as we can.</p>
            </v-row>
            <v-row class="justify-left" no-gutters>
              <v-btn class="mx-2" dark color="primary" @click="goToHome()" type="submit">Home</v-btn>
            </v-row>
          </v-window-item>
        </v-window>
      </div>
    </v-col>
    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in" >
      <!-- <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" /> -->
    </transition>
  </v-row>
</template>

<script>
class SNACKBAR {
  constructor(status, color, message, timeout) {
    this.status = status;
    this.color = color;
    this.message = message;
    this.timeout = timeout;
  }
}
export default {
  name: "ContactUs",
  data: () => ({
    step: 1,
    valid: true,
    snackbarKey: 0,
    snackbar: {},
    contact_name: "",
    contact_email: "",
    contact_subject: "",
    contact_message: "",
    contact_notice: "",

    subjectRules: [(v) => !!v || "Subject is required"],
    messageRules: [(v) => !!v || "Message is required"],
  }),
  computed: {
    formTitle() {
      if (this.step === 1) {
        return "SUPPORT REQUEST";
      } else if (this.step === 2) {
        return "MESSAGE SENT SUCCESSFULLY! ";
      } else {
        return "SUPPORT REQUEST";
      }
    },
  },
  methods: {
    sendContactMessage() {
      if (this.$refs.contactForm.validate()) {
        this.contact_name = this.$store.getters.currUser.firstName + this.$store.getters.currUser.lastName;
        this.contact_email = this.$store.getters.currUser.email;
        const url = `https://europe-west2-vindico-swapp.cloudfunctions.net/sendEmail?email_from=${this.contact_email}&message=${this.contact_message}&subjectForm=${this.contact_subject}&nameUser=${this.contact_name}`;
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        fetch(url, requestOptions);
        this.step++;
        this.snackbar = new SNACKBAR(
          true,
          "success",
          "Your message has been sent",
          2000
        ); // update snack bar with success
        this.snackbarKey++;
      } else {
        this.snackbar = new SNACKBAR(
          true,
          "error",
          "Fill in every field in order to proceed.",
          3000
        ); // update snack bar with error
        this.snackbarKey++;
      }
    },
    goToHome() {
      this.$router.push("/").catch((error) => {
        console.log(error.message);
      });
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clear() {
      this.contact_name = "";
      this.contact_email = "";
      this.contact_subject = "";
      this.contact_message = "";
       this.$router.push("/").catch((error) => {
        console.log(error.message);
      });
    },
  },
};
</script>
