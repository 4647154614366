<template>
    <div class="ma-0 pa-0">
      <!-- //* input for adding the file -->
      <input type="file" ref="input1" style="display: none" @change="previewFile" :accept="allowedTypes"/>
      <!-- //* upload button -->
      <appbutton :label="buttonText"  icon="icons8-upload-to-cloud"  type="darkgrey"  @click.native="click1" />
      <!-- //* upload progress row -->
      <v-row class="px-1 py-0 mb-0" v-if="hideUploadButton === false && isUploading">
        <v-progress-linear v-model="uploadValue" height="25">
            <strong>{{ Math.ceil(uploadValue) }}%</strong>
        </v-progress-linear>
      </v-row>
    </div>
</template>

<script>
export default {
    name: "UploadFilesButton",
    props: {
        allowedTypes: {
            type: String,
        },
        storagePath: {
            type: String,
        },
        buttonText: {
            type: String
        }
    },
    data() {
        return {
            fileData: null,
            fileType: '',
            isUploading: false,
            uploadValue: 0,
            hideUploadButton: false,
        }
    },
    watch: {
        fileData() {
            this.onUpload();
        },
    },
    computed: {
        uploadButtonLabel(){
            if(this.buttonText === null || this.buttonText === undefined || this.buttonText === ''){
                return 'Add File';
            } else {
                return this.buttonText;
            }
        }
    },
    methods: {
        click1() {
            this.$refs.input1.click();
        },
        previewFile(event) {
            this.uploadValue = 0;
            this.fileURL = null;
            this.fileData = event.target.files[0]; // asign fileData for preview
            //this.onUpload();
        },
        onUpload() {
            var t = this;
            this.fileURL = null;

            // Create the file metadata
            if (this.storagePath != undefined){
                var name = this.storagePath + `/${this.fileData.name}`;
            }else {
                var name = this.fileData.name;
            }

            console.log(JSON.stringify(this.fileData, null, 2))

            //GCS storage reference
            const storageRef = this.$firebase.storage.ref().child(name).put(this.fileData);
            storageRef.on(`state_changed`,(snapshot) => {
                    this.uploadValue =(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                }, //upload progress
                (error) => {
                    console.log(error.message); //error
                },
                () => {
                    this.uploadValue = 100; // upload value after loading file
                    this.isUploading = false;
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.fileURL = url; // asign GCS url to fileURL
                        console.log(this.fileURL);
                        // Create the file metadata
                        var storeFile = {
                            fileName: t.fileData.name,
                            fileSize: t.fileData.size,
                            fileType: t.fileData.type,
                            fileURL: t.fileURL
                        }
                        //change the file in store
                        t.$store.commit("upload_file_store/setFile", storeFile);

                    });
                }
            );

        },
  }
}
</script>
