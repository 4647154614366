<template>
    <div>

        <!--Site name-->
        <v-row class="contenttitle grey lighten-2 ma-0 pa-4 d-flex align-center white">
            <app-text category="text-default">{{ form.siteName }}</app-text>
        </v-row>

        <!--
        Page content
         - Tabbed content for Overview and Locations forms
        -->
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col cols="12" xs="6" lg="12">
                    <v-row class="ma-0 pa-0  expandtofill" no-gutters>

                        <!--
                        Tabs
                         - Overview | Locations
                        -->
                        <v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">
                            <v-tabs-slider color="primary" background-color="black"></v-tabs-slider>

                            <!--Overview-->
                            <v-tab href="#overview">
                                <app-text category="text-small">Overview</app-text>
                            </v-tab>

                            <!--Locations-->
                            <v-tab href="#locations">
                                <app-text category="text-small">Locations</app-text>
                            </v-tab>

                        </v-tabs>

                        <!--
                        Tab Content
                         - Overview | Locations
                        -->
                        <v-tabs-items v-model="tabs" class="appbackground" style="width: 100%">

                            <!--
                            Overview tab content
                            -->
                            <v-tab-item value="overview" class="pa-0">

                                <!--Description | Action buttons-->
                                <v-row class="pa-4" no-gutters>

                                    <!-- Description -->
                                    <v-col class="pa-0" cols="12">

                                        <!--Title-->
                                        <app-text category="text-default-bold">Overview</app-text>

                                        <!--Info-->
                                        <app-text category="text-default">
                                            Use the form below to Edit Site or Site Manager details.<br/>
                                            When you have added a site, you must add at least one location to it in the
                                            Locations tab.
                                        </app-text>

                                        <!--Missing location error text-->
                                        <app-text v-if="!form.locations.length"
                                                  category="text-default-bold" class="error--text">
                                            You must add at least one location per site.
                                        </app-text>

                                        <v-divider class="my-4"/>

                                    </v-col>

                                    <!-- Action buttons -->
                                    <v-col class="d-flex">

                                        <!--Undelete-->
                                        <appbutton :label="$t(`buttons.undelete`)"
                                                   v-if="formMode !== 'New' && form.delete !== undefined"
                                                   icon="icons8-process" type="grey" class="mr-1"
                                                   @click.native="undeleteItem(form.id)" :na="formMode !== 'View'"
                                                   :class="{ 'disabled': formMode !== 'View' }" showlabel="xlOnly"/>

                                        <!--Delete-->
                                        <appbutton :label="$t(`buttons.delete`)"
                                                   v-if="formMode !== 'New' && form.delete === undefined"
                                                   icon="icons8-trash" type="error" class="mr-1"
                                                   @click.native="deleteItem(form.id)" :na="formMode !== 'View'"
                                                   :class="{ 'disabled': formMode !== 'View' }" showlabel="xlOnly"/>

                                        <!--Edit-->
                                        <appbutton label="Edit" v-if="formMode !== 'New'" icon="icons8-edit-property"
                                                   type="warning" class="mr-1" @click.native="editItem()"
                                                   :na="formMode !== 'View'"
                                                   :class="{ 'disabled': formMode !== 'View' }" showlabel="xlOnly"/>

                                        <v-spacer/>

                                        <!--Cancel-->
                                        <v-btn v-if="formMode === 'Edit'" @click.native="cancelItem()"
                                               class="mr-4" color="grey" depressed :height="buttonSizeDefault">
                                            <v-icon class="icons8-cancel" color="white"/>
                                            <app-text category="text-default" class="white--text ml-2">Cancel</app-text>
                                        </v-btn>

                                        <!--Save-->
                                        <v-btn v-if="formMode === 'Edit' || formMode === 'New'"
                                               @click.native="validateForm()"
                                               color="success" depressed :height="buttonSizeDefault">
                                            <v-icon class="icons8-save"/>
                                            <app-text category="text-default" class="ml-2">Save</app-text>
                                        </v-btn>

                                    </v-col>

                                </v-row>

                                <!--
                                Form
                                -->
                                <v-row class="mt-4 ma-0 px-2" no-gutters>

                                    <!--Site name-->
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.siteName : errors.siteName = false"
                                                      :error-messages="errors.siteName ? errors.siteNameErrorMessage: ''"  
                                                      filled 
                                                      hide-details="auto"
                                                      label="Site Name" 
                                                      v-model="form.siteName"/>
                                    </v-col>

                                    <!--Site telephone-->
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground" 
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.siteTelephone : errors.siteTelephone = false"
                                                      :error-messages="errors.siteTelephone ? errors.siteTelephoneErrorMessage: ''"                                                      
                                                      filled 
                                                      hide-details="auto"
                                                      type="number"
                                                      label="Telephone"
                                                      v-model="form.siteTelephone"></v-text-field>
                                    </v-col>

                                    <!-- Address -->
                                    <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <app-text category="text-medium" class="darkgrey--text">
                                            Address
                                        </app-text>
                                        <v-divider class="my-2"/>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.siteAddressLine1 : errors.siteAddressLine1 = false"
                                                      :error-messages="errors.siteAddressLine1 ? errors.siteAddressLine1ErrorMessage: ''"  
                                                      filled 
                                                      hide-details="auto"
                                                      label="Address Line 1 (optional)" 
                                                      v-model="form.siteAddress.addressLine1"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.siteAddressLine2 : errors.siteAddressLine2 = false"
                                                      :error-messages="errors.siteAddressLine2 ? errors.siteAddressLine2ErrorMessage: ''"
                                                      filled 
                                                      hide-details="auto"
                                                      label="Address Line 2 (optional)"
                                                      v-model="form.siteAddress.addressLine2"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.siteAddressLine3 : errors.siteAddressLine3 = false"
                                                      :error-messages="errors.siteAddressLine3 ? errors.siteAddressLine3ErrorMessage: ''"
                                                      filled 
                                                      hide-details="auto"
                                                      label="Address Line 3 (optional)"
                                                      v-model="form.siteAddress.addressLine3"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.userTown : errors.userTown = false"
                                                      :error-messages="errors.siteTown ? errors.siteTownErrorMessage: ''"
                                                      filled 
                                                      hide-details="auto"
                                                      label="Town (optional)" 
                                                      v-model="form.siteAddress.town"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.siteCounty : errors.siteCounty = false"
                                                      :error-messages="errors.siteCounty ? errors.siteCountyErrorMessage: ''"
                                                      filled
                                                      hide-details="auto"
                                                      label="County (optional)"
                                                      v-model="form.siteAddress.county" 
                                                      ></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      :disabled="formReadOnly"
                                                      :error="!formReadOnly ? errors.sitePostcode: errors.sitePostcode = false"
                                                      :error-messages="errors.sitePostcode ? errors.sitePostcodeErrorMessage: ''"                                          
                                                      filled
                                                      hide-details="auto"
                                                      label="Postcode (optional)"
                                                      v-model="form.siteAddress.postcode"></v-text-field>
                                    </v-col>

                                    <!-- Contact -->
                                    <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <app-text category="text-medium" class="darkgrey--text">
                                            Site Manager
                                        </app-text>
                                        <v-divider class="my-2"/>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-select @change="setNewSiteManager"
                                                  :background-color="formBackground"
                                                  :disabled="formReadOnly"
                                                  dense
                                                  filled
                                                  hide-details
                                                  :items="usersData"
                                                  item-text="userName"
                                                  label="Name"
                                                  return-object
                                                  v-model="form.siteContact.contactName"/>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      disabled
                                                      :error="!formReadOnly ? errors.siteContactPosition : errors.siteContactPosition = false"
                                                      :error-messages="errors.siteContactPosition ? errors.siteContactPositionErrorMessage: ''"                                      
                                                      filled 
                                                      hide-details="auto"
                                                      label="Position"
                                                      v-model="form.siteContact.contactPosition"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      disabled 
                                                      :error="!formReadOnly ? errors.siteContactTelephone : errors.siteContactTelephone = false"
                                                      :error-messages="errors.siteContactTelephone ? errors.siteContactTelephoneErrorMessage: ''"                                    
                                                      filled 
                                                      hide-details="auto"
                                                      label="Telephone"
                                                      type="number"
                                                      v-model="form.siteContact.contactTelephone"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      disabled
                                                      :error="!formReadOnly ? errors.siteContactMobile : errors.siteContactMobile = false"
                                                      :error-messages="errors.siteContactMobile ? errors.siteContactMobileErrorMessage: ''"                   
                                                      filled 
                                                      hide-details="auto"
                                                      label="Mobile (optional)"
                                                      type="number"
                                                      v-model="form.siteContact.contactMobile"></v-text-field>
                                    </v-col>
                                    <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                        <v-text-field :background-color="formBackground"
                                                      dense
                                                      disabled
                                                      :error="!formReadOnly ? errors.siteContactEmail : errors.siteContactEmail = false"
                                                      :error-messages="errors.siteContactEmail ? errors.siteContactEmailErrorMessage: ''"
                                                      filled
                                                      hide-details="auto"
                                                      label="Email" 
                                                      v-model="form.siteContact.contactEmail"></v-text-field>
                                    </v-col>

                                    <!-- Notes -->
                                    <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <app-text category="text-medium" class="darkgrey--text">Notes</app-text>
                                        <v-divider class="my-2"/>
                                    </v-col>
                                    <v-col>
                                        <v-textarea class="pa-1" label="Notes" v-model="form.siteNotes" filled
                                                    hide-details :background-color="formBackground" dense auto-grow
                                                    rows="4" clearable
                                                    :disabled="formReadOnly"></v-textarea>
                                    </v-col>

                                </v-row>

                                <!--Created and Modified info -->
                                <v-col class="px-3 py-4" cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                       v-if="formMode !== 'New'">

                                    <v-divider/>

                                    <div>
                                        <app-text category="text-small-bold" class="grey--text mt-4">
                                            Created:
                                            <span class="font-weight-regular">
												{{ form.createdUserData.userName
                                                }} - {{ MIX_formatDateTime(form.createdDateTime, 'x', 'DD-MMM-YYYY') }}
											</span>
                                            |
                                            Modified:
                                            <span class="font-weight-regular">
												{{ form.modifiedUserData.userName
                                                }} - {{ MIX_formatDateTime(form.modifiedDateTime, 'x', 'DD-MMM-YYYY') }}
											</span>
                                        </app-text>
                                    </div>

                                </v-col>

                            </v-tab-item>

                            <!--
                            Locations tab content
                            -->
                            <v-tab-item value="locations" class="pa-0">

                                <!--Overview-->
                                <v-row class="pa-4" no-gutters>

                                    <!--Description-->
                                    <v-col class="pa-0" cols="12">

                                        <!--Title-->
                                        <app-text category="text-default-bold">Locations</app-text>

                                        <!--Info-->
                                        <app-text category="text-default">
                                            Use the form below to Add Locations to Sites.
                                        </app-text>

                                        <v-divider class="my-4"/>

                                    </v-col>

                                    <!--Add button | Deleted switch-->
                                    <v-col class="d-flex align-center">

                                        <!--Add-->
                                        <appbutton class="mr-2" :label="$t('buttons.add')" icon="icons8-edit-property"
                                                   type="success"
                                                   @click.native="MIX_location(true, 'sites', form.id, form.locations, {id: form.id, siteName: form.siteName, siteManager: form.siteManager, siteArea: form.siteArea, siteSector: form.siteSector, sitePostocode: form.siteAddress.sitePostcode})"/>

                                        <v-spacer/>

                                        <!--Deleted switch-->
                                        <div v-if="['DA'].includes(GET_currentUser.userLevel)" class="d-flex">
                                            <app-text category="text-default-bold" class="grey--text">DELETED</app-text>
                                            <v-switch @change="getLocations(GET_openItem)"
                                                      class="primary--text ml-4"
                                                      inset
                                                      v-model="showDeletedLocations"/>
                                        </div>

                                    </v-col>

                                </v-row>

                                <!--
                                Table
                                -->
                                <v-row class="ma-0 px-4" no-gutters>
                                    <v-col cols="12" class="pt-0">
                                        <v-data-table
                                            :headers="locationsHeaders"
                                            :items="locationsTableData"
                                            :items-per-page="10"
                                            class="elevation-1">

                                            <!--Location name-->
                                            <template v-slot:[`item.locationName`]="{ item }">
                                                <app-text category="text-default">{{ item.locationName }}</app-text>
                                            </template>

                                            <!--Staff-->
                                            <template v-slot:[`item.staff`]="{ item }">
                                                <app-text category="text-default" class="justify-center">
                                                    {{ noOfUsersAtLocation(item.id, 'Staff') }}
                                                </app-text>
                                            </template>

                                            <!--Total-->
                                            <template v-slot:[`item.totalCount`]="{ item }">
                                                <app-text category="text-default" class="justify-center">
                                                    {{ noOfUsersAtLocation(item.id, '') }}
                                                </app-text>
                                            </template>

                                            <!--Actions-->
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-icon
                                                    @click="openQrCode(item.id, item, form.siteName, form.siteTelephone)">
                                                    icons8-qr-code
                                                </v-icon>
                                                <!-- <v-icon>icons8-bar-chart</v-icon> -->
                                                <v-icon
                                                    @click="MIX_deleteCollectionArray(item.id, item, 'locations', 'sites', form.id)">
                                                    icons8-trash
                                                </v-icon>
                                            </template>

                                        </v-data-table>
                                    </v-col>
                                </v-row>

                            </v-tab-item>

                        </v-tabs-items>

                    </v-row>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'; // Map the Getters from the Vuex Store

export default {
    name: 'Site',

    data: () => ({
        collection: 'sites',
        collectionTitle: 'Site',
        collectionItem: 'site',
        collectionItemTitle: 'Site',
        form: {
            id: '',
            siteName: '',
            siteTelephone: '',
            siteAddress: {
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                town: '',
                county: '',
                postcode: '',
            },
            siteContact: {
                contactName: '',
                contactPosition: '',
                contactTelephone: '',
                contactMobile: '',
                contactEmail: ''
            },
            siteManager: '',
            siteNotes: '',
            locations: [],
            createdUserData: {},
            createdDateTime: '',
            modifiedUserData: {},
            modifiedDateTime: '',
        },
        errors: {
            siteName: false,
            siteNameErrorMessage : '',
            locations: false,
            siteTelephone: false,
            siteTelephoneErrorMessage: '',
            siteName: false,
            siteNameErrorMessage: '',
            siteTelephone: false,
            siteTelephoneErrorMessage: '',
            siteAddressLine1: false,
            siteAddressLine1ErrorMessage: '',
            siteAddressLine2: false,
            siteAddressLine2ErrorMessage: '',
            siteAddressLine3: false,
            siteAddressLine3ErrorMessage: '',
            siteTown: false,
            siteTownErrorMessage: '',
            siteCounty: false,
            siteCountyErrorMessage: '',
            sitePostcode: false,
            sitePostcodeErrorMessage: '',
            // site Manager / Contact
            siteContactName: false,
            siteContactNameErrorMessage: '',
            siteContactPosition: false,
            siteContactPositionErrorMessage: '',
            siteContactTelephone: false,
            siteContactTelephoneErrorMessage: '',
            siteContactMobile: false,
            siteContactMobileErrorMessage: '',
            siteContactEmail: false,
            siteContactEmailErrorMessage: '',
        },
        formBackground: 'grey lighten-3',
        formMode: '',
        formReadOnly: true,
        locationsTableData: [],
        showDeletedLocations: false,
        tabs: 'overview',
        usersData: [],
        usersTableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_openItem: 'GET_openSites',
            GET_panelLayout: 'GET_panelLayout', // Panel Layout used for Layout Structure
            GET_openSites: 'GET_openSites',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult', // Get Confirm Box Result
        }),

        locationsHeaders() {
            return [
                {text: 'Name', value: 'locationName', align: 'left', sortable: false, hidden: false, hide: false},
                {text: 'Staff', value: 'staff', align: 'center', sortable: false, hidden: false, hide: false},
                {text: 'Total', value: 'totalCount', align: 'center', sortable: false, hidden: false, hide: false},
                {text: 'Actions', value: 'actions', align: 'center', sortable: false, hidden: false, hide: false},
            ]
        },
    },

    methods: {
        ...mapActions({'ACT_openItem': 'ACT_openSites', 'ACT_openSites': 'ACT_openSites'}),

        /**
         * Select New Site Manager
         */
        setNewSiteManager() {
            const t = this
            const selectedManager = t.form.siteContact.contactName

            t.form = {
                ...t.form,
                siteContact: {
                    contactName: selectedManager.userName,
                    contactPosition: selectedManager.userJobTitle,
                    contactTelephone: selectedManager.userTelephone,
                    contactMobile: '',
                    contactEmail: selectedManager.userEmail
                },
                siteManager: selectedManager.userName,
            }
        },

        /**
         * Get Users Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the usersData array.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.usersData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.usersData.push(document)
                    }
                })
            })
        },

        /**
         * Validate
         *
         * Validates the required fields for presence and correct format.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const postCodeRegExp = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/
            
            t.errors.siteName = false
            t.errors.siteTelephone = false
            t.errors.siteAddressLine1 = false
            t.errors.siteAddressLine2 = false
            t.errors.siteAddressLine3 = false
            t.errors.siteTown = false
            t.errors.siteCounty = false
            t.errors.sitePostcode = false

            // Site Manager
            t.errors.siteContactName = false
            t.errors.siteContactPosition = false
            t.errors.siteContactTelephone = false
            t.errors.siteContactMobile = false
            t.errors.siteContactEmail = false

            // Validate site name
            if(!t.form.siteName.trim()) {
                t.errors.siteName = true
                t.errors.siteNameErrorMessage = 'Site name is required'
            } else if (t.form.siteName.trim().length < 2) {
                t.errors.siteName = true
                t.errors.siteNameErrorMessage = 'Site name must be longer than 2 characters'
            } else {
                t.form.siteName = t.form.siteName.trim()
            }

            // No contact number is present
            if (!t.form.siteTelephone.trim()) {
                t.errors.siteTelephone = true
                t.errors.siteTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.siteTelephone.trim()[1]) && (t.form.siteTelephone.trim().length < 10 || t.form.siteTelephone.trim().length > 11)) {
                t.errors.siteTelephone = true
                t.errors.siteTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.siteTelephone.trim()[1]) && t.form.siteTelephone.trim().length !== 11) {
                t.errors.siteTelephone = true
                t.errors.siteTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.siteTelephone[1])) {
                t.errors.siteTelephone = true
                t.errors.siteTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // OPTIONAL - If 1st line of address is entered, it must be at least 2 characters long
            if(!t.form.siteAddress.addressLine1) {
                t.errors.siteAddressLine1 = false
            }
            else if (t.form.siteAddress.addressLine1.trim().length < 2) {
                t.errors.siteAddressLine1 = true
                t.errors.siteAddressLine1ErrorMessage = '1st line of Address must be at least 2 characters long'
            } 
            else { 
                t.form.siteAddress.addressLine1 = t.form.siteAddress.addressLine1.trim()
            }

            // OPTIONAL - If 2nd line of address is entered, it must be at least 2 characters long
            if(!t.form.siteAddress.addressLine2) {
                t.errors.siteAddressLine2 = false
            }
            else if (t.form.siteAddress.addressLine2.trim().length < 2) {
                t.errors.siteAddressLine2 = true
                t.errors.siteAddressLine2ErrorMessage = '2nd line of Address must be at least 2 characters long'
            }
            else { 
                t.form.siteAddress.addressLine2 = t.form.siteAddress.addressLine2.trim()
            }

            // OPTIONAL - If 3rd line of address is entered, it must be at least 2 characters long
            if(!t.form.siteAddress.addressLine3) {
                t.errors.siteAddressLine3 = false
            }
            else if (t.form.siteAddress.addressLine3.trim().length < 2) {
                t.errors.siteAddressLine3 = true
                t.errors.siteAddressLine3ErrorMessage = '3rd line of Address must be at least 2 characters long'
            }
            else { 
                t.form.siteAddress.addressLine3 = t.form.siteAddress.addressLine3.trim()
            }

            // OPTIONAL - If town is entered, it must be at least 2 characters long
            if (!t.form.siteAddress.town) {
                t.errors.siteTown = false
            }
            else if (t.form.siteAddress.town.trim().length < 2) {
                t.errors.siteTown = true
                t.errors.siteTownErrorMessage = 'Town must be at least 2 characters long'
            }
            else {
                t.form.siteAddress.town = t.form.siteAddress.town.trim() 
            }

            // OPTIONAL - If county is entered, it must be at least 2 characters long
            if (!t.form.siteAddress.county) {
                t.errors.siteCounty = false
            }
            else if (t.form.siteAddress.county.trim().length < 2) {
                t.errors.siteCounty = true
                t.errors.siteCountyErrorMessage = 'County must be at least 2 characters long'
            }
            else {
                t.form.siteAddress.county = t.form.siteAddress.county.trim() 
            }

            // OPTIONAL - If Postcode is entered, it must be in a valid format
            if (!t.form.siteAddress.postcode) {
                t.errors.sitePostcode = false
            } else if (!t.form.siteAddress.postcode.trim() || !postCodeRegExp.test(t.form.siteAddress.postcode)) {
                t.errors.sitePostcode = true
                t.errors.sitePostcodeErrorMessage = 'Postcode is not in the correct format'
            }

            // No Site Contact Position is present
            if (!t.form.siteContact.contactPosition.trim()) {
                t.errors.siteContactPosition = true
                t.errors.siteContactPositionErrorMessage = 'Position is required'
            }

            // Site Contact Position is too short
            else if (t.form.siteContact.contactPosition.trim().length < 2) {
                t.errors.siteContactPosition = true
                t.errors.siteContactPositionErrorMessage = 'Position must be at least 2 characters long'
            }

            // Ensure the Site Contact Position is trimmed
            else {
                t.form.siteContact.contactPosition = t.form.siteContact.contactPosition.trim()
            }

            // REQUIRED - Site Contact's Number
            if (!t.form.siteContact.contactTelephone.trim()) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.siteContact.contactTelephone.trim()[1]) && (t.form.siteContact.contactTelephone.trim().length < 10 || t.form.siteContact.contactTelephone.trim().length > 11)) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.siteContact.contactTelephone.trim()[1]) && t.form.siteContact.contactTelephone.trim().length !== 11) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.siteContact.contactTelephone[1])) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // OPTIONAL - Site Contact's Mobile Number
            if (!t.form.siteContact.contactMobile) {
                t.errors.siteContactMobile = false
            }
            // Mobile Numbers must be 11 digits
            else if (t.form.siteContact.contactMobile.trim().length !== 11) {
                t.errors.siteContactMobile = true
                t.errors.siteContactMobileErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Mobile numbers must start 07
            else if (['0','1', '2', '3', '4', '5', '6', '8', '9'].includes(t.form.siteContact.contactMobile[1]) || ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(t.form.siteContact.contactMobile[0])) {
                t.errors.siteContactMobile = true
                t.errors.siteContactMobileErrorMessage = 'Mobile numbers must start 07'
            }

            // No email is present
            if (!t.form.siteContact.contactEmail.trim()) {
                t.errors.siteContactEmail = true
                t.errors.siteContactEmailErrorMessage = 'Email is required'
            }
            // Email is not valid
            else if (!/.+@.+\..+/.test(t.form.siteContact.contactEmail)) {
                t.errors.siteContactEmail = true
                t.errors.siteContactEmailErrorMessage = 'Email is not valid'
            }
            // Trim the email
            else {
                t.form.siteContact.contactEmail = t.form.siteContact.contactEmail.trim()
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.saveItem()
            }
        },

        async openItem() {
            const t = this

            const openItem = await t.GET_openItem; // Get Open Item Data

            switch (openItem.process) {
                case 'New':
                    t.formMode = 'New'
                    t.formReadOnly = false
                    t.formBackground = 'white'
                    break
                case 'View':
                    const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item1.data
                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'
                    break
                case 'Edit':
                    const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item2.data
                    t.formMode = 'Edit'
                    t.formReadOnly = false
                    t.formBackground = 'white'
                    break
            }

        },

        async undeleteItem(id) {
            const t = this

            const undeleteResult = await t.MIX_undeleteDocument(t.collection, id)

            if (undeleteResult.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} Restored`, undeleteResult.data, null)
            } else {
                t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error)
                console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
            }

            await t.refreshItemView();
        },

        deleteItem(id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong>?</div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        editItem() {
            const t = this

            t.formMode = 'Edit'
            t.formReadOnly = false
            t.formBackground = 'white'
        },

        cancelItem() {
            const t = this

            t.formMode = 'View'
            t.formReadOnly = true
            t.formBackground = 'grey lighten-3'

            t.refreshItemView(t.form.id)
        },

        async saveItem() {
            const t = this

            // If this is a new document, create it
            if (t.formMode === 'New') {

                const createDocumentResult = await t.MIX_createDocument(t.collection, t.form)

                if (createDocumentResult.code === 1) {
                    t.MIX_alert(1, `${t.collectionItemTitle} Saved`, null, null)

                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'

                    await t.refreshItemView(createDocumentResult.data.id)

                } else {
                    t.MIX_alert(-1, `This site already exists`, null, null)
                }
            }

            // If this is editing a document, update it
            else if (t.formMode === 'Edit') {

                const updateDocumentResult = await this.MIX_updateDocument(t.collection, t.form)

                if (updateDocumentResult.code === 1) {
                    t.MIX_alert(1, `Site ${t.form.clientName} was Updated`, updateDocumentResult.data, null)

                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.formBackground = 'grey lighten-3'

                    await t.refreshItemView(t.form.id)

                } else {
                    t.MIX_alert(-1, 'ERROR - Could not update Site', null, updateDocumentResult.error)
                }
            }
        },

        async refreshItemView(itemId) {
            const t = this

            t.form.id = itemId
            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            const payload = {}
            payload.process = 'View'
            payload.popup = false
            payload.data = itemResult.data
            t.ACT_openItem(payload)

            await t.openItem()
        },

        async getLocations(openSite) {
            const t = this

            if (JSON.stringify(openSite) !== '{}') {
                t.$firebase.db.collection('locations').where("site.id", "==", openSite.data.id)
                    .onSnapshot(function (querySnapshot) {
                        t.locationsTableData = []
                        querySnapshot.forEach(function (doc) {
                            const document = doc.data()
                            document.id = doc.id
                            if (!document.hasOwnProperty('delete')) {
                                t.locationsTableData.push(document);
                            } else if (t.showDeletedConcerns) {
                                t.locationsTableData.push(document)
                            } else {
                                // Do not Show Concern
                            }
                        })
                    })
            }
        },

        async getUsers(openSite) {
            const t = this

            if (JSON.stringify(openSite) !== '{}') {

                t.$firebase.db.collection('users').where("lastSite.siteId", "==", openSite.data.id)
                    .onSnapshot(function (querySnapshot) {

                        // Clear the array to avoid data duplication
                        t.usersTableData = []

                        querySnapshot.forEach(function (doc) {

                            const document = doc.data()
                            document.id = doc.id

                            t.usersTableData.push(document)
                        })
                    })
            }
        },

        noOfUsersAtLocation(locationId, userType) {
            let count = 0

            // If a user type is specified
            if (userType !== '') {
                for (let i = 0; i < this.usersTableData.length; i++) {
                    if (locationId === this.usersTableData[i].lastLocation.locationId && this.usersTableData[i].swappStatus === 1 && this.usersTableData[i].userType === userType) {
                        count++
                    }
                }
            } else {
                for (let i = 0; i < this.usersTableData.length; i++) {
                    if (locationId === this.usersTableData[i].lastLocation.locationId && this.usersTableData[i].swappStatus === 1) {
                        count++
                    }
                }
            }

            return count
        },

        openQrCode(id, item, siteName, siteTelephone) {
            const t = this

            t.MIX_generateCode(true, id, 300, "H", "qrcode", "canvas", "#ffffff", "#000000", true, item.locationName, siteName, siteTelephone)
        },

    },

    watch: {
        // GET CONFIRMATION RESULT (CUSTOM)
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === t.collection) {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {

                        const deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)

                        if (deleteResult.code === 1) {
                            t.MIX_alert(1, `${t.collectionItemTitle} Deleted`, deleteResult.data, null)
                            t.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ''})
                        } else {
                            t.MIX_alert(-1, `Error Deleting ${t.collectionItemTitle}`, null, deleteResult.error)
                        }
                    }
                } else {
                    // Do nothing
                }
            }, deep: true
        },
    },

    // * MOUNTED
    async mounted() {
        const t = this

        // Fetch collection data
        await t.getUsersCollectionData()

        const openItem = await t.GET_openItem // Get Open Project Data
        t.formMode = openItem.process // Show Form in View Mode
        if (openItem.process === 'New') {
            t.formReadOnly = false
            t.formBackground = 'white'
        } else {
            t.form = openItem.data
            t.formReadOnly = true
            t.formBackground = 'grey lighten-3'
        }

        // GET LOCATIONS
        await t.getLocations(openItem)
        await t.getUsers(openItem)

    }
}
</script>

<style scoped>
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
</style>
