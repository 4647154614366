import { mapActions } from 'vuex';

const puDialog_mixins = {       
  data: () => ({
   
  }),
  methods: {
    ...mapActions({
        ACT_puDialog: 'puDialog_store/ACT_puDialog'
    }),
    MIX_puDialog (show, dynamicComponent, width, height){
      var payload = {};
      payload.show = show;
      payload.dynamicComponent = dynamicComponent;
      payload.width = width;
      payload.height = height;
      this.ACT_puDialog(payload);
    },
  }
}

export default {
  install (Vue, options) {
    Vue.mixin(puDialog_mixins)
  }
};  
  