<!--
Staff

Renders the form (right side) of Staff Directory to add/edit/delete users.
Opening an existing user will also show their SWAPP Location History and allows Admins to SWAPP users in and out.
-->
<template>
    <div>

        <!--Header-->
        <!--
         - Renders the title bar with form title and close icon.
         - Under it, another bar containing the selected user's name and status, and status acton buttons.
         -->
        <v-row class="contenttitle grey lighten-2 ma-0 pa-2 d-flex align-center white">

            <!--Username and Status-->
            <div class="ml-2">

                <!--Pending-->
                <app-text v-if="form.userStatus === 'PENDING'" category="text-default" class="mt-1">
                    <v-icon class="icons8-circle mb-1 mr-2" color="accent"/>
                    {{ form.userName }}
                </app-text>

                <!--Approved-->
                <app-text v-if="form.userStatus === 'APPROVED'" category="text-default" class="mt-1">
                    <v-icon class="icons8-checkmark-yes mb-1 mr-2" color="success"/>
                    {{ form.userName }}
                </app-text>

                <!--Suspended-->
                <app-text v-if="form.userStatus === 'SUSPENDED'" category="text-default" class="mt-1">
                    <v-icon class="icons8-private mb-1 mr-2" color="error"/>
                    {{ form.userName }}
                </app-text>

                <!--Archived-->
                <app-text v-if="form.userStatus === 'ARCHIVED'" category="text-default" class="mt-1">
                    <v-icon class="icons8-xbox-a mb-1 mr-2" color="grey"/>
                    {{ form.userName }}
                </app-text>

            </div>

            <v-spacer/>

            <!--User status action buttons-->
            <div class="d-flex">

                <!--Approve-->
                <appbutton v-if="form.userStatus === 'PENDING' && formMode !== 'Edit' && formMode !== 'New'"
                           label="Approve" icon="icons8-checkmark-yes" type="success" class="ma-0"
                           @click.native="updateItemStatus('APPROVED')" showlabel="xlOnly"/>

                <!--Reject-->
                <appbutton v-if="form.userStatus === 'PENDING' && formMode !== 'Edit' && formMode !== 'New'"
                           label="Reject" icon="icons8-high-importance" type="warning" class="ma-0"
                           @click.native="updateItemStatus('REJECTED')" showlabel="xlOnly"/>

                <!--Suspend-->
                <!--<appbutton v-if="form.userStatus !== 'SUSPENDED' && formMode !== 'Edit' && formMode !== 'New'"
                label="Suspend" icon="icons8-private" type="error" class="ma-0" @click.native="updateItemStatus('SUSPENDED')" showlabel="xlOnly" />-->

                <!--Restore-->
                <appbutton v-if="form.userStatus === 'SUSPENDED' && formMode !== 'Edit' && formMode !== 'New'"
                           label="Restore" icon="icons8-process" type="success" class="ma-0"
                           @click.native="updateItemStatus('APPROVED')" showlabel="xlOnly"/>

            </div>

        </v-row>

        <!--Page content-->
        <!--
         - Tabbed content for Overview and Location History forms
        -->
        <v-container fluid class="pa-0">
            <v-row no-gutters>

                <!--Tabs (Overview | Location History)-->
                <v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">
                    <v-tabs-slider color="primary" background-color="black"></v-tabs-slider>

                    <!--Overview-->
                    <v-tab href="#overview">
                        <app-text category="text-small">Overview</app-text>
                    </v-tab>

                    <!--Location History-->
                    <v-tab v-if="formMode !== 'New'" href="#location">
                        <app-text category="text-small">Location History</app-text>
                    </v-tab>

                </v-tabs>

                <!--Tabs Content (Overview | Location History)-->
                <v-tabs-items v-model="tabs" class="appbackground">

                    <!--Overview tab content-->
                    <v-tab-item value="overview" class="pa-0">

                        <!--Description | Action buttons-->
                        <v-row class="pa-4" no-gutters>

                            <!-- Description -->
                            <v-col class="pa-0" cols="12">

                                <!--Title-->
                                <app-text category="text-default-bold">Overview</app-text>

                                <!--Info-->
                                <app-text category="text-default">
                                    Use the form below to Edit Staff details or change the type, roll or user
                                    status.
                                </app-text>

                                <v-divider class="my-4"/>

                            </v-col>

                            <!-- Action Buttons -->
                            <v-col class="d-flex">

                                <!--Undelete-->
                                <appbutton v-if="formMode !== 'New' && form.delete !== undefined"
                                           label="Undelete" icon="icons8-process" class="mr-2" type="grey"
                                           @click.native="undeleteItem(form.id)" :na="formMode !== 'View'"
                                           :class="{ disabled: formMode !== 'View' }" showlabel="xlOnly"/>

                                <!--Delete-->
                                <appbutton v-if="formMode !== 'New' && form.delete === undefined"
                                           @click.native="deleteItem(form.id)" :na="formMode !== 'View'"
                                           label="Delete" icon="icons8-trash" class="mr-2" type="error"
                                           :class="{ disabled: formMode !== 'View' }" showlabel="xlOnly"/>

                                <!--Edit-->
                                <appbutton v-if="formMode !== 'New'"
                                           @click.native="editItem()"
                                           label="Edit" icon="icons8-edit-property" type="warning" class="mr-2"
                                           :na="formMode !== 'View'" :class="{ disabled: formMode !== 'View' }"
                                           showlabel="xlOnly"/>

                                <v-spacer/>

                                <!--Cancel-->
                                <v-btn v-if="formMode === 'Edit'" @click.native="cancelItem()"
                                       class="mr-4" color="grey" depressed :height="buttonSizeDefault">
                                    <v-icon class="icons8-cancel" color="white"/>
                                    <app-text category="text-default" class="white--text ml-2">Cancel</app-text>
                                </v-btn>

                                <!--Save-->
                                <v-btn v-if="formMode === 'Edit' || formMode === 'New'"
                                       @click.native="validateForm()"
                                       color="success" depressed :height="buttonSizeDefault">
                                    <v-icon class="icons8-save"/>
                                    <app-text category="text-default" class="ml-2">Save</app-text>
                                </v-btn>

                            </v-col>

                        </v-row>

                        <!--Form-->
                        <v-row class="ma-0 px-2" no-gutters>

                            <!--Profile Picture | User Details-->
                            <v-row no-gutters style="width: 100%">

                                <!--Profile picture-->
                                <v-col
                                    class="d-flex justify-center align-center pa-2 rounded white profileImage-container"
                                    :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mt-1 mr-1' : 'mb-1'"
                                    :cols="$vuetify.breakpoint.width < 600 && 12"
                                    style="height: 232px; width: 232px">

                                    <!--If an image is present, render it-->
                                    <div v-if="form.profilePicFileURL && !tempImage"
                                         class="d-flex flex-column align-center">

                                        <!--Image-->
                                        <v-img :src="form.profilePicFileURL"
                                               class="rounded" cover height="216" width="216"/>

                                        <!--Upload button-->
                                        <div v-if="formMode === 'Edit'"
                                             style="position: absolute; z-index: 9; margin-top: 152px">

                                            <photoupload class="mr-n4"
                                                         allowedtypes="image/*"
                                                         :docLink="{collection: 'users', documentId: form.id}"
                                                         folder="users-profile-pictures"/>

                                        </div>

                                    </div>

                                    <!--If a tempImage (upload preview) is present, render it-->
                                    <div v-else-if="tempImage"
                                         class="d-flex flex-column align-center">

                                        <!--Image-->
                                        <v-img :src="tempImage"
                                               class="rounded" cover height="216" width="216"/>

                                        <!--Upload button-->
                                        <div style="position: absolute; z-index: 9; margin-top: 152px">

                                            <photoupload class="mr-n4"
                                                         allowedtypes="image/*"
                                                         :docLink="{collection: 'users', documentId: form.id}"
                                                         folder="users-profile-pictures"/>

                                        </div>

                                    </div>

                                    <!--If neither an image or tempImage is present, render an icon-->
                                    <div v-else class="d-flex flex-column align-center" style="width: 216px">

                                        <!--Image-->
                                        <v-icon class="icons8-customer" size="216"/>

                                        <!--Upload button-->
                                        <div v-if="formMode === 'New' || formMode === 'Edit'"
                                             style="position: absolute; z-index: 9; margin-top: 152px">

                                            <photoupload class="mr-n4"
                                                         style="width: 100%"
                                                         allowedtypes="image/*"
                                                         :docLink="{collection: 'users', documentId: form.id}"
                                                         folder="users-profile-pictures"/>

                                        </div>

                                    </div>

                                </v-col>

                                <!-- User details -->
                                <v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
                                       :cols="$vuetify.breakpoint.width < 600 && 12">

                                    <!--Name-->
                                    <v-text-field
                                        :background-color="formBackground"
                                        class="pa-1"
                                        dense
                                        :disabled="formReadOnly"
                                        :error="!formReadOnly ? errors.userName : errors.userName = false"
                                        :error-messages="errors.userName ? errors.userNameErrorMessage : ''"
                                        filled
                                        hide-details="auto"
                                        label="Full Name"
                                        v-model="form.userName"/>

                                    <!--Job Title-->
                                    <v-text-field
                                        :background-color="formBackground"
                                        class="pa-1"
                                        dense
                                        :disabled="formReadOnly"
                                        :error="!formReadOnly ? errors.userJobTitle : errors.userJobTitle = false"
                                        :error-messages="errors.userJobTitle ? errors.userJobTitleErrorMessage : ''"
                                        filled
                                        hide-details="auto"
                                        label="Job Title"
                                        v-model="form.userJobTitle"/>

                                    <!--Email-->
                                    <v-text-field
                                        :background-color="formBackground"
                                        class="pa-1"
                                        dense
                                        :disabled="formReadOnly"
                                        :error="!formReadOnly ? errors.userEmail: errors.userEmail = false"
                                        :error-messages="errors.userEmail ? errors.userEmailErrorMessage : ''"
                                        filled
                                        hide-details="auto"
                                        label="Email"
                                        v-model="form.userEmail"/>

                                    <!--Telephone-->
                                    <v-text-field
                                        :background-color="formBackground"
                                        class="pa-1"
                                        dense
                                        :disabled="formReadOnly"
                                        :error="!formReadOnly ? errors.userTelephone: errors.userTelephone = false"
                                        :error-messages="errors.userTelephone ? errors.userTelephoneErrorMessage: ''"
                                        filled
                                        hide-details="auto"
                                        label="Telephone"
                                        type="number"
                                        v-model.trim="form.userTelephone"/>

                                    <!--Contract Hours-->
                                    <!--<v-text-field label="Contract Hours" v-model="form.contractHours" filled hide-details :background-color="formBackground" dense :clearable="!formReadOnly" :disabled="formReadOnly"-->
                                    <!--              class="pa-1"/>-->
                                </v-col>

                            </v-row>

                            <!-- DOB -->
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field @click="MIX_datePicker(true, 'userDOB')"
                                              append-icon="mdi-calendar" 
                                              :background-color="formBackground" 
                                              dense
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userDOB : errors.userDOB = false"
                                              :error-messages="errors.userDOB ? errors.userDOBErrorMessage: ''"
                                              filled 
                                              hide-details="auto"
                                              label="Date Of Birth (optional)"
                                              readonly
                                              v-model="formattedUserDOB">
                                    {{ formatDOB }}
                                </v-text-field>
                            </v-col>

                            <!-- Start Date -->
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field @click="MIX_datePicker(true, 'userStartDate')"
                                              append-icon="mdi-calendar" 
                                              :background-color="formBackground"
                                              dense 
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userStartDate : errors.userStartDate = false"
                                              :error-messages="errors.userStartDate ? errors.userStartDateErrorMessage: ''"     
                                              filled 
                                              hide-details="auto"
                                              label="Start Date (optional)"
                                              readonly
                                              v-model="formattedStartDate">
                                    {{ formatStartDate }}
                                </v-text-field>
                            </v-col>

                            <!-- User Category -->
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-select :background-color="formBackground"
                                          dense
                                          :disabled="formReadOnly"
                                          :error="!formReadOnly ? errors.userCategory : errors.userCategory = false"
                                          :error-messages="errors.userCategory ? errors.userCategoryErrorMessage: ''"    
                                          filled 
                                          hide-details="auto"
                                          :items="userCategoriesOptionsData"
                                          item-text="text"
                                          item-value="value"
                                          label="User Category"
                                          v-model="form.userCategory">
                                    <template v-slot:item="data">
                                        <app-text category="text-default">{{ data.item }}</app-text>
                                    </template>
                                </v-select>
                            </v-col>

                            <!-- Base location -->
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-select :background-color="formBackground"
                                          dense
                                          :disabled="formReadOnly"
                                          :error="!formReadOnly ? errors.usualSite : errors.usualSite = false"
                                          :error-messages="errors.usualSite ? errors.usualSiteErrorMessage: ''"    
                                          filled
                                          hide-details="auto"
                                          :items="sitesData"
                                          item-text="siteName"
                                          label="Primary Work Location"
                                          v-model="form.usualSite">
                                    <template v-slot:item="data">
                                        <app-text category="text-default">{{ data.item.siteName }}</app-text>
                                    </template>
                                </v-select>
                            </v-col>

                            <!-- Address -->
                            <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <app-text category="text-medium" class="darkgrey--text">Address</app-text>
                                <v-divider class="my-2"/>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userAddressLine1 : errors.userAddressLine1 = false"
                                              :error-messages="errors.userAddressLine1 ? errors.userAddressLine1ErrorMessage: ''"    
                                              filled 
                                              hide-details="auto"
                                              label="Address Line 1 (optional)"
                                              v-model="form.userAddress.userAddressLine1"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userAddressLine2 : errors.userAddressLine2 = false"
                                              :error-messages="errors.userAddressLine2 ? errors.userAddressLine2ErrorMessage: ''"  
                                              filled 
                                              hide-details="auto"
                                              label="Address Line 2 (optional) "
                                              v-model="form.userAddress.userAddressLine2"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userAddressLine3 : errors.userAddressLine3 = false"
                                              :error-messages="errors.userAddressLine3 ? errors.userAddressLine3ErrorMessage: ''"  
                                              filled 
                                              hide-details="auto"
                                              label="Address Line 3 (optional)"
                                              v-model="form.userAddress.userAddressLine3"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userTown : errors.userTown = false"
                                              :error-messages="errors.userTown ? errors.userTownErrorMessage: ''"  
                                              filled
                                              hide-details="auto"
                                              label="Town (optional)"
                                              v-model="form.userAddress.userTown"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userCounty : errors.userCounty = false"
                                              :error-messages="errors.userCounty ? errors.userCountyErrorMessage: ''"
                                              filled 
                                              hide-details="auto"
                                              label="County (optional)"
                                              v-model="form.userAddress.userCounty"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.userPostcode : errors.userPostcode = false"
                                              :error-messages="errors.userPostcode ? errors.userPostcodeErrorMessage: ''"  
                                              filled
                                              hide-details="auto"
                                              label="Postcode (optional)"
                                              v-model="form.userAddress.userPostcode"></v-text-field>
                            </v-col>

                            <!-- Next of Kin -->
                            <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <app-text category="text-medium" class="darkgrey--text">Next of Kin</app-text>
                                <v-divider class="my-2"/>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-text-field :background-color="formBackground" 
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.NOKName : errors.NOKName = false"
                                              :error-messages="errors.NOKName ? errors.NOKNameErrorMessage: ''"  
                                              filled 
                                              hide-details="auto"
                                              label="Name (optional)" 
                                              v-model="form.NOKName"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-text-field :background-color="formBackground"
                                              :error="!formReadOnly ? errors.NOKRelationship : errors.NOKRelationship = false"
                                              :error-messages="errors.NOKRelationship ? errors.NOKRelationshipErrorMessage: ''" 
                                              filled
                                              hide-details="auto"
                                              label="Relationship (optional)"
                                              :disabled="formReadOnly"
                                              v-model="form.NOKRelationship"></v-text-field>
                            </v-col>
                            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-text-field :background-color="formBackground"
                                              :disabled="formReadOnly"
                                              :error="!formReadOnly ? errors.NOKTelNo : errors.NOKTelNo = false"
                                              :error-messages="errors.NOKTelNo ? errors.NOKTelNoErrorMessage: ''" 
                                              filled
                                              hide-details="auto"
                                              label="Telephone Number (optional)"
                                              type="number"
                                              v-model="form.NOKTelNo"></v-text-field>
                            </v-col>

                            <!-- Configuration -->
                            <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <app-text category="text-medium" class="darkgrey--text">Configuration</app-text>
                                <v-divider class="my-2"/>
                            </v-col>

                            <!--User type-->
                            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-select :background-color="formBackground"
                                          :disabled="formReadOnly"
                                          dense
                                          filled
                                          hide-details
                                          :items="userTypesOptionsData"
                                          item-text="text"
                                          item-value="value"
                                          label="User type"
                                          v-model="form.userType">
                                    <template v-slot:item="data">
                                        <app-text category="text-default">{{ data.item.text }}</app-text>
                                    </template>
                                </v-select>
                            </v-col>

                            <!--User role-->
                            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-select :background-color="formBackground"
                                          :disabled="formReadOnly"
                                          dense
                                          filled
                                          hide-details
                                          :items="userRolesOptionsData"
                                          item-text="text"
                                          item-value="value"
                                          label="User Role"
                                          v-model="form.userRole">
                                    <template v-slot:item="data">
                                        <app-text category="text-default">{{ data.item.text }}</app-text>
                                    </template>
                                </v-select>
                            </v-col>

                            <!--User status-->
                            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <v-select :background-color="formBackground"
                                          :disabled="formReadOnly"
                                          dense
                                          filled
                                          hide-details
                                          :items="userStatusOptionsData"
                                          item-text="text"
                                          item-value="value"
                                          label="User Status"
                                          v-model="form.userStatus">
                                    <template v-slot:item="data">
                                        <app-text category="text-default">{{ data.item.text }}</app-text>
                                    </template>
                                </v-select>
                            </v-col>

                        </v-row>

                        <!--Created and Modified info -->
                        <v-col v-if="formMode !== 'New'" class="px-3 py-4" cols="12" xs="12" sm="12" md="12"
                               lg="12" xl="12">

                            <v-divider/>

                            <div>
                                <app-text category="text-small-bold" class="grey--text mt-4">
                                    Created:
                                    <span class="font-weight-regular">{{ form.createdUserData.userName
                                        }} - {{ MIX_formatDateTime(form.createdDateTime, "x", "DD-MMM-YYYY")
                                        }}</span>
                                    |
                                    Modified:
                                    <span class="font-weight-regular">{{ form.modifiedUserData.userName
                                        }} - {{ MIX_formatDateTime(form.modifiedDateTime, "x", "DD-MMM-YYYY")
                                        }}</span>
                                </app-text>
                            </div>

                        </v-col>

                    </v-tab-item>

                    <!--Location History tab content-->
                    <v-tab-item value="location" class="pa-0">

                        <!--
                        Location History
                        -->
                        <v-row class="pa-4" no-gutters>

                            <!-- Description -->
                            <v-col class="pa-0" cols="12">
                                <app-text category="text-default-bold">Location</app-text>
                                <app-text category="text-default">
                                    Use the form below to SWAPP Staff IN and OUT. <br/>
                                    A history of their SWAPPing can be found below.
                                </app-text>

                                <v-divider class="my-4"/>
                            </v-col>

                            <!--Action buttons-->
                            <v-col class="d-flex">

                                <!--SWAPP in-->
                                <appbutton v-if="form.swappStatus === 0 && modifySwappStatus === false"
                                           label="SWAPP IN" @click.native="swappUser(1)" type="primary"
                                           :na="modifySwappStatus === true"
                                           :class="{ disabled: modifySwappStatus === true }"/>

                                <!--SWAPP out-->
                                <appbutton v-if="form.swappStatus === 1 && modifySwappStatus === false"
                                           label="SWAPP OUT" @click.native="swappUser(0)" type="primary"
                                           :na="modifySwappStatus === true"
                                           :class="{ disabled: modifySwappStatus === true }"/>

                                <v-spacer/>

                                <!--Cancel-->
                                <appbutton v-if="modifySwappStatus === true" label="CANCEL"
                                           @click.native="cancelSwappStatusUpdate()" icon="icons8-cancel"
                                           type="grey darken-2"/>

                                <!--Update SWAPP status-->
                                <appbutton v-if="modifySwappStatus === true" label="UPDATE SWAPP STATUS"
                                           @click.native="updateSwappStatus()" icon="icons8-save" type="info"/>

                            </v-col>

                        </v-row>

                        <!--
                        Form
                        -->
                        <v-row class="ma-0 px-4" no-gutters>

                            <!--Last site-->
                            <v-col :class="$vuetify.breakpoint.width < 600 ? 'mb-2' : 'pr-1'" cols="12" xs="12"
                                   sm="4" md="4" lg="4" xl="4">
                                <app-text category="text-default"
                                          v-if="modifySwappStatus === true && swappDirection === 0">To SWAPP OUT
                                    from site
                                    <strong>{{ form.lastSite.siteName }}</strong>, location
                                    <strong>{{ form.lastLocation.locationName }}</strong> just click the Update
                                    Swapp Status.
                                </app-text>
                                <v-text-field v-if="modifySwappStatus != true" :label="$t('fields.lastSite')"
                                              v-model="form.lastSite.siteName" filled hide-details
                                              background-color="white" dense :readonly="true"
                                              :disabled="formReadOnly"></v-text-field>
                                <v-autocomplete v-else-if="modifySwappStatus === true && swappDirection !== 0"
                                                @change="selectSiteLocation()" :label="$t('fields.lastSite')"
                                                v-model="selectedSite" filled hide-details
                                                background-color="white" dense :items="availableSites"
                                                item-text="siteName" return-object no-data-text="No Sites Found"
                                                :clearable="!formReadOnly"
                                                :disabled="formReadOnly"></v-autocomplete>
                            </v-col>

                            <!--Last location-->
                            <v-col :class="$vuetify.breakpoint.width < 600 ? 'mb-2' : 'px-1'" cols="12" xs="12"
                                   sm="4" md="4" lg="4" xl="4">
                                <v-text-field v-if="modifySwappStatus != true"
                                              :label="$t('fields.lastLocation')"
                                              v-model="form.lastLocation.locationName" filled hide-details
                                              background-color="white" dense :readonly="true"
                                              :disabled="formReadOnly"></v-text-field>
                                <v-autocomplete v-else-if="modifySwappStatus === true && swappDirection !== 0"
                                                @change="selectLocation()" :label="$t('fields.lastLocation')"
                                                v-model="selectedLocation" filled hide-details
                                                background-color="white" dense :items="availableLocations"
                                                item-text="locationName" return-object
                                                no-data-text="No Locations Found" :clearable="!formReadOnly"
                                                :disabled="formReadOnly"></v-autocomplete>
                            </v-col>

                            <!--Swapp status-->
                            <v-col :class="$vuetify.breakpoint.width < 600 ? 'mb-2' : 'pl-1'" cols="12" xs="12"
                                   sm="4" md="4" lg="4" xl="4">
                                <v-text-field :label="$t('fields.swappStatus')" v-if="form.swappStatus === 0"
                                              value="OUT" filled hide-details background-color="white" dense
                                              :readonly="true" :class="{ disabled: true }"></v-text-field>
                                <v-text-field :label="$t('fields.swappStatus')" v-if="form.swappStatus === 1"
                                              value="IN" filled hide-details background-color="white" dense
                                              :readonly="true" :class="{ disabled: true }"></v-text-field>
                            </v-col>

                            <!--
                            Table
                             - Render a table displaying a user's location history
                            -->
                            <v-col cols="12" class="mt-4">

                                <!--Title-->
                                <app-text category="text-medium" class="darkgrey--text">
                                    Location History
                                </app-text>

                                <v-divider class="my-2"/>

                                <!--Table-->
                                <v-data-table :headers="locationsHistoryHeaders" :items="filteredLocations"
                                              :items-per-page="5" class="elevation-1 mt-4">

                                    <!--Render swap status-->
                                    <template v-slot:[`item.swappStatus`]="{ item }">
                                        <v-chip class="success" small v-if="item.swappStatus === 1">IN</v-chip>
                                        <v-chip class="lightgrey" small v-else>OUT</v-chip>
                                    </template>

                                    <!--Render swap site-->
                                    <template v-slot:[`item.site.siteName`]="{ item }">
                                        <app-text category="text-default">
                                            {{ MIX_capitalizeText(item.site.siteName) }}
                                        </app-text>
                                    </template>

                                    <!--Render swap location-->
                                    <template v-slot:[`item.location.locationName`]="{ item }">
                                        <app-text category="text-default">
                                            {{ MIX_capitalizeText(item.location.locationName) }}
                                        </app-text>
                                    </template>

                                    <!--Render swap method-->
                                    <template v-slot:[`item.swappMethod`]="{ item }">
                                        <!--<app-text category="text-default" class="justify-center">{{ MIX_capitalizeText(item.swappMethod) }}</app-text>-->
                                        <app-text category="text-default" class="justify-center">
                                            {{ item.swappMethod }}
                                        </app-text>
                                    </template>

                                    <!--Render swap date-->
                                    <template v-slot:[`item.createdDateTime`]="{ item }">
                                        <app-text category="text-default" class="justify-center">
                                            {{ MIX_formatDateTime(item.createdDateTime, "x", "DD-MMM-YY @ HH:mm")
                                            }}
                                        </app-text>
                                    </template>

                                </v-data-table>

                            </v-col>

                        </v-row>

                    </v-tab-item>

                </v-tabs-items>

            </v-row>
        </v-container>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

export default {
    name: "StaffDirectory",

    data: () => ({
        collection: "users",
        collectionTitle: "Staff Directory",
        collectionItem: "user",
        collectionItemTitle: "Staff Directory",
        tabs: "overview",

        // * FORM RELATED
        formMode: "",
        formReadOnly: true,
        formBackground: "grey lighten-3",
        form: {
            id: "",
            authId: null,
            userName: "",
            userAddress: {
                userAddressLine1: "",
                userAddressLine2: "",
                userAddressLine3: "",
                userTown: "",
                userCounty: "",
                userPostcode: "",
            },
            userDOB: "",
            NOKName: "",
            NOKRelationship: "",
            NOKTelNo: "",
            userStartDate: "",
            userTelephone: "",
            contractHours: "",
            userEmail: "",
            userLevel: "",
            userType: "",
            userRole: "",
            userCategory: "",
            usualSite: "",
            userStatus: "",
            userJobTitle: "",
            privacyPolicyConsent: false,
            swappStatus: 0, //default 0
            swappMethod: null, //default null
            profilePicFileURL: null,
            lastLocation: {
                locationId: "",
                locationName: "",
            },
            lastSite: {
                siteId: "",
                siteName: "",
            },
            // Added from admin
            createdUserData: {},
            createdDateTime: "",
            modifiedUserData: {},
            modifiedDateTime: "",
        },
        // * PROFILE PICTURE
        types: "image/*",
        storagePathProfilePic: "users-profile-pictures",
        photoUploadResult: {},
        tempImage: '',

        //check if user was created in admin dashboard
        userInLocalStorage: false,
        userInLocalStorageId: null,

        formattedUserDOB: "",
        formattedStartDate: "",
        formattedAssesmentDate: "", // to set training assesmentDate
        expiryDate: "", // to set training expiryDate
        filterStateItems: ["CURRENT", "ARCHIVED"],
        filterTraining: {
            state: ["CURRENT"],
            status: ["ACTIVE", "REQUIRED", "DUE", "EXPIRED"],
            sortAsc: true,
        },
        sortBy: "assesmentDate",

        // * SWAPP STATUS RELATED
        modifySwappStatus: false, // modify swapp status
        availableLocations: [], // locations from the db
        allowLocationSelect: false, // only allow location select once a site is selected
        availableSites: [], // sites from the db
        selectedSite: null, // select site from options
        selectedLocation: null, // select location from options,
        swappDirection: 0,
        locationsHistory: [],
        errors: {
            userName: false,
            userNameErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
            userJobTitle: false,
            userJobTitleErrorMessage: '',
            userDOB: false,
            userDOBErrorMessage: '',
            userStartDate: false,
            userStartDateErrorMessage: '',
            userCategory: false,
            userCategoryErrorMessage: '',
            usualSite: false,
            usualSiteErrorMessage: '',
            userAddressLine1: false,
            userAddressLine1ErrorMessage: '',
            userAddressLine2: false,
            userAddressLine2ErrorMessage: '',
            userAddressLine3: false,
            userAddressLine3ErrorMessage: '',
            userTown: false,
            userTownErrorMessage: '',
            userCounty: false,
            userCountyErrorMessage: '',
            userPostcode: false,
            userPostcodeErrorMessage: '',
            NOKName: false,
            NOKNameErrorMessage: '',
            NOKRelationship: false,
            NOKRelationshipErrorMessage: '',
            NOKTelNo: false,
            NOKTelNoErrorMessage: '',

        },
        sitesData: []
    }),

    computed: {
        ...mapGetters({
            GET_openItem: "GET_openStaffDirectory",
            GET_panelLayout: "GET_panelLayout", // Panel Layout used for Layout Structure
            GET_openStaffDirectory: "GET_openStaffDirectory",
            GET_confirmBoxResult: "confirmBox_store/GET_confirmBoxResult", // Get Confirm Box Result
            GET_datePickerResult: "datePicker_store/GET_datePickerResult", // Get Date Picker Result
            GET_areas: "GET_areas", // Get Areas
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
            GET_lookupValues: 'GET_lookupValues',
        }),

         /**
         * Format DOB
         *
         * Convert the user's date of birth from a unix time to a readable format.
         */
        formatDOB() {
            const t = this

            t.formattedUserDOB = t.MIX_formatDateTime(t.form.userDOB, "x", "DD MMMM YYYY")
        },

        /**
         * Format Start Date
         *
         * Convert the user's start date from a unix time to a readable format.
         */
        formatStartDate() {
            const t = this

            t.formattedStartDate = t.MIX_formatDateTime(t.form.userStartDate, "x", "DD MMMM YYYY")
        },

        // LOCATIONS HISTORY HEADERS
        filteredLocations() {
            const t = this

            return t.MIX_sortByCreatedDateTime(t.locationsHistory)
        },

        locationsHistoryHeaders() {
            return [
                {text: "Status", value: "swappStatus", align: "center", sortable: false, hidden: false, hide: false},
                {text: "Site", value: "site.siteName", align: "left", sortable: false, hidden: false, hide: false},
                {
                    text: "Location",
                    value: "location.locationName",
                    align: "left",
                    sortable: false,
                    hidden: false,
                    hide: false
                },
                {text: "Method", value: "swappMethod", align: "center", sortable: false, hidden: false, hide: false},
                {
                    text: "Swapped at",
                    value: "createdDateTime",
                    align: "center",
                    sortable: false,
                    hidden: false,
                    hide: false
                },
            ];
        },
    },

    methods: {
        ...mapActions({ACT_openItem: "ACT_openStaffDirectory", ACT_openStaffDirectory: "ACT_openStaffDirectory"}),

        /**
         * Get Sites Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the sitesData array.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.sitesData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document)
                    }

                })
            })
        },

        /**
         * Validate
         *
         * Validates the required fields for presence and correct format or length.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const postCodeRegExp = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/
            const years = t.$moment().diff(Number(t.form.userDOB), 'years')

            t.errors.userName = false
            t.errors.userJobTitle = false
            t.errors.userEmail = false
            t.errors.userTelephone = false
            t.errors.userDOB = false
            t.errors.userStartDate = false
            t.errors.userCategory = false
            t.errors.usualSite = false
            t.errors.userAddressLine1 = false
            t.errors.userAddressLine2= false
            t.errors.userAddressLine3 = false
            t.errors.userTown = false
            t.errors.userCounty = false
            t.errors.userPostcode = false
            t.errors.NOKName = false
            t.errors.NOKRelationship = false
            t.errors.NOKTelNo = false

            // REQUIRED - No name is present
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name is required'
            }
            // REQUIRED - Name is too short or too long
            else if (t.form.userName.trim().length < 2 || t.form.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }
            // REQUIRED - Ensure the Name is trimmed
            else {
                t.form.userName = t.form.userName.trim()
            }

            // REQUIRED - No job title is present
            if (!t.form.userJobTitle.trim()) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job title is required'
            }
            // REQUIRED - Job title is too short
            else if (t.form.userJobTitle.trim().length < 2) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job title must be at least 2 characters long'
            }
            // Ensure the job title is trimmed
            else {
                t.form.userJobTitle = t.form.userJobTitle.trim()
            }

            // REQUIRED - No email is present
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // REQUIRED - Email is not valid
            else if (!/.+@.+\..+/.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }
            // Trim the email
            else {
                t.form.userEmail = t.form.userEmail.trim()
            }

            // REQUIRED - No contact number is present
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // OPTIONAL - If selected, DOB must be over 16
            if (!t.form.userDOB) {
                t.errors.userDOB = false
            } else if (years < 16) {
                t.errors.userDOB = true
                t.errors.userDOBErrorMessage = 'You Must be over 16'
            }

            // REQUIRED - No User Category has been selected
            if (!t.form.userCategory) {
                t.errors.userCategory = true
                t.errors.userCategoryErrorMessage = 'User Category is required'
            }

            // REQUIRED - No Primary Work Location selected
            if (!t.form.usualSite) {
                t.errors.usualSite = true
                t.errors.usualSiteErrorMessage = 'Primary Work Location is required'
            }
            
            // OPTIONAL - If 1st line of address is entered, it must be at least 2 characters long
            if(!t.form.userAddress.userAddressLine1) {
                t.errors.userAddressLine1 = false
            }
            else if (t.form.userAddress.userAddressLine1.trim().length < 2) {
                t.errors.userAddressLine1 = true
                t.errors.userAddressLine1ErrorMessage = '1st line of Address must be at least 2 characters long'
            } 
            else { 
                t.form.userAddress.userAddressLine1 = t.form.userAddress.userAddressLine1.trim()
            }

            // OPTIONAL - If 2nd line of address is entered, it must be at least 2 characters long
            if(!t.form.userAddress.userAddressLine2) {
                t.errors.userAddressLine2 = false
            }
            else if (t.form.userAddress.userAddressLine2.trim().length < 2) {
                t.errors.userAddressLine2 = true
                t.errors.userAddressLine2ErrorMessage = '2nd line of Address must be at least 2 characters long'
            }
            else { 
                t.form.userAddress.userAddressLine2 = t.form.userAddress.userAddressLine2.trim()
            }

            // OPTIONAL - If 3rd line of address is entered, it must be at least 2 characters long
            if(!t.form.userAddress.userAddressLine3) {
                t.errors.userAddressLine3 = false
            }
            else if (t.form.userAddress.userAddressLine3.trim().length < 2) {
                t.errors.userAddressLine3 = true
                t.errors.userAddressLine3ErrorMessage = '3rd line of Address must be at least 2 characters long'
            }
            else {
                t.form.userAddress.userAddressLine3 = t.form.userAddress.userAddressLine3.trim()
            }

            // OPTIONAL - If town is entered, it must be at least 2 characters long
            if (!t.form.userAddress.userTown) {
                t.errors.userTown = false
            }
            else if (t.form.userAddress.userTown.trim().length < 2) {
                t.errors.userTown = true
                t.errors.userTownErrorMessage = 'Town must be at least 2 characters long'
            }
            else {
                t.form.userAddress.userTown = t.form.userAddress.userTown.trim() 
            }

            // OPTIONAL - If county is entered, it must be at least 2 characters long
            if (!t.form.userAddress.userCounty) {
                t.errors.userCounty = false
            }
            else if (t.form.userAddress.userCounty.trim().length < 2) {
                t.errors.userCounty = true
                t.errors.userCountyErrorMessage = 'County must be at least 2 characters long'
            }
            else {
                t.form.userAddress.userCounty = t.form.userAddress.userCounty.trim()
            }

            // OPTIONAL - If Postcode is entered, it must be in a valid format
            if (!t.form.userAddress.userPostcode) {
                t.errors.userPostcode = false
            } else if (!t.form.userAddress.userPostcode.trim() || !postCodeRegExp.test(t.form.userAddress.userPostcode)) {
                t.errors.userPostcode = true
                t.errors.userPostcodeErrorMessage = 'Postcode is not in the correct format'
            }

            // OPTIONAL - Next of kin name too short
            if (!t.form.NOKName) {
                t.errors.NOKName = false
            } else if (t.form.NOKName.trim().length < 2) {
                t.errors.NOKName = true
                t.errors.NOKNameErrorMessage = 'Next of kin name must be at least 2 characters long'
            } else {
                t.form.NOKName = t.form.NOKName.trim()
            }

            // OPTIONAL - No Next of kin relationship
            if (!t.form.NOKRelationship) {
                t.errors.NOKRelationship = false
            } else if (t.form.NOKRelationship.trim().length < 2) {
                t.errors.NOKRelationship = true
                t.errors.NOKRelationshipErrorMessage = 'Next of kin relationship must be at least 2 characters long'
            } else {
                t.form.NOKRelationship = t.form.NOKRelationship.trim()
            }
            
            // OPTIONAL - Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            if ((t.form.NOKTelNo && ['1', '2', '3'].includes(t.form.NOKTelNo.trim()[1])) && (t.form.NOKTelNo.trim().length < 10 || t.form.NOKTelNo.trim().length > 11)) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (t.form.NOKTelNo && ['7'].includes(t.form.NOKTelNo.trim()[1]) && t.form.NOKTelNo.trim().length !== 11) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (t.form.NOKTelNo && ['0', '4', '5', '6', '8', '9'].includes(t.form.NOKTelNo[1])) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (t.form.NOKTelNo && t.form.NOKTelNo.length < 2) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Please enter a correct telephone number'
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.setUserLevel()
                this.saveItem()
            }
        },

        /**
         * Set User Level
         *
         * Configure the user's user level from their user type and user role.
         */
        setUserLevel() {
            const t = this

            t.form.userLevel = t.form.userType[0] + t.form.userRole[0]
        },

        async openItem() {
            const t = this

            const openItem = await t.GET_openItem

            switch (openItem.process) {
                case "New":
                    t.formMode = "New"
                    t.formReadOnly = false
                    t.formBackground = "white"
                    break
                case "View":
                    const item1 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item1.data
                    t.formMode = "View"
                    t.formReadOnly = true
                    t.formBackground = "grey lighten-3"
                    break
                case "Edit":
                    const item2 = await t.MIX_readDocumentById(t.collection, openItem.data.id)
                    t.form = item2.data
                    t.formMode = "Edit"
                    t.formReadOnly = false
                    t.formBackground = "white"
                    break
            }
        },

        async undeleteItem(id) {
            const t = this

            const undeleteResult = await t.MIX_undeleteDocument(t.collection, id)

            if (undeleteResult.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} Restored`, undeleteResult.data, null)
            } else {
                t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error)
                console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
            }

            await t.refreshItemView()
        },

        deleteItem(id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox({
                show: true,
                collection: t.collection,
                id: id,
                title: `Delete Staff Member`,
                description: `Are you sure you want to delete this staff member: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + "Name"]}</strong>?</div>`,
                width,
                trueButtonText: "Yes",
                trueButtonValue: "YES",
                falseButtonText: "No",
                falseButtonValue: "NO",
            })
        },

        removeUploadedDocument() {
            this.$store.commit("upload_file_store/setFile", null)
        },

        editItem() {
            const t = this

            t.formMode = "Edit"
            t.formReadOnly = false
            t.formBackground = "white"
        },

        cancelItem() {
            const t = this

            t.formMode = "View"
            t.formReadOnly = true
            t.formBackground = "grey lighten-3"
            t.tempImage = ''
            t.removeUploadedDocument()
            t.refreshItemView()
        },

        async checkUserInLocalStorage(email) {
            const t = this

            return new Promise(function (resolve, reject) {
                t.$firebase.db
                    .collection("users")
                    .where("userEmail", "==", email)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.docs.length === 0) {
                            t.userInLocalStorage = false
                            return resolve(t.userInLocalStorage)
                        } else {
                            t.userInLocalStorage = true
                            t.userInLocalStorageId = querySnapshot.docs[0].id
                            return resolve(t.userInLocalStorage)
                        }
                    })
                    .catch(function (error) {
                        return reject(error)
                    })
            })
        },

        async saveItem() {
            const t = this

            // If form is NEW
            if (t.formMode === "New") {

                // Check if user already exists
                const userExists = await this.checkUserInLocalStorage(t.form.userEmail)

                if (userExists !== true) {
                    const createDocumentResult = await t.MIX_createDocument(t.collection, t.form)

                    if (createDocumentResult.code === 1) {
                        t.MIX_alert(1, `User successfully created`, null, null)
                        t.formMode = "View"
                        t.formReadOnly = true
                        t.formBackground = "grey lighten-3"

                        // Add uploaded profile image
                        await t.uploadProfileImage()

                        await t.refreshItemView(createDocumentResult.data.id)

                        t.removeUploadedDocument()

                    } else {
                        t.MIX_alert(-1, `Error creating user`, null, null)
                    }
                } else {
                    t.MIX_alert(-1, `This user already exists`, null, null)
                }
            }

            // If form is EDIT
            else if (t.formMode === "Edit") {
                const updateDocumentResult = await this.MIX_updateDocument(t.collection, t.form)
                if (updateDocumentResult.code === 1) {
                    t.MIX_alert(1, `User successfully updated`, updateDocumentResult.data, null)
                    t.formMode = "View"
                    t.formReadOnly = true
                    t.formBackground = "grey lighten-3"

                    // Add uploaded profile image
                    await t.uploadProfileImage()

                    await t.refreshItemView()

                } else {
                    t.MIX_alert(-1, "Error updating user", null, updateDocumentResult.error)
                }
            }
        },

        async refreshItemView(id) {
            const t = this

            // If no ID has been passed through, use the form ID
            if (!id) {
                id = t.form.id
            }

            const itemResult = await t.MIX_readDocumentById(t.collection, id)
            const payload = {}
            payload.process = "View"
            payload.popup = false
            payload.data = itemResult.data
            t.ACT_openItem(payload)
            await t.openItem()
        },

        async updateItemStatus(status) {
            const t = this

            const updateResult = await t.MIX_updateDocumentFieldsById(t.collection, t.form.id, {[t.collectionItem + "Status"]: status})

            if (updateResult.code === 1) {
                t.MIX_alert(1, "User status successfully updated", updateResult.data, null);
                await t.refreshItemView();
            } else {
                t.MIX_alert(-1, "Error updating user status", null, updateResult.error);
            }
        },

        swappUser(direction) {
            this.getAvailableSites()
            this.modifySwappStatus = true
            this.swappDirection = direction
            this.form.swappStatus = direction
            this.formReadOnly = false
        },

        selectSiteLocation() {
            const t = this

            if (t.selectedSite != null) {
                t.form.lastSite.siteId = t.selectedSite.id
                t.form.lastSite.siteName = t.selectedSite.siteName
            }
            t.getAvailableLocations()
            t.allowLocationSelect = true
        },

        selectLocation() {
            const t = this

            if (t.selectedLocation != null) {
                t.form.lastLocation.locationId = t.selectedLocation.id
                t.form.lastLocation.locationName = t.selectedLocation.locationName
            }
        },

        async getAvailableSites() {
            const t = this

            const sitesResult = await t.MIX_readDocuments("sites")
            if (sitesResult.code === 1) {
                t.availableSites = sitesResult.data
            } else {
                t.availableSites = []
            }
        },

        async updateSwappStatus() {
            const t = this

            t.form.swappMethod = "Admin"

            const updateDocumentResult = await t.MIX_updateDocument("users", t.form)

            if (updateDocumentResult.code === 1) {
                t.modifySwappStatus = false
                t.MIX_alert(1, "Swapp Status Updated", updateDocumentResult.data, null)
                const user = await t.MIX_readDocumentById("users", t.form.id)
                t.ACT_openStaffDirectory(user.data)
            } else {
                t.MIX_alert(-1, "ERROR - Could not update Status", null, updateDocumentResult.error)
            }
        },

        cancelSwappStatusUpdate() {
            const t = this

            if (t.swappDirection === 0) {
                t.swappDirection = 1
                t.form.swappStatus = 1
            } else {
                t.swappDirection = 0
                t.form.swappStatus = 0
            }
            t.modifySwappStatus = false
        },

        async getAvailableLocations() {
            const t = this

            const locationsResult = await t.MIX_readDocumentsByKeyValue("locations", "site.id", t.selectedSite.id)
            if (locationsResult.code === 1) {
                t.availableLocations = locationsResult.data
            } else {
                t.availableLocations = []
            }
        },

        getLocationsHistory() {
            const t = this

            t.$firebase.db
                .collection("swappLogs")
                .where("swappedUser", "==", t.form.id)
                .onSnapshot(function (querySnapshot) {
                    t.locationsHistory = []
                    querySnapshot.forEach(function (doc) {
                        var document = doc.data()
                        document.id = doc.id
                        if (!document.hasOwnProperty("delete")) {
                            t.locationsHistory.push(document)
                        } else {
                            // Do not Show Notice
                        }
                    })
                })
        },

        /**
         * Upload Profile Image
         *
         * Upon successful saving/updating of a User document, also save the profile image path.
         *
         * @returns {Promise<void>}
         */
        async uploadProfileImage() {
            const t = this

            if (t.photoUploadResult !== {}) {

                // Save to the document with: collection | user id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

                // Render confirmation box
                if (updatePhotosResult.code === 1) {
                    this.MIX_alert(1, 'User successfully updated', updatePhotosResult.data, null)
                } else {
                    this.MIX_alert(-1, 'Error updating user', null, updatePhotosResult.error)
                }
            }
        },

    },

    watch: {

        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === t.collection) {
                    if (t.GET_confirmBoxResult.data.result === "YES") {
                        var deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id)
                        if (deleteResult.code === 1) {
                            t.MIX_alert(1, `${t.collectionItemTitle} and Training Deleted`, deleteResult.data, null)
                            t.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ""})
                        } else {
                            t.MIX_alert(-1, `Error Deleting ${t.collectionItemTitle}`, null, deleteResult.error)
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === "training") {
                    if (t.GET_confirmBoxResult.data.result === "YES") {
                        const updateResult = await t.MIX_updateDocumentFieldsById("users", t.form.id, {trainingCurrent: t.form.trainingCurrent})
                        if (updateResult.code === 1) {
                            t.MIX_alert(1, `Training deleted`, updateResult.data, null)
                            t.refreshItemView()
                        } else {
                            t.MIX_alert(-1, "Error deleteting training", null, updateResult.error)
                        }
                    }
                } else {
                    // Do nothing
                }
            },
            deep: true,
        },

                /**
         * Date Picker Result
         *
         * Watch for the result of the date picker and assign the required values.
         */
        GET_datePickerResult: {
            handler() {
                const t = this

                switch (t.GET_datePickerResult.field) {

                    case "userDOB":
                        t.formattedUserDOB = t.MIX_formatDateTime(
                            t.GET_datePickerResult.value, "x", "DD-MMM-YYYY")
                        t.form.userDOB = t.GET_datePickerResult.value
                        break;

                    case "userStartDate":
                        t.formattedStartDate = t.MIX_formatDateTime(
                            t.GET_datePickerResult.value, "x", "DD-MMM-YYYY");
                        t.form.userStartDate = t.GET_datePickerResult.value
                        break;

                    default:
                        console.log('Staff date not recognised')

                }
            },
            deep: true,
        },

        /**
         * Photo Upload Result
         *
         * On upload of a profile picture, add its storage path to the photoUploadResult variable.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                t.photoUploadResult = t.GET_photoUploadResult
                t.tempImage = t.photoUploadResult.fileURL

            }, deep: true
        },
    },

    async mounted() {
        const t = this

        t.removeUploadedDocument()

        const openItem = await t.GET_openItem
        t.formMode = openItem.process

        if (openItem.process === "New") {
            t.formReadOnly = false
            t.formBackground = "white"
        } else {
            t.form = openItem.data
            t.formReadOnly = true
            t.formBackground = "grey lighten-3"
        }
        if (openItem.popup === false) {

        }

        t.getLocationsHistory()

        // Get collections data
        await t.getSitesCollectionData()
    },
};
</script>

<style scoped>
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.profileImage-container {
    border: 1px solid lightgray !important;
}

.expandtofill {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    /* background: #ffffff !important; */
}
</style>
