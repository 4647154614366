import {mapActions} from 'vuex';

const datePicker_mixins = {

	data: () => ({}),

	methods: {

		...mapActions('datePicker_store', ['ACT_datePicker']),

		MIX_datePicker(show, field) {

			var payload = {};
			payload.show = show;
			payload.field = field;
			this.ACT_datePicker(payload);
		},
	}
}

export default {
	install(Vue, options) {
		Vue.mixin(datePicker_mixins)
	}
};




