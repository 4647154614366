<!-- * RIGHT PANEL -->
<template>
  <v-col class="border-left-grey" :cols="GET_panelLayout.rightPanel" v-if="GET_panelLayout.show">

    <!-- //TODO add close grey bar-->
    <v-card ref="popup" class="appbackground" flat tile>

      <div class="primary appclosebar pa-2 pl-4 d-flex align-center">

        <!--Title-->
        <app-text category="text-large" class="white--text">{{ GET_panelLayout.title }}</app-text>

        <v-spacer/>

        <!--Close button-->
        <v-btn icon class="white--text ma-2" @click.native="closeRightPanel">
          <v-icon class="icons8-multiply" color="white"/>
        </v-btn>

      </div>

      <div>
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in">
          <component :is="GET_panelLayout.dynamicComponent" :key="dynamicComponentKey"></component>
        </transition>
      </div>

    </v-card>

  </v-col>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'; // Map the Getters from the Vuex Store
export default {
  data: () => ({
    dynamicComponentKey: 0
  }),
  computed: {
    ...mapGetters({
      GET_panelLayout: 'GET_panelLayout', // Panel Layout used for Layout Structure
    }),
  },
  watch: {
    GET_panelLayout: {
      handler() {
        this.dynamicComponentKey++;
      }, deep: true
    },
  },
  methods: {
    ...mapActions({
      ACT_panelLayout: 'ACT_panelLayout',
    }),
    // UPDATE BREADCRUMBS
    updateBreadcrumbs() {
      var t = this;
      var breadcrumbs = [];
      var name = this.GET_panelLayout.title + 's';
      var path = this.GET_panelLayout.title.toLowerCase() + 's';
      breadcrumbs.push({name: name, path: path}) // Set the Main Path
      t.MIX_breadcrumbs(breadcrumbs);
    },
    closeRightPanel() {
      ////this.updateBreadcrumbs();
      this.ACT_panelLayout({leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: ''});
    }
  }
}
</script>
