<!--
Unauthorised

Renders a page for user's with accounts that are awaiting approval.
The page is locked down until it is approved.
-->
<template>
    <v-row class="pa-4" no-gutters>

        <!--Page title-->
        <page-title :icon="computedPageTitle.icon" :page-title="computedPageTitle.title"/>

        <v-col cols="12">
            <v-divider/>
        </v-col>

        <!--Welcome | Information-->
        <v-col cols="12" sm="10" md="8" lg="6">
            <v-card class="mt-4 pa-4" flat>

                <!--Welcome-->
                <app-text category="text-default-bold">
                    Welcome {{ computedUserName }}!
                </app-text>

                <!--Information-->
                <app-text category="text-default" class="mt-4">
                    {{ computedStatusMessage }}
                </app-text>

                <!--Instructional text-->
                <app-text category="text-default" class="mt-4">
                    {{ computedInstructionalText }}
                </app-text>

                <!--Action button - Home-->
                <div v-if="userStatus === 'APPROVED'" class="d-flex mt-4">

                    <v-spacer/>

                    <v-btn @click="MIX_go('/')" color="primary" depressed :height="buttonSizeDefault">
                        <app-text category="text-default">Home</app-text>
                    </v-btn>

                </div>

            </v-card>
        </v-col>

    </v-row>
</template>

<script>
export default {
    name: "unauthorised",

    data: () => ({
        userStatus: 'PENDING'
    }),

    computed: {

        /**
         * Computed Page Title
         *
         * Returns an icon and page title based on the user's status.
         *
         * @returns {{icon: string, title: string}} - icon and title for page header
         */
        computedPageTitle() {
            const t = this
            let pageTitle = {icon: '', title: ''}

            if (t.userStatus.toUpperCase() === 'PENDING') {
                pageTitle.icon = 'icons8-private'
                pageTitle.title = 'Unauthorised'
            } else {
                pageTitle.icon = 'icons8-checkmark-yes'
                pageTitle.title = 'Approved'
            }

            return pageTitle

        },

        /**
         * Computed User Name
         *
         * Returns the current user's first name from their stored userName.
         *
         * @returns {name} - the current user's first name.
         */
        computedUserName() {
            const t = this

            return t.GET_currentUser.userName.split(' ')[0]
        },

        /**
         * Computed Status Message
         *
         * Returns an information message based, on the user's status.
         *
         * @returns {string} -
         */
        computedStatusMessage() {
            const t = this
            let message = ''

            if (t.userStatus.toUpperCase() === 'PENDING') {
                message = 'Your account is temporarily restricted while awaiting approval.'
            } else {
                message = 'Your account has now been approved.'
            }

            return message
        },

        /**
         * Computed Instructional Text
         *
         * Returns an information message, based on the user's status.
         *
         * @returns {string} -
         */
        computedInstructionalText() {
            const t = this
            let message = ''

            if (t.userStatus.toUpperCase() === 'PENDING') {
                message = 'You will be notified when it is ready.'
            } else {
                message = 'Please click the Home button to get started. This may take a minute, so try refreshing the page and trying again if it doesn\'t work.'
            }

            return message
        }

    },

    mounted() {
        const t = this

        t.$firebase.db
            .collection('users')
            .doc(t.GET_currentUser.id)
            .onSnapshot(doc => {
                t.userStatus = doc.data().userStatus
            });

    }
}
</script>
