import { mapActions } from 'vuex';
const photoViewer_mixins = {       
    methods: {
        ...mapActions('photoViewer_store', ['ACT_photoViewer']),
        MIX_photoViewer (payload){
            this.ACT_photoViewer(payload);
        },
    }
}
export default {
    install (Vue, options) {
        Vue.mixin(photoViewer_mixins)
    }
};  
  