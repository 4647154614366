<!--
Concern

Renders the form (right side panel) for Concerns which allows the user to add/edit/delete a concern.
This are split into tabs for: Overview |  Photos | History.

TODO - the base for this was copied over fromStaff Directory, so there may be some unrelated logic to remove.
-->
<template>
	<div>

		<!--
		Header
		-->
		<v-row class="grey lighten-2 ma-0 pa-0 pl-2 d-flex align-center">

			<!--Form title and Status-->
			<div class="d-flex align-center ml-2" style="height: 48px">
				<v-icon v-if="form.concernStatus === 'RESOLVED'" color="success" class="icons8-checkmark-yes"/>
				<v-icon v-if="form.concernStatus === 'ONHOLD'" color="warning" class="icons8-private"/>
				<v-icon v-if="form.concernStatus === 'PENDING'" color="accent" class="icons8-circle"/>
				<v-icon v-if="form.concernStatus === 'DRAFT'" color="grey" class="icons8-inactive-state"/>

				<app-text v-if="form[collectionItem + 'Name'] === ''" category="text-default" class="pl-2">
					{{ $t(`concerns.form.title.new`) }}
				</app-text>
				<app-text v-else category="text-default" class="pl-2 d-inline-block text-truncate">
					{{ form[collectionItem + 'Name'] }}
				</app-text>
			</div>

		</v-row>

		<!--
		Page Content
		-->
		<v-container fluid class="pa-0">
			<v-row no-gutters>

				<!--
				Tabs
				 - Overview | Photos | History
				-->
				<v-tabs class="border-bottom-grey" v-model="tabs" :height="buttonSizeDefault">
					<v-tabs-slider color="primary" background-color="black"></v-tabs-slider>

					<!--Overview-->
					<v-tab href="#overview">
						<app-text category="text-small">{{ $t(`concerns.form.tabs.overview.tabName`) }}</app-text>
					</v-tab>

					<!--Photos-->
					<v-tab v-if="formMode != 'New'" href="#photos">
						<app-text category="text-small">{{ $t(`concerns.form.tabs.photos.tabName`) }}
							({{ calculateArrayLength(files) }})
						</app-text>
					</v-tab>

					<!--History-->
					<!--<v-tab v-if="formMode != 'New'" href="#history" class="white">-->
					<!--	<app-text category="text-small">{{ $t(`concerns.form.tabs.history.tabName`) }}-->
					<!--		({{ calculateArrayLength(commentsTableData) }})-->
					<!--	</app-text>-->
					<!--</v-tab>-->
				</v-tabs>

				<!--
				Tab Content
				 - Overview | Photos | History
				-->
				<v-tabs-items v-model="tabs" class="appbackground">

					<!--
					Overview Tab Content
					-->
					<v-tab-item value="overview" class="pa-0">

						<!--Title | Instructions | Action buttons-->
						<v-row class="pa-4" no-gutters>

							<!--Title | Instructions-->
							<v-col class="pa-0" cols="12">
								<!--Title-->
								<app-text category="text-default-bold">{{ $t('concerns.form.tabs.overview.tabName') }}
								</app-text>

								<!--Instructions-->
								<app-text category="text-default">
									To create a concern, first fill out the form below and save it.<br>
									The form will be saved as a draft until it is submitted, or the status is changed
									manually.<br>
									To add photos, first create and save a concern, and then add them in the photos tab.
								</app-text>
								<app-text category="text-default-bold"
										  v-if="GET_help && GET_currentUser.userType === 'CONTRACTOR'"
										  v-html="$t(`${langPath}.overview.text.contractor`)"></app-text>
								<app-text category="text-default-bold"
										  v-if="GET_help && GET_currentUser.userType !== 'CONTRACTOR'"
										  v-html="$t(`${langPath}.overview.text.default`)"></app-text>

								<v-divider class="my-4"/>
							</v-col>

							<!--Action Buttons-->
							<v-col class="d-flex">

								<!--Destroy-->
								<appbutton :label="$t(`buttons.destroy`)"
										   v-if="formMode !== 'New' && form.delete !== undefined && ['DA'].includes(GET_currentUser.userLevel)"
										   icon="icons8-fire" type="purple" class="mr-2"
										   @click.native="destroyItem(form.id)" :na="formMode !== 'View'"
										   :class="{ 'disabled': formMode !== 'View' }" showlabel="xlOnly"/>

								<!--Undelete-->
								<appbutton :label="$t(`buttons.undelete`)"
										   v-if="formMode !== 'New' && form.delete !== undefined && ['DA'].includes(GET_currentUser.userLevel)"
										   icon="icons8-process" type="purple" class="mr-2"
										   @click.native="undeleteItem(form.id)" :na="formMode !== 'View'"
										   :class="{ 'disabled': formMode !== 'View' }" showlabel="xlOnly"/>

								<!--Delete-->
								<appbutton :label="$t(`buttons.delete`)"
										   v-if="['DA'].includes(GET_currentUser.userLevel) && formMode !== 'New' && form.delete === undefined"
										   icon="icons8-trash"
										   type="error" class="mr-1" @click.native="deleteItem(form.id)"
										   :na="formMode !== 'View'" :class="{ 'disabled': formMode !== 'View' }"
										   showlabel="xlOnly"/>

								<!--Edit-->
								<appbutton :label="$t(`buttons.edit`)" v-if="formMode !== 'New'"
										   icon="icons8-edit-property" type="warning" class="mr-2"
										   @click.native="editItem()" :na="formMode !== 'View'"
										   :class="{ 'disabled': formMode !== 'View' }" showlabel="xlOnly"/>

								<v-spacer/>

								<!--Cancel-->
								<appbutton :label="$t(`buttons.cancel`)" v-if="formMode === 'Edit'" icon="icons8-cancel"
										   type="grey darken-2" class="mr-2" @click.native="cancelItem()"
										   showlabel="xlOnly"/>

								<!--Save-->
								<appbutton :label="$t(`buttons.save`)" v-if="formMode === 'Edit' || formMode === 'New'"
										   icon="icons8-save" type="success" class="mr-n2" @click.native="validateForm()"
										   showlabel="xlOnly"/>

								<!--Submit-->
								<appbutton :label="$t(`buttons.submit`)"
										   v-if="form.concernStatus === 'DRAFT' && formMode !== 'Edit' && formMode !== 'New'"
										   icon="icons8-email-send" type="info" class="mr-0"
										   @click.native="updateItemStatus('PENDING')" showlabel="xlOnly"/>

							</v-col>

						</v-row>

						<!--
						Form
						-->
						<v-form ref="form">
							<v-row class="ma-0 px-2" no-gutters>

								<!--Name-->
								<v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="8">
									<v-text-field color="black"
												  :label="$t(`fields.name`)"
												  v-model="form.concernName"
												  :rules="rules.concernName"
												  required
												  filled
												  hide-details="auto"
												  :background-color="formBackground"
												  dense
												  :clearable="!formReadOnly"
												  :readonly="formReadOnly"
												  :class="{ 'disabled': formReadOnly}"></v-text-field>
								</v-col>

								<!--Types-->
								<v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
									<v-select color="black" :label="$t(`fields.type`)" v-model="form.concernType"
											  :items="concernTypes" :rules="rules.concernType" required filled
											  hide-details="auto" :background-color="formBackground" dense
											  :clearable="!formReadOnly" :readonly="formReadOnly"
											  :class="{ 'disabled': formReadOnly}">
										<template v-slot:item="data">
											{{ data.item.text }}
										</template>
										<template v-slot:selection="data">
											{{ data.item.text }}
										</template>
									</v-select>
								</v-col>

								<!--Description-->
								<v-col class="pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
									<v-textarea color="black" :label="$t(`fields.description`)"
												v-model="form.concernDescription" :rules="rules.concernDescription"
												required filled hide-details="auto" :background-color="formBackground"
												auto-grow rows="1" dense :clearable="!formReadOnly"
												:readonly="formReadOnly"
												:class="{ 'disabled': formReadOnly}"></v-textarea>
								</v-col>

								<!--Site-->
								<v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6">
									<v-autocomplete color="black" :label="$t(`fields.site`)" v-model="form.site"
													:rules="rules.site" required filled hide-details="auto"
													:background-color="formBackground" dense auto-select-first
													:items="sites" item-text="siteName" return-object
													:clearable="!formReadOnly" :readonly="formReadOnly"
													:class="{ 'disabled': formReadOnly}">
									</v-autocomplete>
								</v-col>

								<!--Severity-->
								<v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6">
									<v-select color="black" :label="$t(`fields.severity`)"
											  v-model="form.concernSeverity" :rules="rules.concernSeverity" required
											  :items="concernSeverities" filled hide-details="auto"
											  :background-color="formBackground" dense :clearable="!formReadOnly"
											  :readonly="formReadOnly" :class="{ 'disabled': formReadOnly}">
										<template v-slot:item="data">
											{{ data.item.text }}
										</template>
										<template v-slot:selection="data">
											{{ data.item.text }}
										</template>
									</v-select>
								</v-col>

								<!--Status-->
								<v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
									<v-select color="black" :label="$t(`fields.status`)" v-model="form.concernStatus"
											  :rules="rules.concernStatus" required :items="concernStatus" filled
											  hide-details="auto" :background-color="formBackground" dense
											  :clearable="!formReadOnly" :readonly="formReadOnly"
											  :class="{ 'disabled': formReadOnly}">
										<template v-slot:item="data">
											{{ data.item.text }}
										</template>
										<template v-slot:selection="data">
											{{ data.item.text }}
										</template>
									</v-select>
								</v-col>

								<!--Response-->
								<v-col class="pa-1 mt-4" cols="12">

									<div class="d-flex align-end">
										<!--Title-->
										<app-text category="text-medium" class="darkgrey--text mr-4">Response</app-text>

										<app-text category="text-default" class="grey--text">
											(Admin use only)
										</app-text>
									</div>

									<v-divider class="my-2"/>

									<v-textarea
										:background-color="formBackground"
										:counter="!formReadOnly"
										filled
										placeholder="Enter your response to this concern."
										:readonly="formReadOnly"
										rows="4"
										:rules="[v => v.length <= 500 || 'Max 500 characters']"
										v-model="form.response"
										:disabled="GET_currentUser.userLevel !== 'SA'"/>

									<app-text v-if="form.response" category="text-small" class="grey--text">
										Response last updated:
										<span class="font-weight-bold">
										{{ MIX_formatDateTime(form.modifiedDateTime, "x", "DD MMM YYYY @ HH:MM") }}
											</span>
									</app-text>

								</v-col>

							</v-row>
						</v-form>

					</v-tab-item>

					<!--
					Photos
					-->
					<v-tab-item value="photos" class="pa-0">
						<v-row class="pa-4" no-gutters>

							<!--Description-->
							<v-col class="pa-0" cols="12">

								<!--Title-->
								<app-text category="text-default-bold">{{ $t('concerns.form.tabs.photos.tabName') }}
									<v-icon class="ml-2" @click="MIX_help(GET_help)">icons8-help</v-icon>
								</app-text>

								<!--Description-->
								<app-text category="text-default" v-if="GET_help">{{ $t(`${langPath}.photos.text`) }}
								</app-text>

								<v-divider class="my-4"/>

							</v-col>

							<v-col class="d-flex align-center" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

								<!--Photo upload-->
								<photoupload :allowedtypes="allowedTypes" :docLink="docLink" :folder="folder"/>

								<!--<v-spacer/>-->

								<!--Deleted-->
								<div v-if="['DA'].includes(GET_currentUser.userLevel)" class="d-flex align-center">
									<v-icon color="primary" class="icons8-trash ml-4 text-h4"/>
									<v-switch @change="getFiles(GET_openItem)" v-model="showDeletedFiles"
											  class="ml-4 purple--text" color="accent" inset></v-switch>
								</div>

							</v-col>
						</v-row>

						<!--Photo grid-->
						<v-row class="ma-0">
							<!-- // TODO REMOVED v-if="calculateLength(files) !== 0" -->
							<transition-group name="fade" class="row ma-0">
								<v-col v-for="(file, index) in files" :key="file.id" class="d-flex child-flex pa-4"
									   cols="6" xs="6" sm="6" md="3">
									<v-img @click="openPhoto(index)" :src="file.fileURL" aspect-ratio="1"
										   class="d-flex align-end" style="border-radius: 4px !important;">

										<!--Photo action buttons-->
										<div class="pa-2">

											<!--Delete button-->
											<v-menu offset-x flat v-if="!file.hasOwnProperty('delete')">
												<template v-slot:activator="{ on, attrs }">
													<v-btn small v-bind="attrs" v-on="on">
														<v-icon color="error" class="icons8-trash"/>
													</v-btn>
												</template>
												<v-btn @click="deletePhoto(file.id)" small class="error ml-2">CONFIRM
												</v-btn>
											</v-menu>

											<!--Process button-->
											<v-menu offset-x flat v-if="file.hasOwnProperty('delete')">
												<template v-slot:activator="{ on, attrs }">
													<v-btn small v-bind="attrs" v-on="on">
														<v-icon color="primary" class="icons8-process"/>
													</v-btn>
												</template>
												<v-btn @click="undeletePhoto(file.id)" small class="ml-2">CONFIRM
												</v-btn>
											</v-menu>
										</div>

										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular indeterminate
																	 color="red lighten-5"></v-progress-circular>
											</v-row>
										</template>

									</v-img>
								</v-col>
							</transition-group>
						</v-row>

					</v-tab-item>

					<!--
					History/Comments Tab Content
					-->
					<v-tab-item value="history" class="pa-0">
						<!--Overview-->
						<v-row class="pa-4" no-gutters>

							<!--Description-->
							<v-col class="pa-0" cols="12">
								<!--Title-->
								<app-text category="tab-title">{{ $t(`concerns.form.tabs.history.tabName`) }}
									<v-icon class="ml-2" @click="MIX_help(GET_help)">icons8-help</v-icon>
								</app-text>
								<app-text category="tab-text" v-if="GET_help">{{ $t(`${langPath}.history.text`) }}
								</app-text>

								<v-divider class="my-4"/>
							</v-col>

							<!--Action Buttons-->
							<v-col cols="12" class="d-flex align-center">
								<appbutton :label="$t('buttons.add')" icon="icons8-edit-property" type="primary"
										   @click.native="MIX_comment({ show: true, action: 'New', data: { comment: '', private: true, docLink: { collection: 'concerns', documentId: form.id } } })"/>

								<v-spacer/>

								<div v-if="['DA'].includes(GET_currentUser.userLevel)" class="d-flex align-center">
									<!--Show 'ID's' Switch-->
									<app-text category="text-default-bold" class="grey--text">
										{{ $t('concerns.form.tabs.history.interface.idSwitchLabel') }}
									</app-text>
									<v-switch v-model="showIDComments" class="primary--text ml-4" inset></v-switch>

									<!--Show Deleted Comments Switch-->
									<v-icon color="primary" class="icons8-trash text-h4 ml-4"/>
									<v-switch @change="getComments(GET_openItem)" v-model="showDeletedComments"
											  class="accent--text ml-4" inset></v-switch>
								</div>
							</v-col>
						</v-row>

						<!--
						Table
						-->
						<v-row class="ma-0">
							<v-col class="mt-4 px-4" cols="12">
								<v-data-table
									id="commentstable"
									:headers="computedCommentsHeaders"
									:items="computedCommentsTableData"
									:items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
									item-key="id"
									class="elevation-0"
									dense
									hide-default-header>

									<!--Status-->
									<template v-slot:[`item.concernStatus`]="{ item }">
										<v-icon v-if="item.concernStatus === 'RESOLVED'"
												:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
												class="iconbackground display-1 success--text icons8-checkmark-yes"></v-icon>
										<v-icon v-if="item.concernStatus === 'ONHOLD'"
												:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
												class="iconbackground display-1 warning--text icons8-private"></v-icon>
										<v-icon v-if="item.concernStatus === 'PENDING'"
												:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
												class="iconbackground display-1 grey--text icons8-circle"></v-icon>
									</template>

									<!--Headers-->
									<template v-slot:header="{ props }">
										<th :align="head.align" class="pa-4 font-weight-medium"
											style="font-size:12px !important;" v-for="(head, index) in props.headers"
											:key="index">
											<app-text category="text-small" class="grey--text">
												{{ $t(`fields.${head.text}`) }}
											</app-text>
										</th>
									</template>

									<!--Private-->
									<template v-slot:[`item.private`]="{ item }">
										<v-icon v-if="item.private === true">icons8-lock</v-icon>
										<v-icon v-if="item.private === false"></v-icon>
									</template>

									<!--Comment-->
									<template v-slot:[`item.comment`]="{ item }">
										<span
											v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
											class="purple--text"><v-icon class="purple--text">icons8-trash</v-icon>aaa {{ item.comment
											}}</span>
										<span v-else>{{ item.comment }}</span>
									</template>

									<!--DocLink-->
									<template v-slot:[`item.docLink`]="{ item }">
										{{ item.docLink.documentId }}
									</template>

									<!--Created User Data-->
									<template v-slot:[`item.createdUserData`]="{ item }">
										<div
											v-if="item.createdUserData !== '' && item.createdUserData !== undefined && item.createdUserData !== null"
											@click="MIX_popupUser(item.createdUserData.userId)"
											class="pointer noselect fixedcolumn">
											<v-icon>icons8-identification-documents</v-icon>
											{{ item.createdUserData.userName }}<br/>
											<span class="caption">{{ item.createdDateTime }}</span>
										</div>
									</template>

									<!--Modified User Data-->
									<template v-slot:[`item.modifiedUserData`]="{ item }">
										<div
											v-if="item.modifiedUserData !== '' && item.modifiedUserData !== undefined && item.modifiedUserData !== null"
											@click="MIX_popupUser(item.modifiedUserData.userId)"
											class="pointer noselect fixedcolumn">
											<v-icon>icons8-identification-documents</v-icon>
											{{ item.modifiedUserData.userName }}
											<span class="caption">{{ item.modifiedDateTime }}</span>
										</div>
									</template>

									<!--Delete User Data-->
									<template v-slot:[`item.deleteUserData`]="{ item }">
										<div
											v-if="item.deleteUserData !== '' && item.deleteUserData !== undefined && item.deleteUserData !== null"
											@click="MIX_popupUser(item.deleteUserData.userId)"
											class="pointer noselect fixedcolumn">
											<v-icon>icons8-identification-documents</v-icon>
											{{ item.deleteUserData.userName }}
											<span class="caption">{{ item.delete }}</span>
										</div>
									</template>

									<!--Actions-->
									<template v-slot:[`item.actions`]="{ item }">
										<div class="actionscolumn">
											<v-icon
												v-if="item.delete === null || item.delete === '' || item.delete === undefined"
												@click="MIX_comment({ show: true, action: 'Edit', data: { id: item.id, comment: item.comment, private: item.private } })">
												icons8-edit-property
											</v-icon>
											<v-icon
												v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
												@click="MIX_undelete('comments', 'Comment', item.id)">icons8-process
											</v-icon>
											<v-icon
												v-if="item.delete === null || item.delete === '' || item.delete === undefined"
												@click="deleteComment(item)">icons8-trash
											</v-icon>
										</div>
									</template>
								</v-data-table>
							</v-col>
						</v-row>

					</v-tab-item>

				</v-tabs-items>

			</v-row>
		</v-container>

	</div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'; // Map the Getters from the Vuex Store

export default {
	name: "Concerns",

	data: () => ({
		// * PAGE DETAILS
		collection: 'concerns',
		collectionTitle: 'Concerns',
		collectionItem: 'concern',
		collectionItemTitle: 'Concern',
		tabs: 'concern',
		// * FORM RELATED
		form: {
			id: '', // all users manual entry // TODO NOT SURE IF THIS IS REQUIRED
			concernName: '', // name of concern
			concernDescription: '', // description of concern
			concernType: '',
			concernSeverity: '', // severity of the concern from pre-defined lookup table
			// automatically set to pending by default
			concernStatus: 'DRAFT', // status of the concern, should be set
			// staff can specify a concern is about a contractor / user
			contractorId: '', // contractor Id should be set if concern is about a contractor or set by a contractor
			userId: '', // userId if the concern is being reported about a user
			// visitors / contractors / staff can specify a concern / site but not mandatory
			site: {
				id: '',
				siteName: '',
				siteType: '',
				sitePostcode: ''
			},
			reportedBy: '', // user id of person reporting concern
			reportedTo: '', // user id of the person responsible for the concern
			response: '',
		},
		rules: {},
		formMode: 'New', // Show Form in New Mode / Blank
		formReadOnly: false, // Make Form Editable
		formBackground: 'grey lighten-3',
		concernStatus: [],
		concernTypes: [], // Populated by Lookup Values
		concernSeverities: [], // Populated by Lookup Values
		sites: [], // Populated by GET_sites
		//upload file
		types: "image/*",
		// * COMMENTS RELATED
		commentsTableData: [],  // populated from firestore on show comments
		commentsHeaders: [
			{text: 'private', value: 'private', align: 'center', sortable: false, hidden: false, hide: false},
			{text: 'id', value: 'id', align: 'start', sortable: false, hidden: true, hide: false},
			{text: 'comment', value: 'comment', align: 'start', sortable: false, hidden: false, hide: false,},
			{text: 'docLink', value: 'docLink', align: 'start', sortable: false, hidden: true, hide: false},
			{
				text: 'createdUserData',
				value: 'createdUserData',
				align: 'start',
				sortable: false,
				hidden: false,
				hide: true
			},
			{text: 'created', value: 'createdDateTime', align: 'start', sortable: false, hidden: true, hide: true},
			{
				text: 'modifiedUserData',
				value: 'modifiedUserData',
				align: 'start',
				sortable: false,
				hidden: true,
				hide: true
			},
			{text: 'modified', value: 'modifiedDateTime', align: 'start', sortable: false, hidden: true, hide: true},
			{text: 'delete', value: 'delete', align: 'start', sortable: false, hidden: true, hide: false},
			{
				text: 'deleteUserData',
				value: 'deleteUserData',
				align: 'start',
				sortable: false,
				hidden: true,
				hide: false
			},
			{
				text: 'actions',
				value: 'actions',
				align: 'center',
				sortable: false,
				hidden: false,
				hide: false,
				width: '100px'
			},
		],
		showDeletedComments: false, // Show Deleted Comments
		showIDComments: false,
		// DOCUMENT UPLOAD
		files: [],
		allowedTypes: "image/*",
		folder: '/concerns',
		showDeletedFiles: false, // Show Deleted Comments
	}),

	computed: {
		...mapGetters({
			GET_openItem: 'GET_openConcern', // Get Open Concern Data
			GET_panelLayout: "GET_panelLayout",
			GET_openConcern: "GET_openConcern",
			GET_lookupValues: 'GET_lookupValues', // Get Lookup Values
			GET_organisations: 'GET_organisations', // Get Organisations
			GET_sites: 'GET_sites', // Get Sites,
			GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult', // GET PhotoUpload Result
			GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult', // Get Confirm Box Result
			GET_help: 'GET_help'
		}),
		// COMPUTED COMMENTS HEADERS (GENERIC)
		computedCommentsHeaders() {
			var t = this;
			var headers = t.commentsHeaders;
			var newHeaders = []
			for (var i = 0; i < headers.length; i++) {
				var header = headers[i]
				if (header.hidden) {
					switch (true) {
						// Add in Cases that need to be manually controlled
						case (header.text === 'id'):
							if (t.showIDComments) {
								newHeaders.push(header)
							} // remove if false
							break;
						case (header.text === 'deleteUserData'):
							if (t.showDeletedComments) {
								newHeaders.push(header)
							} // remove if false
							break;
						default:
						// do nothing

					}
				} else {
					newHeaders.push(header)
				}
			}
			return newHeaders
		},
		// COMPUTED COMMENTS TABLE DATA (GENERIC)
		computedCommentsTableData() {
			var t = this;
			var tableData = t.commentsTableData;
			var tableDataList = tableData.map(e => {
				return {
					private: e.private,
					id: e.id,
					comment: e.comment,
					docLink: e.docLink,
					createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'DD-MMM-YY HH:mm'),
					createdUserData: e.createdUserData,
					modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'DD-MMM-YY HH:mm'),
					modifiedUserData: e.modifiedUserData,
					delete: t.MIX_formatDateTime(e.delete, 'x', 'DD-MMM-YY HH:mm'),
					deleteUserData: e.deleteUserData,
				};
			});
			return (tableDataList);
		},
		// DOC LINK (GENERIC) - REQUIRED FOR DOCUMENT UPLOAD
		docLink() {
			const t = this;

			return ({collection: t.collection, documentId: t.form.id})
		},
	},

	watch: {
		// GET CONFIRMATION RESULT (CUSTOM)
		GET_confirmBoxResult: {
			handler: async function () {
				var t = this;
				if (t.GET_confirmBoxResult.data.collection === 'concerns') {
					if (t.GET_confirmBoxResult.data.result === 'YES') {
						var deleteDocumentResult = await t.MIX_deleteDocument('concerns', t.GET_confirmBoxResult.data.id);
						var deleteLinkedResult = await t.MIX_deleteLinked(['files', 'comments'], t.GET_confirmBoxResult.data.id);
						if ((deleteDocumentResult.code === 1) && (deleteLinkedResult.code === 1)) {
							var comment = {}
							comment.comment = `${t.collectionItemTitle} Deleted`
							comment.docLink = {},
								comment.docLink.collection = 'concerns';
							comment.docLink.documentId = t.GET_confirmBoxResult.data.id;
							comment.private = false;
							await t.MIX_createDocument('comments', comment);
							t.MIX_alert(1, `Incident and Photos Deleted`, deleteDocumentResult.data, null);
							t.refreshItemView();
						} else {
							t.MIX_alert(-1, `Error Deleting Incident / Photos`, null, null);
						}
					}
				} else if (t.GET_confirmBoxResult.data.collection === 'concerns/destroy') {
					if (t.GET_confirmBoxResult.data.result === 'YES') {
						var destroyDocumentResult = await t.MIX_destroyDocument('concerns', t.GET_confirmBoxResult.data.id);
						var destroyLinkedDocs = await t.MIX_destroyLinked(['files', 'comments'], t.GET_confirmBoxResult.data.id)
						if ((destroyDocumentResult.code === 1) && (destroyLinkedDocs.code === 1)) {
							t.MIX_alert(1, `Incident and Photos Destroyed`, destroyDocumentResult.data, null);
							t.MIX_setPanelLayout(12, 0, false, '', '');
						} else {
							t.MIX_alert(-1, `Error Destroying Incident & Photos`, null, null);
						}
					}
					// } else  if (t.GET_confirmBoxResult.data.collection === 'files') {
					//     if (t.GET_confirmBoxResult.data.result === 'YES') {
					//         var deleteFileRecord = await t.MIX_destroyDocument('files', t.GET_confirmBoxResult.data.id);
					//         if (deleteFileRecord.code === 1){
					//             await t.MIX_updateDocumentFieldsById ('documents', t.form.id, { fileData: null } )
					//             t.MIX_alert(1, `File Deleted`, deleteFileRecord.data, null);
					//             setTimeout(function() {
					//                 t.refreshItemView();
					//                 t.MIX_setPanelLayout(6, 6, true, 'Document', 'document');
					//             }, 1000)
					//         } else {
					//             t.MIX_alert(-1, `ERROR Deleting Document & File`, null, deleteFileRecord.error);
					//         }
					//     }
				} else if (t.GET_confirmBoxResult.data.collection === 'comments') {
					if (t.GET_confirmBoxResult.data.result === 'YES') {
						var deleteResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id);
						if (deleteResult.code === 1) {
							t.MIX_alert(1, 'Comment Deleted', deleteResult.data, null);
						} else {
							t.MIX_alert(-1, 'Error Deleting Comment', null, deleteResult.error);
						}
					}
				} else {
					// Do nothing
				}
			}, deep: true
		},
		GET_photoUploadResult: {
			handler: async function () {
				var photoUploadResult = this.GET_photoUploadResult;
				if (JSON.stringify(photoUploadResult) !== '{}') {
					var index = this.form.files.findIndex(file => file === photoUploadResult.id)
					if (index === -1) {
						this.form.files.push(photoUploadResult.id);
						var updatePhotosResult = await this.MIX_updateDocumentFieldsById('concerns', this.form.id, {files: this.form.files})
						if (updatePhotosResult.code === 1) {
							this.MIX_alert(1, 'Photo Added', updatePhotosResult.data, null);
							this.getFiles();
						} else {
							this.MIX_alert(-1, 'Error Adding Photo', null, updatePhotosResult.error);
						}
					}
				}
			}, deep: true
		}
	},

	methods: {
		...mapActions({ACT_openItem: "ACT_openConcern", ACT_openConcern: "ACT_openConcern"}),
		// UPDATE BREADCRUMBS
		updateBreadcrumbs() {
			var t = this;
			var breadcrumbs = [];
			breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
			if (t.GET_openItem.process === 'New') {
				breadcrumbs.push({name: `New ${t.collectionItemTitle}`, path: `/${t.collection}`}) // Set a Secondary Path
			} else {
				breadcrumbs.push({
					name: `${this.GET_openItem.data[t.collectionItem + 'Name']}`,
					path: `/${t.collection}/${this.GET_openItem.data.id}`
				}) // Set a Secondary Path
			}
			this.MIX_breadcrumbs(breadcrumbs);
		},
		// OPEN ITEM (GENERIC)
		openItem: async function () {
			var t = this;
			var openItem = await t.GET_openItem; // Get Open Item Data
			switch (openItem.process) {
				case 'New':
					t.formMode = 'New'; // Show Form in New Mode
					t.formReadOnly = false; // Make Form Editable
					t.formBackground = 'white'; // Change Background Colour of Form
					break;
				case 'View':
					var item = await t.MIX_readDocumentById(t.collection, openItem.data.id)
					t.form = item.data; // Update the Form with the Open Item details
					t.formMode = 'View'; // Show Form in View Mode
					t.formReadOnly = true; // Make Form Read Only
					t.formBackground = 'grey lighten-3'; // Change Background Colour of Form
					break;
				case 'Edit':
					var item = await t.MIX_readDocumentById(t.collection, openItem.data.id)
					t.form = item.data; // Update the Form with the Open Item details
					t.formMode = 'Edit'; // Show Form in Edit Mode
					t.formReadOnly = false; // Make Form Editable
					t.formBackground = 'white'; // Change Background Colour of Form
					break;
			}
			return;
		},
		// EDIT ITEM (GENERIC)
		editItem() {
			var t = this;
			t.formMode = 'Edit';
			t.formReadOnly = false;
			t.formBackground = 'white';
		},
		// CANCEL ITEM (GENERIC)
		cancelItem() {
			var t = this;
			t.formMode = 'View';
			t.formReadOnly = true;
			t.formBackground = 'grey lighten-3';
			t.refreshItemView();
			t.closeRightPanel();
		},
		// SAVE ITEM (GENERIC)
		saveItem: async function () {
			var t = this;
			// if (t.form.id === undefined) {
			if (!t.form.id) {
				t.form.id = t.MIX_generateId();  // if new then generate new id
			}

			var createDocumentResult = await t.MIX_createDocument(t.collection, t.form); // Create Document Within Firestore based on Form
			if (createDocumentResult.code === 1) {
				t.MIX_alert(1, `${t.collectionItemTitle} Saved`, null, null); // Send Success Alert to Item
				t.formMode = 'View'; // Change Form Mode
				t.formReadOnly = true; // Set Form to Read Only
				t.formBackground = 'grey lighten-3';
				t.refreshItemView();
			} else {
				t.MIX_alert(-1, `Error Saving ${t.collectionItemTitle}`, null, null); // Send Error Alert to Item
			}
		},
		// * VALIDATION RULES
		validateForm() {
			let t = this;
			t.rules = {
				concernName: [
					v => !!v || 'Name is required',
					v => (v && v.length < 255) || 'Name must be less than 255 characters',
				],
				concernDescription: [v => !!v || 'Required'],
				concernType: [v => !!v || "Concern Type is Required"],
				site: [v => !!v || "Site is Required"],
				concernSeverity: [v => !!v || 'Severity is required'],
				concernStatus: [v => !!v || 'Status is required'],
			}
			//let the DOM update
			t.$nextTick(() => {
				//manually trigger Vuetify validation
				if (t.$refs.form.validate()) {
					//t.MIX_alert(1, "Passed validation.", null, null);
					//it’s valid, do work
					// t.MIX_confirmBox (
					// 	{
					// 		show: true,
					// 		collection: 'passes/save',
					// 		id: t.form.id,
					// 		title: `Visitor Pass Declaration`,
					// 		description: `Please confirm that all Visitors listed on this pass are Medically fit to attend site and are not classed as vulnerable?`,
					// 		width: '300',
					// 		height: '300',
					// 		trueButtonText: 'Yes',
					// 		trueButtonValue: 'YES',
					// 		falseButtonText: 'No',
					// 		falseButtonValue: 'NO',
					//   	}
					// )
					this.saveItem();
				} else {
					t.MIX_alert(-1, "Please fill out all the fields", null, null);
				}

				//if not valid, errors will be automatically displayed
			})
		},
		// UPDATE ITEM STATUS
		updateItemStatus: async function (status) {
			const t = this;
			const updateResult = await t.MIX_updateDocumentFieldsById(t.collection, t.form.id, {[t.collectionItem + 'Status']: status})
			if (updateResult.code === 1) {
				t.MIX_alert(1, 'Status Updated', updateResult.data, null);
				t.refreshItemView();
			} else {
				t.MIX_alert(-1, 'ERROR - Could not update Status', null, updateResult.error);
			}
		},
		// REFRESH ITEM VIEW
		refreshItemView: async function () {
			var t = this;
			var itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
			var payload = {}
			payload.process = 'View';
			payload.popup = false;
			payload.data = itemResult.data;
			t.ACT_openItem(payload);
			t.openItem();
		},
		// DELETE ITEM METHOD
		deleteItem: function (id) {
			var t = this;
			t.MIX_confirmBox(
				{
					show: true,
					collection: t.collection,
					id: id,
					title: `Delete ${t.collectionItemTitle}`,
					description: `Are you sure you want to delete ${t.collectionItemTitle}: <br /><strong>${t.form[t.collectionItem + 'Name']}</strong>?`,
					width: '400',
					height: '200',
					trueButtonText: 'Yes',
					trueButtonValue: 'YES',
					falseButtonText: 'No',
					falseButtonValue: 'NO',
				}
			)
		},
		// UNDELETE ITEM METHOD
		undeleteItem: async function (id) {
			var t = this;
			var undeleteResult = await t.MIX_undeleteDocument(t.collection, id)
			if (undeleteResult.code === 1) {
				var unDeleteLinkedResult = await t.MIX_undeleteLinked(['files', 'comments'], t.form.id)
				if (unDeleteLinkedResult.code === 1) {
					var comment = {}
					comment.comment = `${t.collectionItemTitle} Restored`
					comment.docLink = {},
						comment.docLink.collection = 'concerns';
					comment.docLink.documentId = t.form.id;
					comment.private = false;
					await t.MIX_createDocument('comments', comment);
					t.MIX_alert(1, `${t.collectionItemTitle} Restored`, unDeleteLinkedResult.data, null);
					t.refreshItemView();
				} else {
					t.MIX_alert(-1, `Error Restoring File Record`, null, unDeleteLinkedResult.error);
				}
			} else {
				t.MIX_alert(-1, `Error Restoring ${t.collectionItemTitle}`, null, undeleteResult.error);
				console.log(`undeleteResult.error = ${JSON.stringify(undeleteResult.error, null, 2)}`)
			}
			t.refreshItemView();
		},
		// DESTROY ITEM METHOD (CUSTOM)
		destroyItem: async function () {
			var t = this
			t.MIX_confirmBox(
				{
					show: true,
					collection: 'concerns/destroy',
					id: t.form.id,
					title: `Destroy Incident`,
					description: `Are you sure you want to destroy the concern: <br /><strong>${t.form.concernName}</strong>?`,
					width: '300',
					height: '300',
					trueButtonText: 'Yes',
					trueButtonValue: 'YES',
					falseButtonText: 'No',
					falseButtonValue: 'NO',
				}
			)
		},
		// calculateLength: function(arr) {
		//     if ((arr !== []) && (arr !== null) && (arr !== undefined)) {
		//         return arr.length;
		//     } else {
		//         return 0;
		//     }
		// },
		// GET COMMENTS METHOD (GENERIC)
		getComments: async function (openItem) {
			var t = this;
			if (JSON.stringify(openItem.data) !== '{}') {
				t.$firebase.db.collection('comments').where("docLink.documentId", "==", openItem.data.id)
					.onSnapshot(function (querySnapshot) {
						t.commentsTableData = [];
						querySnapshot.forEach(function (doc) {
							var document = doc.data()
							document.id = doc.id;
							if (!document.hasOwnProperty('delete')) {
								t.commentsTableData.push(document);
							} else if (t.showDeletedComments) {
								t.commentsTableData.push(document);
							} else {
								// Do not Show Notice
							}
						});
					});
			}
		},
		// DELETE COMMENT METHOD (GENERIC)
		deleteComment: function (item) {
			this.MIX_confirmBox(
				{
					show: true,
					collection: 'comments',
					id: item.id,
					title: 'Delete Comment',
					description: `Are you sure you want to delete comment: <strong>${item.comment}</strong>?`,
					width: '400',
					height: '200',
					trueButtonText: 'Yes',
					trueButtonValue: 'YES',
					falseButtonText: 'No',
					falseButtonValue: 'NO',
				}
			)
		},
		// Get Photos
		getFiles: async function (openItem) {
			var t = this;
			if (JSON.stringify(openItem.data) !== '{}') {
				t.$firebase.db.collection('files').where("docLink", "==", openItem.data.id)
					.onSnapshot(function (querySnapshot) {
						t.files = [];
						querySnapshot.forEach(function (doc) {
							var document = doc.data()
							document.id = doc.id;
							if (!document.hasOwnProperty('delete')) {
								t.files.push(document);
							} else if (t.showDeletedFiles) {
								t.files.push(document);
							} else {
								// Do not Show Notice
							}
						});
					});
			}
		},
		// Open Photo
		openPhoto(index) {
			var t = this;
			var payload = {}
			payload.title = t.form.concernName;
			payload.index = index;
			payload.collection = 'concerns';
			payload.id = t.form.id;
			payload.photos = t.files;
			var width, height
			switch (true) {
				case (t.$vuetify.breakpoint.name === 'xs'):
					width = '300';
					height = "300";
					break;
				case (t.$vuetify.breakpoint.name === 'sm'):
					width = '300';
					height = "300";
					break;
				default:
					width = '600';
					height = "580";
					break;
			}
			payload.width = width;
			payload.height = height;
			t.MIX_photoViewer(payload);
			t.MIX_puDialog(true, 'photoviewer', width, (parseFloat(height) + 88).toString())

		},
		// DELETE PHOTO (GENERIC)
		deletePhoto: async function (fileId) {
			var t = this;
			var deleteFileResult = await t.MIX_deleteDocument('files', fileId);
			if (deleteFileResult.code === 1) {
				t.MIX_alert(1, 'Photo Deleted', deleteFileResult.data, null); // Send Success Alert to Concern
			} else {
				t.MIX_alert(-1, 'Error Deleting Photo', null, deleteFileResult.error); // Send Error Alert to Concern
			}
		},
		// UNDELETE PHOTO (GENERIC)
		undeletePhoto: async function (fileId) {
			var t = this;
			var undeleteFileResult = await t.MIX_undeleteDocument('files', fileId);
			if (undeleteFileResult.code === 1) {
				t.MIX_alert(1, 'Photo Restored', undeleteFileResult.data, null); // Send Success Alert to Concern
			} else {
				t.MIX_alert(-1, 'Error Restoring Photo', null, undeleteFileResult.error); // Send Error Alert to Concern
			}
		},

		// CALCULATE ARRAY LENGTH
		calculateArrayLength(array) {
			return array.length;
		}
	},

	async mounted() {
		var t = this;
		// * LOOKUP RELATED MOUNTED
		await t.MIX_lookupValues(); // LOAD LOOKUP VALUES
		t.concernSeverities = await t.GET_lookupValues('CONCERNSEVERITIES')
		t.concernStatus = await t.GET_lookupValues('CONCERNSTATUSES');
		t.concernTypes = await t.GET_lookupValues('CONCERNTYPES');
		t.MIX_photoUploadResult({}) // Empty Photo Upload Result on Load
		t.sites = t.GET_sites; // STORE SITES IN LOCAL ARRAY

		// * ITEM RELATED MOUNTED
		var openItem = await t.GET_openItem; // Get Open Concern Data
		t.formMode = openItem.process; // Show Form in View Mode
		if (openItem.process === 'New') {
			t.formReadOnly = false;             // Make Form Editable }
			t.formBackground = 'white';
		} else {
			t.form = openItem.data; // Update the Form with the Open Concern details
			t.formReadOnly = true;
			t.formBackground = 'grey lighten-3';
			t.getFiles(openItem); // GET FILES
			t.getComments(openItem);
		}
		if (openItem.popup === false) {
			t.updateBreadcrumbs()
		} // Update Breadcrumbs in Title

		// t.getFiles(); // GET FILES
		// // * SET PHOTO UPLOAD DEFAULTS
		// if (t.form.id !== '') {
		//     t.MIX_photoUpload({type: 'concerns', typeId: t.form.id, allowedTypes: 'image/*', folder: 'concerntest' })
		// }
		if (openItem.popup === false) {
			t.updateBreadcrumbs()
		} // Update Breadcrumbs in Title

	}
}
</script>

<style scoped>
.disabled >>> .v-input__slot::before {
	border-style: none !important;
}

.v-tabs-items {
	width: 100% !important;
	height: 100% !important;
}

.v-window-item {
	height: calc(100vh - 170px);
	overflow-y: auto;
}

.v-menu__content {
	box-shadow: none !important;
}

.fade-in-enter-active {
	transition: all 0.5s ease;
}

.fade-in-leave-active {
	transition: all 0.5s ease;
}

.fade-in-enter, .fade-in-leave-to {
	position: absolute; /* add for smooth transition between elements */
	opacity: 0;
}

/* v-menu__content theme--light menuable__content__active */
</style>
