<!--
RESET PASSWORD

Renders the reset password form, containing the following:
	- Email (input)
	- Log In (button)
	- Rest Password (button)
-->
<template>
   <v-row class="d-flex justify-center ma-4" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

			<!--
			Title
				Screen title and Reset Password instructions
			-->
            <!--<v-row class="mt-4 px-4 justify-center">-->
            <v-row class="justify-center">
                <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5">

					<!--Title-->
                    <app-text category="text-large" class="primary--text">Reset Password</app-text>

					<!--Instruction-->
                    <app-text category="text-default" class="my-4">Please enter your email address to request a password reset.</app-text>

					<v-divider/>
                </v-col>
            </v-row>

			<!--
			Reset Password Form
				Reset password form with a field for Email, and buttons for Login and Reset Password
			-->
            <v-row class="d-flex justify-center mt-0 pa-0">
                <v-col cols="12" xs="12" sm="6" md="5" lg="5" xl="5">
					<!--Form-->
                    <firebase-auth-reset />
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
export default {
  name: "ResetPassword",
};
</script>
