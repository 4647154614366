import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; //material icons

Vue.use(Vuetify);

const opts = {
	options: {
		customProperties: true
	},
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: '#582468',
				primaryLight: '#9058ae',
				secondary: '#FFFFFF',
				accent: '#9058ae',

				// Dashboard status colors
				away: '#4492DA',
				home: '#29777C',
				holiday: '#F2994A',
				visitor: '#a711b3',
				
				lightgrey: '#E0E0E0',
				notificationsBackground: '#EEEEEE',
				darkgrey: '#4f4f4f',

				appbackground: '#E5E5E5',
				success: '#00942A',
				warning: '#f07800',
				error: '#f01800',

				info: '#4492DA',
				vindicoPurple: '#582468'
			},
		},
	},
	breakpoint: {
		thresholds: {
			xs: 340,
			sm: 600, // 540,
			md: 800, // (775 ???)
			lg: 1501,  // sets Ipad / surface book (1476???)
		},
		scrollBarWidth: 24,
	},
};

export default new Vuetify(opts);
