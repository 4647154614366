<template>
    <v-row class="ma-0 pa-0" no-gutters>

        <!-- File input -->
        <input type="file" ref="fileinput" style="display: none" @change="previewFile"
               :accept="GET_photoUpload.allowedTypes"/>

        <!--Add photo button | Progress bar-->
        <v-col class="d-flex align-center">

            <!--Add button-->
            <v-btn v-if="!isUploading" @click.native="fileClick"
                   class="white--text mr-4"
                   color="primary"
                   :disabled="disabled">
                <v-icon class="icons8-camera mr-2" color="white"/>
                Add Photo
            </v-btn>

            <!--Progress bar-->
            <v-progress-linear v-if="isUploading"
                               v-model="uploadValue" height="36">
                <app-text category="text-default">{{ Math.ceil(uploadValue) }}%</app-text>
            </v-progress-linear>

        </v-col>

    </v-row>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	props: ['disabled'],

	data: () => ({
		fileData: null,
		fileType: '',
		hideUploadButton: false,
	}),

	computed: {
		...mapGetters({
			GET_photoUpload: 'photoUpload_store/GET_photoUpload'
		})
	},

	methods: {
		fileClick() {
			this.$refs.fileinput.click();
		},
		previewFile: async function (event) {
			this.uploadValue = 0;
			this.fileURL = null;
			this.fileData = event.target.files[0]; // assign fileData for preview
		},
	},

	watch: {
		fileData: async function () {
			const t = this;

			const docLink = t.$attrs.docLink.documentId

			const uploadFileResult = await t.MIX_uploadFile(t.GET_photoUpload.folder, t.fileData, docLink, t.GET_photoUpload.type, t.GET_photoUpload.typeId);

			t.MIX_photoUploadResult(uploadFileResult.data);
		},
	}
}
</script>
