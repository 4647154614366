const upload_file_store = {
  namespaced: true,
  state: {
    file: null,
  },
  mutations: {
    setFile(state, data) {
      state.file = data;
    },
  },
  getters: {
    currentFile(state) {
      return state.file;
    },
  },
};

export default upload_file_store;
