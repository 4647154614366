<template>
    <v-banner height="4px" app sticky class="primary pa-0 ma-0 d-flex align-center"  v-if="GET_progressBar.show" style="padding: 0px !important; margin: 0px !important; width: 100% !important; position: fixed !important; z-index: 1000 !important;">
        <v-progress-linear indeterminate color="primary lighten-4" style="padding: 0px !important; margin: 0px !important; width: 100% !important;"></v-progress-linear>
    </v-banner>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            GET_progressBar: 'progressBar_store/GET_progressBar'
        })
    }

    
}
</script>

<style>
    .v-banner__wrapper {
        padding: 0px !important;
        margin: 0px !important;
    }
    .v-banner__content {
        padding: 0px !important;
        margin: 0px !important;

    }
</style>