<!-- The comment component -->
<template>
    <v-dialog v-model="GET_fsDialog.show" fullscreen hide-overlay transition="dialog-bottom-transition" style="z-index: 9999 !important;">
        <v-card ref="popup" class="appbackground" >
            <div class="primary text-right appclosebar py-1 pl-2 pa-0 d-flex align-center" >
                <app-text category="dialog-title" class="ma-2">{{ GET_fsDialog.title }}</app-text>
                <v-spacer></v-spacer>
                <v-btn icon class="white--text pr-2" @click.native="closefsDialog"><v-icon>icons8-multiply</v-icon></v-btn>
            </div>
            <div>
                <component :is="GET_fsDialog.dynamicComponent" :key="dynamicComponentKey"></component>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import app_text from '../../components/base/app_text.vue';
    export default {
        components: { app_text },
        data: () => ({
            dynamicComponent: '',
            dynamicComponentKey: 0
        }),
        computed: {
            ...mapGetters({
                GET_fsDialog: 'fsDialog_store/GET_fsDialog',
            }),
        },
        watch: {
            GET_fsDialog: {
                handler() {
                    console.log('fsDialog Updated so increase component key')
                    this.dynamicComponentKey++
                }, deep: true
            }
        },
        methods: {
            closefsDialog() {
                this.MIX_fsDialog(false, ''); // Hide Comments Dialog
            },
        }
    }
</script>

<style scoped>

</style>
