<!--
Form Field Container with Label

Renders a container with a title passed in as props, and a slot for the form field.
-->
<template>
	<div class="ffcwl-container">

		<!--Label-->
		<app-text category="text-default-bold" class="ffcwl-text">{{ title }}</app-text>

		<!--Form field-->
		<slot></slot>

	</div>
</template>

<script>
export default {
	props: ['title'],
}
</script>

<style>
.ffcwl-container {
	margin-top: 16px;
}

/*Field label*/
.ffcwl-text {
	color: var(--v-primary-base);
	margin-bottom: 8px;
}
</style>
