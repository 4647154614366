<!--
Form Field Container

Renders a container with a slot for the form field.
-->
<template>
	<div class="ffc-container">

		<!--Form field-->
		<div class="ffc-slot">
			<slot></slot>
		</div>

	</div>
</template>

<script>
export default {
	props: ['title'],
}
</script>

<style>
.ffc-container {
	margin-top: 16px;
}

/*Text field*/
.ffc-slot {
	padding: 4px;
}
</style>
