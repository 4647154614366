<template>
	<v-col cols="12" xs="12">
		<!-- start of USER-INPUT -->
		<v-form>
			<v-text-field ref="email" dense filled :background-color="inputBackgroundColor" v-model.trim="email" label="Email" append-icon="icons8-envelope" hide-details="auto" data-vv-name="email" required :rules="[rules.required, rules.email]"></v-text-field>

			<v-row class="my-4">
				<v-col cols="6">
					<v-btn block depressed large @click="MIX_go('/login')" class="mt-3 grey lighten-1">{{ $t("buttons.login") }}</v-btn>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="6">
					<v-btn block depressed @click.native="resetPassword()" large class="mt-3 primary">{{ $t("buttons.resetPassword") }}</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-col>
</template>

<script>
	export default {
		name: "ResetPassword",
		// * DATA
		data: () => ({
			inputBackgroundColor: "white",
			email: "",
			rules: {
				required: (value) => !!value || "This filed is required.",
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail. Pleas try again ";
				},
			},
		}),
		methods: {
			resetPassword: function() {
				let t = this;
				t.email = t.email.toLowerCase();
				if (t.$refs.email.validate()) {
					t.$firebase.auth
						.sendPasswordResetEmail(t.email)
						.then(function() {
							// Email sent.
							t.MIX_alert(1, "A link to reset your password has been sent to your email address. Check your inbox.", null, null);
						})
						.catch(function(error) {
							t.MIX_alert(-1, error, null, null);
						});
				} else {
					t.MIX_alert(-1, "Fill out your email correctly field in order to proceed.", null, null);
				}
			},
		},
	};
</script>
