<!--
IT Support

Renders a form for logging IT issues.
When submitting the form it is added to the 'itSupport' collection which is then picked up by a Firebase Function
and sent as an email.
-->
<template>
    <v-row class="ma-4" no-gutters>

        <!--Title-->
        <page-title icon="icons8-computer-support" pageTitle="IT Support"/>

        <!--Instructional text-->
        <v-col cols="12">
            <app-text category="text-default">
                Use this form to log a request for IT support.
            </app-text>
        </v-col>

        <!--Divider-->
        <v-col cols="12">
            <v-divider class="mt-4"/>
        </v-col>

        <!--IT support form-->
        <v-form style="width: 100%">
            <v-row no-gutters>
                <v-col>

                    <!--Title-->
                    <form-field-container>
                        <v-text-field background-color="grey lighten-3"
                                      :error="errors.supportTitle"
                                      filled
                                      hide-details="auto"
                                      label="Title"
                                      v-model="form.supportTitle"/>
                    </form-field-container>

                    <!--Description-->
                    <form-field-container>
                        <v-textarea auto-grow
                                    background-color="grey lighten-3"
                                    :error="errors.supportDescription"
                                    filled
                                    hide-details="auto"
                                    label="Description"
                                    rows="8"
                                    v-model="form.supportDescription"/>
                    </form-field-container>

                    <!--Site-->
                    <form-field-container>
                        <v-select background-color="grey lighten-3"
                                  :error="errors.supportSite"
                                  filled
                                  hide-details="auto"
                                  :items="sitesCollectionData"
                                  item-text="siteName"
                                  label="Site"
                                  v-model="form.supportSite"/>
                    </form-field-container>

                    <!--Priority-->
                    <form-field-container>
                        <v-select background-color="grey lighten-3"
                                  :error="errors.supportPriority"
                                  filled
                                  hide-details="auto"
                                  :items="priorityOptionsData"
                                  label="Priority"
                                  v-model="form.supportPriority"/>
                    </form-field-container>

                    <!--Action buttons-->
                    <v-col class="d-flex mt-4 pa-0">

                        <v-spacer/>

                        <!--Submit-->
                        <v-btn @click="validate" color="success" depressed :height="buttonSizeDefault">
                            <v-icon class="icons8-checkmark-yes"/>
                            <app-text category="text-default" class="ml-2">Submit</app-text>
                        </v-btn>

                    </v-col>

                </v-col>
            </v-row>
        </v-form>

    </v-row>
</template>

<script>
import FormFieldContainer from "../../components/commonComponents/formFieldContainer";

export default {
    name: "itSupport",

    components: {
        FormFieldContainer,
    },

    data: () => ({
        form: {
            supportTitle: '',
            supportDescription: '',
            supportSite: '',
            supportPriority: '',

            createdDateTime: '',
            createdUserData: {},
            modifiedDateTime: '',
            modifiedUserData: {}
        },
        errors: {
            supportTitle: false,
            supportDescription: false,
            supportSite: false,
            supportPriority: false,
        },
        sitesCollectionData: [],
    }),

    methods: {

        /**
         * Get Sites Collection Data
         *
         * Fetch all the data from the collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        getSitesCollectionData: async function () {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Rest the data to avoid duplication
                t.sitesCollectionData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesCollectionData.push(document)
                    }

                })
            })
        },

        /**
         * Validate
         *
         * Validate fields for presence, if there are no errors, call to save.
         */
        validate() {
            const t = this

            t.form.supportTitle.trim() ? t.errors.supportTitle = false : t.errors.supportTitle = true
            t.form.supportDescription.trim() ? t.errors.supportDescription = false : t.errors.supportDescription = true
            t.form.supportSite ? t.errors.supportSite = false : t.errors.supportSite = true
            t.form.supportPriority ? t.errors.supportPriority = false : t.errors.supportPriority = true

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Questions
         *
         * Save the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        saveDocument: async function () {
            const t = this

            // Create document
            const createDocumentResult = await t.MIX_createDocument('itSupport', t.form)

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok.
         *  -> 'Error' alert if something when wrong.
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `IT support request sent`, null, null)
                t.clearFieldsOnSuccessfulSubmission()
            } else {
                t.MIX_alert(-1, `Error sending IT support request`, null, null)
            }
        },

        /**
         * Clear Fields On Successful Submission
         *
         * If the submission was successful, clear the fields to assert completeness.
         */
        clearFieldsOnSuccessfulSubmission() {
            const t = this

            t.form.supportTitle = ''
            t.form.supportDescription = ''
            t.form.supportSite = ''
            t.form.supportPriority = ''
        }

    },

    async mounted() {
        const t = this

        // Get required collection data
        await t.getSitesCollectionData()
    }
}
</script>
