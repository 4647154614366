<!--
Covid Questions

This is the form for the Covid Questions.
The 'isSwappEnabled' boolean
-->
<template>
    <div v-if="GET_currentUser.swappStatus === 0 && $vuetify.breakpoint.width < 840"
         class="mt-4 pa-0">
        <v-card flat class="d-flex flex-column pa-4">

            <!--Icon | Title-->
            <div class="d-flex">

                <!--Icon-->
                <v-icon class="icons8-coronavirus mr-4" color="warning" size="48"/>

                <!--Title-->
                <app-text category="text-medium-bold" class="primary--text">Covid Screening</app-text>

            </div>

            <!--Instructions-->
            <app-text category="text-default" class="mt-4">
                You must answer the Covid Screening questions before you can SWAPP IN.
            </app-text>

            <!--Button-->
            <v-btn @click="isFormVisible = !isFormVisible"
                   class="mt-4" color="primary"
                   :disabled="hasFormBeenFilledToday"
                   :height="buttonSizeDefault">
                <v-icon class="icons8-ask-question mr-2" color="white"/>
                <app-text category="text-default">Covid Screening</app-text>
            </v-btn>

            <!--
            Questions and Answers
            -->
            <v-form v-if="isFormVisible" class="home-form">

                <div v-for="(question, index) in questionsData">

                    <!--Question-->
                    <app-text category="text-default-bold" class="mt-8 mb-2">
                        {{ question.questionName }}
                    </app-text>

                    <!--Description-->
                    <app-text category="text-default" class="grey--text mb-2">
                        {{ question.questionDescription }}
                    </app-text>

                    <!--Answers-->
                    <v-select background-color="white"
                              @change="saveSelectedAnswer(question, index)"
                              filled
                              hide-details
                              :items="question.questionAnswers"
                              label="Select an answer..."
                              return-object
                              v-model="answer[index]"/>

                </div>

                <!--Submit Button-->
                <div class="d-flex align-end">

                    <!--Error message-->
                    <app-text v-if="isErrorMessageVisible" category="text-default"
                              class="error white--text pa-2 errorMessage">
                        Answer all questions
                    </app-text>

                    <v-spacer/>

                    <!--Submit button-->
                    <v-btn @click="submitForm" class="mt-4" color="success" :height="buttonSizeDefault">
                        Submit
                    </v-btn>

                </div>

            </v-form>

            <!--
            Responses
             - Render a good/bad message depending on the answers given
            -->
            <div>

                <!--Bad result-->
                <app-text v-if="hasBadAnswer" category="text-default" class="mt-4">
                    <v-icon class="icons8-no-entry flash pb-1 mr-4" color="error"/>
                    <span class="text-default-bold">Do not enter this site</span>
                    <br><br>
                    Due to the answers you have provided, you must contact a member of management for
                    further
                    instructions.
                </app-text>

                <!--Good result-->
                <app-text v-if="isSwappEnabled" category="text-default" class="mt-4">
                    <v-icon class="icons8-checkmark-yes pb-1" color="success"/>
                    You have completed the Covid Screening for today.
                </app-text>

            </div>

        </v-card>
    </div>
</template>

<script>
export default {
    name: "covidQuestions",

    data: () => ({

        answer: [], // holds all the answers as we don't know how many questions there will be
        answeredQuestions: [], // hold all the questions and answers to save to Firebase
        answersData: [], // questions and answers data from DB
        hasBadAnswer: false,
        hasFormBeenFilledToday: false,
        isErrorMessageVisible: false,
        isFormVisible: false,
        isSwappEnabled: false,
        questionsData: [],

    }),

    methods: {

        /**
         * Get Answers Collection
         *
         * Fetch all the data from the Answers collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getAnswersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('answers')

            collection.onSnapshot(snapshot => {
                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.answersData.push(document)
                    }
                })
                t.checkIfFormHasBeenFilledInToday()
            })
        },

        /**
         * Check If Form Has Been Filled In Today
         *
         * The form only needs submitting once a day.
         * Check the answersData to see if the current user has completed the form today.
         * If they have, check to ensure the result was good or bad, to know whether to enable Swapp button.
         * If they have NOT, the form should be filled in.
         */
        checkIfFormHasBeenFilledInToday() {
            const t = this

            // Filter all the current user's answers
            const usersAnswers = t.answersData.filter(answer => answer.createdUserData.userId === t.GET_currentUser.id)

            // Filter the current user's answers for today
            const todaysAnswers = usersAnswers.filter(answer =>
                t.$moment(Number(answer.createdDateTime)).format('DD-MM-YYYY')
                ===
                t.$moment().format('DD-MM-YYYY')
            )

            // If the questions have been answered today
            if (todaysAnswers.length) {

                // Filter any bad answers
                const badAnswers = todaysAnswers[0].questions.filter(
                    answer => !answer.question.questionCorrectAnswers.includes(answer.answer))

                // Handle good or bad answers
                if (badAnswers.length) {
                    t.handleFormAndButtonsVisibility('bad')
                } else {
                    t.handleFormAndButtonsVisibility('good')
                }
            }

            // If the questions have not been answered today, answer them
            else {
                t.hasFormBeenFilledToday = false
                t.shouldEnableSwappIn(false)
            }
        },

        /**
         * Handle Form and Buttons Visibility
         *
         * Based on the results from either 'checkIfFormHasBeenFilledInToday()', or 'checkForBadAnswers()'.
         * If any of the answers are not acceptable, disable the form and Swapp button.
         * If all the answers are acceptable, disable the form but enable the Swapp button.
         */
        handleFormAndButtonsVisibility(status) {
            const t = this

            if (status === 'bad') {
                t.isFormVisible = false
                t.hasBadAnswer = true
                t.hasFormBeenFilledToday = true
                t.shouldEnableSwappIn(false)
            } else if (status === 'good') {
                t.isFormVisible = false
                t.hasFormBeenFilledToday = true
                t.shouldEnableSwappIn(true)
            }
        },

        /**
         * Enable/Disable Swapp button based on the status
         *
         * @param status - the collective result of the answers.
         */
        shouldEnableSwappIn(status) {
            const t = this

            t.isSwappEnabled = status

            t.$emit('isSwappEnabled', status)

        },

        /**
         * Get Questions Collection
         *
         * Fetch all the data from the Questions collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getQuestionsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('questions')

            collection.onSnapshot(snapshot => {
                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.questionsData.push(document)
                    }

                })
            })
        },

        /**
         * Save Selected Answer
         *
         * Saves the current question and answer in the answeredQuestions array.
         * The question is saved as an object and the answer is saved as a string.
         * As the Q&As are dynamically rendered the amount of Q&As being asked is unknown, so the answer is bound
         * to the this.answer array, which is why the index is required to grab the correct answer.
         *
         * @param currentQuestion - the current question object
         * @param currentQuestionIndex - the index of the current question
         */
        saveSelectedAnswer(currentQuestion, currentQuestionIndex) {
            const t = this

            // Create a question object with the current question (object) and answer (string)
            let questionAnswerObj = {
                question: currentQuestion,
                answer: t.answer[currentQuestionIndex]
            }

            // Find the index of the current question
            const i = t.answeredQuestions.findIndex(question => question.question.id === currentQuestion.id)

            // If the index exists in the
            if (i === -1) {
                t.answeredQuestions.push(questionAnswerObj)
            } else {
                t.answeredQuestions[i] = questionAnswerObj
            }
        },

        /**
         * Submit Form
         *
         * Save the document with the collection name, question details and related answers.
         * If saving is successful SWAPPING IN will be enabled.
         *
         * @returns {Promise<void>}
         */
        async submitForm() {
            const t = this
            const numberOfQuestionsProvided = t.questionsData.length
            const numberOfQuestionsAnswered = t.answeredQuestions.length

            // To avoid having anonymous nested objects,
            // wrap the questions in an object which will hold the rest of the data
            let saveableObject = {
                questions: []
            }

            // If all the questions have been answered
            if (numberOfQuestionsAnswered === numberOfQuestionsProvided) {

                saveableObject.questions = t.answeredQuestions

                // Create a new document
                const createDocumentResult = await t.MIX_createDocument('answers', saveableObject)

                // Enable the Swapp button and close the form if the submission was successful
                if (createDocumentResult.code === 1) {

                    // Check to ensure all answers given are accepted
                    t.checkForBadAnswers()
                }

                // Call for the confirmation box
                t.renderConfirmationAlert(createDocumentResult)
            }

            // If all the questions have NOT been answered show an error message
            else {
                t.toggleErrorMessageVisibility()
            }
        },

        /**
         * Check For Bad Answers
         *
         * Check the given answers against the related question's correct answers to ensure they are acceptable.
         * If any of the answers are not acceptable, render a 'do not enter' message.
         */
        checkForBadAnswers() {
            const t = this
            const answerResults = [];

            // Iterate over the answered questions
            t.answeredQuestions.forEach(answeredQuestion => {
                // Iterate over the stock questions
                t.questionsData.forEach(question => {

                    // If it's the correct question
                    if (answeredQuestion.question.id === question.id) {

                        // Check if the answer is included in the correct answers
                        const isIncluded = question.questionCorrectAnswers.includes(answeredQuestion.answer)

                        // Good
                        if (isIncluded) {
                            answerResults.push('good')
                        }
                        // Bad
                        else {
                            answerResults.push('bad')
                        }
                    }
                })
            })

            // Handle good or bad results
            if (answerResults.includes('bad')) {
                t.handleFormAndButtonsVisibility('bad')
            } else {
                this.handleFormAndButtonsVisibility('good')
            }
        },

        /**
         * Toggle Error Message Visibility
         */
        toggleErrorMessageVisibility() {
            const t = this

            t.isErrorMessageVisible = true

            setTimeout(() => {
                t.isErrorMessageVisible = false
            }, 3000)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `Answer submitted`, null, null)
            } else {
                t.MIX_alert(-1, `Error submitting answer`, null, null)
            }
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getQuestionsCollectionData()
        await t.getAnswersCollectionData()

    }
}
</script>

<style>
.errorMessage {
    border-radius: 4px;
    animation: wobblee 3s;
}
/* Wobble the error message */
@keyframes wobblee {
    0% {
        transform: translateX(8px);
    }
    2% {
        transform: translateX(-8px);
    }
    4% {
        transform: translateX(4px);
    }
    6% {
        transform: translateX(-4px);
    }
    8% {
        transform: translateX(0);
    }
    90% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.home-form {
    animation: softDrop 1s;
}
/* Fade in the form */
@keyframes softDrop {
    0% {
        transform: translateY(32px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.flash {
    animation: flash 1s infinite;
}
</style>
