// * LOCATION STORE MODULE
const location_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        location: { show: false, collectionName: '', id: '', locations: [], siteDetails: [] },
        locationResult: {
            collectionName: '',
            id: '',
            value: '',
            siteDetails: ''
        } // date will be returned here
    },
    // * MUTATIONS
    mutations:{
        MUT_location(state, data) {
            state.location = data },
        MUT_locationResult(state, data) { state.locationResult = data }
    },
    // * GETTERS
    getters:{
        GET_location(state) {
            return state.location },
        GET_locationResult(state) { return state.locationResult }
    },
    // * ACTIONS
    actions: {
        ACT_location({commit}, payload){ commit('MUT_location', payload) },
        ACT_locationResult({commit}, payload){ commit('MUT_locationResult', payload) }
    }
};
export default location_store;
