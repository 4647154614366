<template>
    <div class="pa-4">

		<!--Title-->
        <app-text category="text-xlarge" class="primary--text">SETTINGS</app-text>

        <router-link to="/settings/lookups">Lookups</router-link>

    </div>
</template>

<script>
export default {

    mounted() {
        var breadcrumbs = [];
        breadcrumbs.push ({ name: `Settings`, path: `/settings/`})
        this.MIX_breadcrumbs(breadcrumbs);
    }
}
</script>
