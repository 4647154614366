// * CONFIRM BOX STORE MODULE
const confirmBox_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        confirmBox: { 
            show: false, 
            collection: '',
            id: '',
            field: '',
            title: '',
            description: '',
            width: '',
            height: '',
            trueButtonText: '',
            trueButtonValue: '',
            falseButtonText: '',
            falseButtonValue: '',
        },
        confirmBoxResult: {
            code: 0,
            message: null,
            data: null,
            error: null,
        }
    },
    // * MUTATIONS
    mutations:{
        MUT_confirmBox(state, data) { state.confirmBox = data },
        MUT_confirmBoxResult(state, data) { state.confirmBoxResult = data }
    },
    // * GETTERS
    getters:{
        GET_confirmBox(state) { return state.confirmBox },
        GET_confirmBoxResult(state) { return state.confirmBoxResult }
    },
    // * ACTIONS
    actions: {
        ACT_confirmBox({commit}, payload){ commit('MUT_confirmBox', payload) },
        ACT_confirmBoxResult({commit}, payload){ commit('MUT_confirmBoxResult', payload) }
    }
};
export default confirmBox_store;