<!--
Staff User Modal

Renders a Dialog component showing a User Card which opens to a bigger user pop up with the selected user's details
and options to either call or email them directly.
-->
<template>
	<v-container>
		<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			max-width="512">

			<!--Activator-->
			<template v-slot:activator="{ on, attrs }">

				<!--User Card-->
				<v-card v-on="on" class="d-flex align-center pr-4" height="96">

					<!--If an image is present, render it-->
					<div class="flex-grow-0">

						<v-img v-if="user.profilePicFileURL"
							   center
							   class="rounded-l"
							   height="96"
							   :src="user.profilePicFileURL"
							   width="96"/>

						<!--If an image is NOT present, render an icon-->
						<v-icon v-else
								class="icons8-customer"
								size="96"/>

					</div>

					<!--User details (name | position | telephone | location)-->
					<div class="flex-grow-1 justify-space-around px-4 py-2 sum-nameOverflow">

						<!--Name-->
						<app-text category="text-default-bold" class="mb-2">{{ user.userName }}</app-text>

						<!--Position-->
						<div class="d-flex align-center mb-2">
							<v-icon class="icons8-management mr-2" :color="iconColor" :size="iconSize"/>
							<app-text category="text-small" class="grey--text">{{ renderJobTitleText() }}</app-text>
						</div>

						<!--Location-->
						<div class="d-flex align-center">
							<v-icon class="icons8-qr-code ml-n1 mr-2" :color="swappStatus(user.swappStatus)"
									:size="iconSize + 5"/>
							<app-text v-if="user.lastLocation" category="text-small" class="grey--text">
								{{ locationText(status) }}
							</app-text>
						</div>

					</div>

				</v-card>

			</template>

			<!--Modal Content-->
			<v-card>

				<!--Toolbar-->
				<v-toolbar color="primary">

					<app-text category="text-medium" class="white--text">{{ user.userName }}</app-text>

					<v-spacer/>

					<v-btn @click="dialog = false" icon dark>
						<v-icon class="icons8-multiply" color="white"/>
					</v-btn>

				</v-toolbar>

				<!--Content-->
				<div>

					<!--Image | Buttons-->
					<div class="d-flex align-center pa-4">

						<!--If an image is present, render it-->
						<div class="mr-4" style="border: 1px solid lightgrey; border-radius: 4px">
							<v-img v-if="user.profilePicFileURL"
								   center
								   class="rounded"
								   height="128"
								   :src="user.profilePicFileURL"
								   width="128"/>

							<!--If an image is NOT present, render an icon-->
							<v-icon v-else
									class="icons8-customer"
									size="128"/>
						</div>

						<!--Buttons (call | email)-->
						<div class="d-flex justify-center" style="width: 100%">
							<div class="d-flex flex-column align-end">

								<!--Call button-->
								<v-btn @click="openTelephone(user.userTelephone)"
									   class="mb-8" color="white" depressed :height="buttonSizeDefault">
									<app-text category="text-default" class="grey--text mr-4">Call</app-text>
									<v-icon class="icons8-phone" color="primary" size="48"/>
								</v-btn>

								<!--Email button-->
								<v-btn @click="openEmail(user.userEmail)"
									   color="white" depressed :height="buttonSizeDefault">
									<app-text category="text-default" class="grey--text mr-4">Email</app-text>
									<v-icon class="icons8-email" color="primary" size="48"/>
								</v-btn>

							</div>
						</div>

					</div>

					<v-divider class="mx-4"/>

					<!--User details (position | telephone | location)-->
					<div class="pa-4 pb-1">

						<!--Position-->
						<div class="d-flex align-center mb-4">
							<v-icon class="icons8-management mr-4" :color="iconColor" :size="24"/>
							<app-text category="text-default">{{ renderJobTitleText() }}</app-text>
						</div>

						<!--Telephone-->
						<div class="d-flex align-center mb-4">
							<v-icon class="icons8-phone mr-4" :color="iconColor" :size="24"/>
							<app-text category="text-default">{{ user.userTelephone }}</app-text>
						</div>

						<!--Email-->
						<div class="d-flex align-center mb-4">
							<v-icon class="icons8-email mr-4" :color="iconColor" :size="24"/>
							<app-text category="text-default">{{ user.userEmail }}</app-text>
						</div>

						<!--Location-->
						<div class="d-flex align-center mb-4">
							<v-icon class="icons8-qr-code ml-n1 mr-4" :color="swappStatus(user.swappStatus)"
									:size="32"/>
							<app-text v-if="user.lastLocation" category="text-default" class="ml-n1 ">
								{{ locationText(user.swappStatus) }}
							</app-text>
						</div>

					</div>

				</div>

			</v-card>

		</v-dialog>
	</v-container>
</template>

<script>
export default {
	props: ['user'],

	data: () => ({
		dialog: false,
		iconSize: 20,
		iconColor: 'primary',
	}),

	methods: {
		/**
		 * Swapp Status
		 *
		 * Change the colour of the QR icon based on the swapp status.
		 * 1 (in) = green
		 * 0 (out) = blue
		 *
		 * @param status - the current swapp status of the user
		 * @returns {string} - colour for the swapp icon
		 */
		swappStatus(status) {
			return status === 1 ? 'success' : 'primary'
		},

		/**
		 * Location Text
		 *
		 * Render either the current location name, or 'out' based on the swapp status of the user.
		 *
		 * @returns {string}
		 */
		locationText() {
			const t = this

			return t.$props.user.swappStatus === 1 ? t.$props.user.lastLocation.locationName : '(out)'
		},

		/**
		 * Render Job Title Text
		 *
		 * Return a string for the job title of the user.
		 * If the user is staff, return their job title.
		 * If the user is a visitor, return 'Visitor' in place of a job title.
		 *
		 * @return jobTitle - String containing the user's job title
		 */
		renderJobTitleText() {
			const t = this
			let jobTitle

			if (t.$props.user.userLevel === 'VU') {
				jobTitle = 'Visitor'
			} else {
				jobTitle = t.$props.user.userJobTitle
			}

			return jobTitle
		},

		/**
		 * Open Telephone
		 *
		 * Open the device's call service to call the user.
		 *
		 * @param number - the number to call
		 */
		openTelephone(number) {
			window.open('tel:' + number)
		},

		/**
		 * Open Email
		 *
		 * Open the device's email service to email the user.
		 *
		 * @param email - the email to send to
		 */
		openEmail(email) {
			window.open('mailto:' + email)
		}
	}
}
</script>

<style>
.sum-nameOverflow {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
