<!-- The location component -->
<template>
    <v-dialog v-model="GET_location.show" scrollable max-width="500px">
        <v-card color="grey lighten-2">
            <v-card-title class="grey darken-3 white--text">Add location</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 250px;" class="mt-5">
                <v-row class=" ma-0 px-1" no-gutters>
                    <v-col class="pa-1" cols="12">
                        <v-text-field label="Location name" v-model="locationName" filled hide-details="auto" background-color="white" clearable></v-text-field>
                    </v-col>
                    <v-col class="pa-1" cols="12">
                        <v-textarea label="Description" v-model="description" filled hide-details background-color="white" dense auto-grow rows="4" clearable></v-textarea>
                    </v-col>
                    
                    collectionName: {{ GET_location.collectionName }} / id: {{ GET_location.id }} / locations: {{ GET_location.locations }}
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right">
                <v-spacer />
                <v-btn color="grey lighten-1" @click="closeLocation">Close</v-btn>
                <v-btn color="black white--text" @click="saveLocation">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        data: () => ({
            id: '',
            locationName: '',
            description: '',
            site: {},
        }),
        computed: {
            ...mapGetters({
                GET_location: 'location_store/GET_location',
                GET_locationResult: 'location_store/GET_locationResult'
            }),
        },
        methods: {
            ...mapActions({
                ACT_locationResult: 'location_store/ACT_locationResult',
            }),
            closeLocation() {
                this.MIX_location(false, ''); // Hide locations Dialog
            },
            saveLocation: async function () {
                var document = {}
                document.locationName = this.locationName;
                document.description = this.description;
                document.site = this.GET_location.siteDetails;
                var createDocumentResult = await this.MIX_createDocument('locations', document);
                if (createDocumentResult.code === 1) {
                    var locations = this.GET_location.locations // get existing locations
                    locations.push (createDocumentResult.data.id); // push new location
                    var updateDocumentResult = await this.MIX_updateDocumentFieldsById (this.GET_location.collectionName, this.GET_location.id, { locations: locations } )
                    if (updateDocumentResult.code === 1) {
                        this.MIX_alert(1, 'location Saved', createDocumentResult.data, null);
                        this.ACT_locationResult({ field: this.GET_location.field, value: this.location, id: this.GET_location.id, locationId: createDocumentResult.data.id }); // Save location
                        this.MIX_location(false, '', '');
                    } else {
                        this.MIX_alert(-1, 'ERROR - Could not save location', null, updateDocumentResult.error); 
                    }
                } else {
                    this.MIX_alert(-1, 'ERROR - Could not save location', null, createDocumentResult.error); 
                }
            }
        }
    }
</script>