<!--
User Profile

Render the user's profile and schedule data.
From here the user can adjust their details and also be advised on missing information.
-->
<template>
    <div>

        <!--Header (Title | Instructional text)-->
        <v-row class="ma-4" no-gutters>

            <!--Page title-->
            <page-title icon="icons8-person" page-title="My Profile"/>

            <!--Instructional text-->
            <app-text category="text-default">
                This is your profile.
                From here you can update your personal and work details, as well as your daily schedule.
            </app-text>

        </v-row>

        <v-divider class="ma-4"/>

        <!--User Form-->
        <v-row class="ma-4" no-gutters>

            <!--Missing fields | Action Buttons (Cancel | Edit)-->
            <v-col class="d-flex mb-4" cols="12">

                <!--Missing fields-->
                <div>
                    <app-text v-if="!form.usualSite" category="text-default-bold" class="red--text">You are missing a Primary
                        Work Location
                    </app-text>
                </div>

                <v-spacer/>

                <!--Cancel-->
                <v-btn v-if="formMode === 'edit'" @click.native="cancelDocument()"
                       color="grey"
                       depressed
                       :height="buttonSizeDefault">
                    <v-icon class="icons8-cancel" color="white"/>
                    <app-text category="text-default" class="white--text ml-2">Cancel</app-text>
                </v-btn>

                <!--Edit-->
                <v-btn v-if="formMode !== 'edit'" @click.native="editDocument()"
                       color="warning"
                       depressed
                       :height="buttonSizeDefault">
                    <v-icon class="icons8-edit-property" color="white"/>
                    <app-text category="text-default" class="white--text ml-2">Edit</app-text>
                </v-btn>

            </v-col>

            <!--Profile Picture | User Details-->
            <v-row no-gutters style="width: 100%">

                <!--Profile picture-->
                <v-col class="d-flex justify-center align-center pa-2 rounded white profileImage-container"
                       :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mt-1 mr-1' : 'mb-1'"
                       :cols="$vuetify.breakpoint.width < 600 && 12"
                       style="height: 232px; width: 232px">

                    <!--If an image is present, render it-->
                    <div v-if="form.profilePicFileURL && !tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="form.profilePicFileURL"
                               class="rounded" cover height="216" width="216"/>

                        <!--Upload button-->
                        <div v-if="formMode === 'edit'"
                             style="position: absolute; z-index: 9; margin-top: 152px">

                            <photoupload class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If a tempImage (upload preview) is present, render it-->
                    <div v-else-if="tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="tempImage"
                               class="rounded" cover height="216" width="216"/>

                        <!--Upload button-->
                        <div style="position: absolute; z-index: 9; margin-top: 152px">

                            <photoupload class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If neither an image or tempImage is present, render an icon-->
                    <div v-else class="d-flex flex-column align-center" style="width: 216px">

                        <!--Image-->
                        <v-icon class="icons8-customer" size="216"/>

                        <!--Upload button-->
                        <div v-if="formMode === 'edit'"
                             style="position: absolute; z-index: 9; margin-top: 152px">

                            <photoupload class="mr-n4"
                                         style="width: 100%"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                </v-col>

                <!-- User details -->
                <v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
                       :cols="$vuetify.breakpoint.width < 600 && 12">

                    <!--Name-->
                    <v-text-field
                        :background-color="formBackground"
                        class="pa-1"
                        dense
						:disabled="formReadOnly"
                        :error="!formReadOnly ? errors.userName : errors.userName = false"
                        :error-messages="errors.userName ? errors.userNameErrorMessage : ''"
                        filled
                        hide-details="auto"
                        label="Full Name"
                        v-model="form.userName"/>

                    <!--Job Title-->
                    <v-text-field
                        :background-color="formBackground"
                        class="pa-1"
                        dense
						:disabled="formReadOnly"
                        :error="!formReadOnly ? errors.userJobTitle : errors.userJobTitle = false"
                        :error-messages="errors.userJobTitle ? errors.userJobTitleErrorMessage : ''"
                        filled
                        hide-details="auto"
                        label="Job Title"
                        v-model="form.userJobTitle"/>

                    <!--Email-->
                    <v-text-field
                        :background-color="formBackground"
                        class="pa-1"
                        dense
						:disabled="formReadOnly"
                        :error="!formReadOnly ? errors.userEmail : errors.userEmail = false"
                        :error-messages="errors.userEmail ? errors.userEmailErrorMessage : ''"
                        filled
                        hide-details="auto"
                        label="Email"
                        v-model="form.userEmail"/>

                    <!--Telephone-->
                    <v-text-field
                        :background-color="formBackground"
                        class="pa-1"
                        dense
						:disabled="formReadOnly"
                        :error="!formReadOnly ? errors.userTelephone : errors.userTelephone = false"
                        :error-messages="errors.userTelephone ? errors.userTelephoneErrorMessage: ''"
                        filled
                        hide-details="auto"
                        label="Telephone"
                        type="number"
                        v-model="form.userTelephone"/>

                </v-col>

            </v-row>

            <!-- DOB -->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field @click="MIX_datePicker(true, 'userDOB')"
                              append-icon="mdi-calendar"
                              :background-color="formBackground"
                              dense
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userDOB : errors.userDOB = false"
                              :error-messages="errors.userDOB ? errors.userDOBErrorMessage: ''"
                              filled 
                              hide-details="auto"
                              label="Date Of Birth (optional)"
                              :readonly="formReadOnly"
                              v-model="formattedUserDOB">
                    {{ formatDOB }}
                </v-text-field>
            </v-col>

            <!-- Start Date -->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field @click="MIX_datePicker(true, 'userStartDate')"
                              append-icon="mdi-calendar" 
                              :background-color="formBackground" 
                              dense
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userStartDate : errors.userStartDate = false"
                              :error-messages="errors.userStartDate ? errors.userStartDateErrorMessage: ''" 
                              filled 
                              hide-details="auto"
                              label="Start Date (optional)"
                              :readonly="formReadOnly"
                              v-model="formattedStartDate">
                    {{ formatStartDate }}
                </v-text-field>
            </v-col>

            <!-- Primary Work Location -->
            <v-col class="pa-1" :class="!form.usualSite && 'red'"
                   cols="12" xs="12" sm="6" md="6" lg="6" xl="6">

                <v-select :background-color="formBackground"
                          dense
						  :disabled="formReadOnly"
                          :error="!formReadOnly ? errors.usualSite : errors.usualSite = false"
                          :error-messages="errors.usualSite ? errors.usualSiteErrorMessage: ''"
                          filled
                          hide-details="auto"
                          :items="sitesCollectionData"
                          item-text="siteName"
                          label="Primary Work Location"
                          v-model="form.usualSite">

                    <template v-slot:item="data">
                        <app-text category="text-default">{{ data.item.siteName }}</app-text>
                    </template>

                </v-select>

            </v-col>

            <!-- Address -->
            <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <app-text category="text-medium" class="darkgrey--text">Address</app-text>
                <v-divider class="my-2"/>
            </v-col>
            <!--Address Line 1-->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userAddressLine1 : errors.userAddressLine1 = false"
                              :error-messages="errors.userAddressLine1 ? errors.userAddressLine1ErrorMessage: ''"  
                              filled
                              hide-details="auto" 
                              label="Address Line 1 (optional)"
                              v-model="form.userAddress.userAddressLine1"/>
            </v-col>
            <!--Address Line 2-->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userAddressLine2 : errors.userAddressLine2 = false"
                              :error-messages="errors.userAddressLine2 ? errors.userAddressLine2ErrorMessage: ''" 
                              filled 
                              hide-details="auto" 
                              label="Address Line 2 (optional)"
                              v-model="form.userAddress.userAddressLine2"/>
            </v-col>
            <!--Address Line 3-->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userAddressLine3 : errors.userAddressLine3 = false"
                              :error-messages="errors.userAddressLine3 ? errors.userAddressLine3ErrorMessage: ''" 
                              filled
                              hide-details="auto" 
                              label="Address Line 3 (optional)"
                              v-model="form.userAddress.userAddressLine3"/>
            </v-col>
            <!--Town-->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userTown : errors.userTown = false"
                              :error-messages="errors.userTown ? errors.userTownErrorMessage: ''"  
                              filled 
                              hide-details="auto" 
                              label="Town (optional)"
                              v-model="form.userAddress.userTown"/>
            </v-col>
            <!--County-->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userCounty : errors.userCounty = false"
                              :error-messages="errors.userCounty ? errors.userCountyErrorMessage: ''"  
                              filled
                              hide-details="auto" 
                              label="County (optional)"
                              v-model="form.userAddress.userCounty"/>
            </v-col>
            <!--Postcode-->
            <v-col class="pa-1" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.userPostcode : errors.userPostcode = false"
                              :error-messages="errors.userPostcode ? errors.userPostcodeErrorMessage: ''"  
                              filled 
                              hide-details="auto"
                              label="Postcode (optional)"
                              v-model="form.userAddress.userPostcode"/>
            </v-col>

            <!-- Next of Kin -->
            <v-col class="mt-4 pa-1" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <app-text category="text-medium" class="darkgrey--text">Next of Kin</app-text>
                <v-divider class="my-2"/>
            </v-col>
            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field :background-color="formBackground" 
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.NOKName : errors.NOKName = false"
                              :error-messages="errors.NOKName ? errors.NOKNameErrorMessage: ''"    
                              filled 
                              hide-details="auto" 
                              label="Name (optional)" 
                              v-model="form.NOKName"
                              />
            </v-col>
            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field :background-color="formBackground"
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.NOKRelationship : errors.NOKRelationship = false"
                              :error-messages="errors.NOKRelationship ? errors.NOKRelationshipErrorMessage: ''" 
                              filled
                              hide-details="auto" 
                              label="Relationship (optional)"
                              v-model="form.NOKRelationship"/>
            </v-col>
            <v-col class="pa-1" cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <v-text-field :background-color="formBackground" 
							  :disabled="formReadOnly"
                              :error="!formReadOnly ? errors.NOKTelNo : errors.NOKTelNo = false"
                              :error-messages="errors.NOKTelNo ? errors.NOKTelNoErrorMessage: ''" 
                              filled
                              hide-details="auto"
                              label="Telephone Number (optional)"
                              type="number"
                              v-model="form.NOKTelNo"/>
            </v-col>

            <!--Action Buttons (Cancel | Save)-->
            <v-col class="d-flex mt-4" cols="12">

                <v-spacer/>

                <!--Save-->
                <v-btn v-if="formMode === 'edit' || formMode === 'new'" @click.native="validateForm()"
                       color="success"
                       depressed
                       :height="buttonSizeDefault">
                    <v-icon class="icons8-save"/>
                    <app-text category="text-default" class="ml-2">Save</app-text>
                </v-btn>

            </v-col>

        </v-row>

        <!--Page break-->
        <v-row class="primary ma-0" style="height: 8px"/>

        <!--Add the User Schedule component -->
        <user-schedule/>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import userSchedule from "../userSchedule/userSchedule";

export default {
    name: "userProfile",

    components: {
        userSchedule
    },

    data: () => ({
        errors: {
            userName: false,
            userNameErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
            userJobTitle: false,
            userJobTitleErrorMessage: '',
            userDOB: false,
            userDOBErrorMessage: '',
            userStartDate: false,
            userStartDateErrorMessage: '',
            usualSite: false,
            usualSiteErrorMessage: '',
            userAddressLine1: false,
            userAddressLine1ErrorMessage: '',
            userAddressLine2: false,
            userAddressLine2ErrorMessage: '',
            userAddressLine3: false,
            userAddressLine3ErrorMessage: '',
            userTown: false,
            userTownErrorMessage: '',
            userCounty: false,
            userCountyErrorMessage: '',
            userPostcode: false,
            userPostcodeErrorMessage: '',
            NOKName: false,
            NOKNameErrorMessage: '',
            NOKRelationship: false,
            NOKRelationshipErrorMessage: '',
            NOKTelNo: false,
            NOKTelNoErrorMessage: '',
        },

        formMode: 'view',
        formReadOnly: true,
        formBackground: 'grey lighten-3',
        form: {
            id: '',
            userName: '',
            userAddress: {
                userAddressLine1: '',
                userAddressLine2: '',
                userAddressLine3: '',
                userTown: '',
                userCounty: '',
                userPostcode: '',
            },
            NOKName: '',
            NOKRelationship: '',
            NOKTelNo: '',
            profilePicFileURL: null,
            userDOB: '',
            userEmail: '',
            userJobTitle: '',
            userLevel: '',
            userStartDate: '',
            userTelephone: '',
            usualSite: '',

            createdUserData: {},
            createdDateTime: '',
            modifiedUserData: {},
            modifiedDateTime: '',
        },

        formattedUserDOB: '',
        formattedStartDate: '',

        // Profile picture
        types: "image/*",
        storagePathProfilePic: "users-profile-pictures",
        photoUploadResult: {},
        tempImage: '',

        sitesCollectionData: [],

    }),

    computed: {
        ...mapGetters({
            GET_datePickerResult: "datePicker_store/GET_datePickerResult",
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Format DOB
         *
         * Convert the user's date of birth from a unix time to a readable format.
         */
        formatDOB() {
            const t = this

            t.formattedUserDOB = t.MIX_formatDateTime(t.form.userDOB, "x", "DD MMMM YYYY")
        },

        /**
         * Format Start Date
         *
         * Convert the user's start date from a unix time to a readable format.
         */
        formatStartDate() {
            const t = this

            t.formattedStartDate = t.MIX_formatDateTime(t.form.userStartDate, "x", "DD MMMM YYYY")
        },

    },

    methods: {

        /**
         * Get User Collection Data
         *
         * Fetch user collection data for the current user and sync it to the form.
         *
         * @returns {Promise<void>}
         */
        async getUserCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            if (doc.exists) {
                t.userCollectionData = doc.data()
                t.form = doc.data()
            } else {
                console.log('UserProfile error fetch ing user data')
            }

        },

        /**
         * Get Sites Collection Data
         *
         * Fetch sites collection data.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Clear the collection data to avoid duplications
                t.sitesCollectionData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesCollectionData.push(document)
                    }

                })
            })
        },

        /**
         * Edit Document
         *
         * Set the form to an editable state.
         */
        editDocument() {
            const t = this

            t.formMode = 'edit'
            t.formReadOnly = false
            t.formBackground = "white"
        },

        /**
         * Cancel Document
         *
         * Set the form to a viewable state and refresh the user data.
         *
         * @returns {Promise<void>}
         */
        async cancelDocument() {
            const t = this

            t.formMode = "view"
            t.formReadOnly = true
            t.formBackground = "grey lighten-3"
            t.tempImage = '';

            // Refresh user data
            await t.getUserCollectionData()
        },

        /**
         * Validate Form
         *
         * Validate the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const postCodeRegExp = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/
            const years = t.$moment().diff(Number(t.form.userDOB), 'years')

            t.errors.userName = false
            t.errors.userJobTitle = false
            t.errors.userEmail = false
            t.errors.userTelephone = false
            t.errors.userDOB = false
            t.errors.userStartDate = false
            t.errors.usualSite = false
            t.errors.userAddressLine1 = false
            t.errors.userAddressLine2 = false
            t.errors.userAddressLine3 = false
            t.errors.userTown = false
            t.errors.userCounty = false
            t.errors.userPostcode = false
            t.errors.NOKName = false
            t.errors.NOKRelationship = false
            t.errors.NOKTelNo = false

            // No name is present
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name is required'
            }

            // Name is too short or too long
            else if (t.form.userName.trim().length < 2 || t.form.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // Ensure the Name is trimmed
            else {
                t.form.userName = t.form.userName.trim()
            }

            // No job title is present
            if (!t.form.userJobTitle.trim()) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job title is required'
            }

            // Job title is too short
            else if (t.form.userJobTitle.trim().length < 2) {
                t.errors.userJobTitle = true
                t.errors.userJobTitleErrorMessage = 'Job title must be at least 2 characters long'
            }

            // Ensure the job title is trimmed
            else {
                t.form.userJobTitle = t.form.userJobTitle.trim()
            }

            // No email is present
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // Email is not valid
            else if (!/.+@.+\..+/.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }
            // Trim the email
            else {
                t.form.userEmail = t.form.userEmail.trim()
            }

            // No contact number is present
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // OPTIONAL - If selected, DOB must be over 16
            if (!t.form.userDOB) {
                t.errors.userDOB = false
            } else if (years < 16) {
                t.errors.userDOB = true
                t.errors.userDOBErrorMessage = 'You Must be over 16'
            }

            // No Primary Work Location selected
            if (!t.form.usualSite) {
                t.errors.usualSite = true
                t.errors.usualSiteErrorMessage = 'Primary Work Location is required'
            }
            
            // OPTIONAL - If 1st line of address is entered, it must be at least 2 characters long
            if(!t.form.userAddress.userAddressLine1) {
                t.errors.userAddressLine1 = false
            }
            else if (t.form.userAddress.userAddressLine1.trim().length < 2) {
                t.errors.userAddressLine1 = true
                t.errors.userAddressLine1ErrorMessage = '1st line of Address must be at least 2 characters long'
            } 
            else { 
                t.form.userAddress.userAddressLine1 = t.form.userAddress.userAddressLine1.trim()
            }

            // OPTIONAL - If 2nd line of address is entered, it must be at least 2 characters long
            if(!t.form.userAddress.userAddressLine2) {
                t.errors.userAddressLine2 = false
            }
            else if (t.form.userAddress.userAddressLine2.trim().length < 2) {
                t.errors.userAddressLine2 = true
                t.errors.userAddressLine2ErrorMessage = '2nd line of Address must be at least 2 characters long'
            }
            else { 
                t.form.userAddress.userAddressLine2 = t.form.userAddress.userAddressLine2.trim()
            }

            // OPTIONAL - If 3rd line of address is entered, it must be at least 2 characters long
            if(!t.form.userAddress.userAddressLine3) {
                t.errors.userAddressLine3 = false
            }
            else if (t.form.userAddress.userAddressLine3.trim().length < 2) {
                t.errors.userAddressLine3 = true
                t.errors.userAddressLine3ErrorMessage = '3rd line of Address must be at least 2 characters long'
            }
            else {
                t.form.userAddress.userAddressLine3 = t.form.userAddress.userAddressLine3.trim()
            }

            // OPTIONAL - If town is entered, it must be at least 2 characters long
            if (!t.form.userAddress.userTown) {
                t.errors.userTown = false
            }
            else if (t.form.userAddress.userTown.trim().length < 2) {
                t.errors.userTown = true
                t.errors.userTownErrorMessage = 'Town must be at least 2 characters long'
            }
            else {
                t.form.userAddress.userTown = t.form.userAddress.userTown.trim() 
            }

            // OPTIONAL - If county is entered, it must be at least 2 characters long
            if (!t.form.userAddress.userCounty) {
                t.errors.userCounty = false
            }
            else if (t.form.userAddress.userCounty.trim().length < 2) {
                t.errors.userCounty = true
                t.errors.userCountyErrorMessage = 'County must be at least 2 characters long'
            }
            else {
                t.form.userAddress.userCounty = t.form.userAddress.userCounty.trim()
            }

            // OPTIONAL - If Postcode is entered, it must be in a valid format
            if (!t.form.userAddress.userPostcode) {
                t.errors.userPostcode = false
            } else if (!t.form.userAddress.userPostcode.trim() || !postCodeRegExp.test(t.form.userAddress.userPostcode)) {
                t.errors.userPostcode = true
                t.errors.userPostcodeErrorMessage = 'Postcode is not in the correct format'
            }

            // OPTIONAL - Next of kin name too short
            if (!t.form.NOKName) {
                t.errors.NOKName = false
            } else if (t.form.NOKName.trim().length < 2) {
                t.errors.NOKName = true
                t.errors.NOKNameErrorMessage = 'Next of kin name must be at least 2 characters long'
            } else {
                t.form.NOKName = t.form.NOKName.trim()
            }

            // OPTIONAL - No Next of kin relationship
            if (!t.form.NOKRelationship) {
                t.errors.NOKRelationship = false
            } else if (t.form.NOKRelationship.trim().length < 2) {
                t.errors.NOKRelationship = true
                t.errors.NOKRelationshipErrorMessage = 'Next of kin relationship must be at least 2 characters long'
            } else {
                t.form.NOKRelationship = t.form.NOKRelationship.trim()
            }
            
            // OPTIONAL - Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            if ((t.form.NOKTelNo && ['1', '2', '3'].includes(t.form.NOKTelNo.trim()[1])) && (t.form.NOKTelNo.trim().length < 10 || t.form.NOKTelNo.trim().length > 11)) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (t.form.NOKTelNo && ['7'].includes(t.form.NOKTelNo.trim()[1]) && t.form.NOKTelNo.trim().length !== 11) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (t.form.NOKTelNo && ['0', '4', '5', '6', '8', '9'].includes(t.form.NOKTelNo[1])) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (t.form.NOKTelNo && t.form.NOKTelNo.length < 2) {
                t.errors.NOKTelNo = true
                t.errors.NOKTelNoErrorMessage = 'Please enter a correct telephone number'
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Update the user document.
         * Set the form to a viewable state.
         * Call to upload the profile picture if one has been selected.
         * Refresh the user data.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            const updateDocumentResult = await this.MIX_updateDocument('users', t.form)

            if (updateDocumentResult.code === 1) {

                t.formBackground = "grey lighten-3"
                t.formMode = "view"
                t.formReadOnly = true
                t.tempImage = ''

                // Add uploaded profile image
                await t.uploadProfileImage()

                // Refresh user data
                await t.getUserCollectionData()

                t.MIX_go('/')

            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(updateDocumentResult, 'Profile successfully updated', 'Error updating profile')

        },

        /**
         * Upload Profile Image
         *
         * Update the user's document with a profile image path  (collection | user id | image path).
         *
         * @returns {Promise<void>}
         */
        async uploadProfileImage() {
            const t = this

            if (t.photoUploadResult !== {}) {

                // Save to the document with: collection | user id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

                // Call for a confirmation alert
                t.renderConfirmationAlert(updatePhotosResult, 'Photo successfully updated', 'Error updating photo')

            }
        },

        /**
         * Render Confirmation Alert
         *
         * Take the result of a document db change and render a confirmation box to the user.
         */
        renderConfirmationAlert(document, successMessage, failureMessage) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, successMessage, null, null)
            } else {
                t.MIX_alert(-1, failureMessage, null, document.error)
            }

        },

    },

    watch: {

        /**
         * Date Picker Result
         *
         * Watch for the result of the date picker and assign the required values.
         */
        GET_datePickerResult: {
            handler() {
                const t = this

                switch (t.GET_datePickerResult.field) {

                    case "userDOB":
                        t.formattedUserDOB = t.MIX_formatDateTime(
                            t.GET_datePickerResult.value, "x", "DD-MMM-YYYY")
                        t.form.userDOB = t.GET_datePickerResult.value
                        break;

                    case "userStartDate":
                        t.formattedStartDate = t.MIX_formatDateTime(
                            t.GET_datePickerResult.value, "x", "DD-MMM-YYYY");
                        t.form.userStartDate = t.GET_datePickerResult.value
                        break;

                    default:
                        console.log('User Profile date not recognised')

                }
            },
            deep: true,
        },

        /**
         * Photo Upload Result
         *
         * Watch for the result of a profile picture upload and add its storage path to the photoUploadResult variable.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                t.photoUploadResult = t.GET_photoUploadResult
                t.tempImage = t.photoUploadResult.fileURL

            }, deep: true
        },

    },

    async mounted() {
        const t = this

        // Fetch required collection data
        await t.getUserCollectionData()
        await t.getSitesCollectionData()

    }
}
</script>
