<!-- The datePicker component -->
<template>
    <v-dialog
        ref="dialog"
        :return-value.sync="date"
        v-model="GET_datePicker.show"
        width="300">

        <v-date-picker color="primary" v-model="date" :max="maxDate">

            <v-spacer/>

            <v-btn @click="GET_datePicker.show = false"
                   color="primary"
                   text>
                Cancel
            </v-btn>

            <v-btn @click="$refs.dialog.save(date)"
                   color="primary"
                   text>
                OK
            </v-btn>

        </v-date-picker>

    </v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {

    data: () => ({
        date: new Date().toISOString().substr(0, 10),
        maxDate: new Date().toISOString().substr(0, 10)
    }),

    computed: {
        ...mapGetters({
            GET_datePicker: 'datePicker_store/GET_datePicker',
            GET_datePickerResult: 'datePicker_store/GET_datePickerResult'
        }),
    },

    methods: {
        ...mapActions({
            ACT_datePickerResult: 'datePicker_store/ACT_datePickerResult',
        })
    },

    watch: {
        date: {
            handler() {
                this.ACT_datePickerResult({
                    field: this.GET_datePicker.field,
                    value: this.$moment(this.date, 'YYYY-MM-DD').format('x')
                })
            }, deep: true
        }
    }

}
</script>
