<!--
User Schedule Set All Dialog

Renders a Set All button in UserSchedule which open up into a dialog box wth a selection of options.
When the user has selected an option it is emitted back to the parent component (UserSchedule) for setting.
-->
<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			max-width="512">

			<!--Activator-->
			<template v-slot:activator="{ on, attrs }">

				<div class="ussad-activatorButton-container pr-0">
					<v-btn color="primary"
						   depressed
						   :height="buttonSizeDefault"
						   v-on="on">
						<app-text category="text-default">Set All</app-text>
					</v-btn>
				</div>

			</template>

			<!--Dialog content-->
			<v-card>

				<!--Toolbar-->
				<v-toolbar color="primary">

					<app-text category="text-medium" class="white--text ml-2">Set all days to...</app-text>

					<v-spacer/>

					<v-btn @click="dialog = false" icon dark>
						<v-icon class="icons8-multiply" color="white"/>
					</v-btn>

				</v-toolbar>

				<!--Body-->
				<div class="ussad-body-container">

					<!--Instructional text-->
					<app-text category="text-default">
						By selecting an option below, all the days of the week in your schedule will be automatically
						set
					</app-text>

					<v-divider class="my-4"/>

					<!--Options selection-->
					<form-field-container-with-label title="Options">
						<v-select background-color="grey lighten-3"
								  filled
								  hide-details="auto"
								  :items="$props.scheduleOptions"
								  item-text="siteName"
								  v-model="selectedOption">
						</v-select>
					</form-field-container-with-label>

				</div>

				<v-spacer/>

				<!--Accept button-->
				<div class="ussad-acceptButton-container">
					<v-btn @click="setSelectedOption"
						   color="primary"
						   :disabled="!selectedOption"
						   :height="buttonSizeDefault">
						<v-icon class="icons8-checkmark-yes"/>
						<app-text category="text-default" class="ml-2">Set All</app-text>
					</v-btn>
				</div>

			</v-card>

		</v-dialog>
	</div>
</template>

<script>
import formFieldContainerWithLabel from '../../../components/commonComponents/formFieldContainerWithLabel'

export default {
	props: ['scheduleOptions'],

	components: {
		formFieldContainerWithLabel
	},

	data: () => ({
		dialog: false,
		selectedOption: '',
	}),

	methods: {
		/**
		 * Set Selected Option
		 *
		 * Take the newly selected Set All option and send it back to the parent component (UserSchedule).
		 */
		setSelectedOption() {
			const t = this

			// Emit the selection back to UserSchedule
			t.$emit('updateUserScheduleSetAll', t.selectedOption)

			// Close the dialog box
			t.dialog = false
		}
	}

}
</script>

<style>
.ussad-activatorButton-container {
	display: flex;
	justify-content: flex-end;

	padding: 0 16px;
}

.ussad-body-container {
	padding: 16px;
}

.ussad-acceptButton-container {
	display: flex;
	justify-content: flex-end;

	padding: 0 16px 16px 16px;
}
</style>
