const confirmation_mixins = {
  methods: {
    MIX_showConfirmation (show, title, message, size, trueButton, falseButton, actionDetails){
      var payload = {};
      payload.show = show;
      payload.title = title;
      payload.message = message;
      payload.size = size;
      payload.trueButton = trueButton;
      payload.falseButton = falseButton;
      payload.actionDetails = actionDetails;
      this.$store.commit("confirmation_store/setConfirmation",payload);
    },

    MIX_decisionConfirmation (answer){
      var payloadD = {};
      payloadD.answer = answer;
      this.$store.commit("confirmation_store/setDecision",payloadD);
    },

  }
}

export default {
  install (Vue, options) {
    Vue.mixin(confirmation_mixins)
  }
};