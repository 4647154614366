<!--
SWAPP

After successfully logging in on a mobile device, this view is rendered activating the camera with some instructions.
The camera is used to read the location's QR code to SWAPP in and out.
After successfully SWAPPING, a message (results) is rendered advising the user of the action.

The user has the option to SWAPP manually if they're having any issues with the QR code,
by selecting a site and location. This will mark their SWAPP as 'manual'.
-->
<template>
    <div style="width: 100%">

        <!--
        Display QR Code Reader
        -->
        <div class="mt-n4" v-if="!isTroubleSwappingVisible && !showSwappMessage">

            <!-- QR code reader component -->
            <qrCodeReader/>

            <!-- Instructions -->
            <div class="ma-4">
                <app-text category="text-large" class="primary--text text-uppercase mb-4">
                    SWAPP {{ $route.params.direction }}
                </app-text>
                <app-text category="text-default" class="mb-2">1. Allow your camera when prompted</app-text>
                <app-text category="text-default" class="mb-2">2. Point your camera at the poster</app-text>
                <app-text category="text-default">3. Make sure the QR Code is visible in the box above</app-text>
            </div>

        </div>

        <!--
        Manual SWAPPing
        -->
        <div v-if="!showSwappMessage" class="ma-4">

            <!--Can't SWAPP In Button-->
            <v-btn @click="isTroubleSwappingVisible = !isTroubleSwappingVisible"
                   color="white" depressed :height="buttonSizeDefault">
                {{ swappTypeMessage }}
            </v-btn>

            <!--
            Manual SWAPPING
            -->
            <v-col v-if="$vuetify.breakpoint.mdAndDown"
                   class="pa-0 mb-4" cols="12" xs="12" sm="8">

                <v-card v-if="isTroubleSwappingVisible"
                        class="mt-4 pa-4" flat>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text">
                        {{ troubleSwappingTitle }}
                    </app-text>

                    <!--Instruction-->
                    <app-text category="text-default" class="mt-4">
                        If you're having trouble SWAPPING {{ $route.params.direction }} using the QR code, select the
                        site from the list below.
                    </app-text>

                    <!--Manual Swapp reasons-->
                    <v-select class="mt-4"
                              background-color="white"
                              filled
                              hide-details
                              :items="manualSwappReasons"
                              label="Reason?"
                              required
                              @change="handleSiteSelection"
                              v-model="manualSwappReason"/>

                    <!--Site selector-->
                    <v-select v-if="$route.params.direction.toUpperCase() !== 'OUT' && manualSwappReason"
                              class="mt-4"
                              background-color="white"
                              filled
                              hide-details
                              :items="sitesData"
                              item-text="siteName"
                              label="Sites"
                              required
                              return-object
                              @change="handleSiteSelection"
                              v-model="selectedSite"/>

                    <!--Site selector-->
                    <v-select v-if="locationsDataRenderable && locationsDataRenderable.length > 1"
                              class="mt-4"
                              background-color="white"
                              :error="hasLocationSelectionError"
                              filled
                              hide-details="auto"
                              :items="locationsDataRenderable"
                              item-text="locationName"
                              label="Locations"
                              required
                              return-object
                              v-model="selectedLocation"/>

                    <!--Site Name-->
                    <app-text v-if="selectedSite.siteName && selectedSite.siteName"
                              category="text-default-bold" class="mt-4">
                        {{ selectedSite.siteName }}
                    </app-text>

                    <!--
                    Site Address
                     - Line 1 | Town
                    -->
                    <div v-if="selectedSite.siteAddress" class="d-flex align-start mt-4">

                        <v-icon class="icons8-home mr-4" color="primary"/>

                        <div class="d-flex flex-column">

                            <!--Address line 1-->
                            <app-text
                                v-if="selectedSite.siteAddress && selectedSite.siteAddress.addressLine1"
                                category="text-default">
                                {{ selectedSite.siteAddress.addressLine1 }},
                            </app-text>

                            <!--Town-->
                            <app-text
                                v-if="selectedSite.siteAddress && selectedSite.siteAddress.town && selectedSite.siteAddress.town"
                                category="text-default" class="mt-4">
                                {{ selectedSite.siteAddress.town }}
                            </app-text>

                        </div>

                    </div>

                    <!--
                    Site Contact
                     - Contact name | Contact mobile number
                    -->
                    <app-text v-if="selectedSite.siteContact" category="text-default-bold" class="mt-4">
                        Site Manager
                    </app-text>
                    <div v-if="selectedSite.siteContact" class="d-flex align-start mt-4">

                        <v-icon class="icons8-person mr-4" color="primary"/>

                        <div class="d-flex flex-column">

                            <!--Contact Name-->
                            <app-text
                                v-if="selectedSite.siteContact && selectedSite.siteContact.contactName && selectedSite.siteContact.contactName"
                                category="text-default">
                                {{ selectedSite.siteContact.contactName }}
                            </app-text>

                            <!--Contact Number-->
                            <app-text
                                v-if="selectedSite.siteContact.contactTelephone"
                                category="text-default" class="mt-4">
                                {{ selectedSite.siteContact.contactTelephone }}
                            </app-text>
                        </div>

                    </div>

                    <!--Location Name (if applicable)-->
                    <div v-if="selectedLocation.locationName" class="d-flex align-start mt-4">

                        <v-icon class="icons8-marker mr-4" color="accent"/>

                        <div class="d-flex flex-column">

                            <app-text
                                v-if="selectedLocation && selectedLocation.locationName"
                                category="text-default">
                                {{ selectedLocation.locationName }}
                            </app-text>

                        </div>

                    </div>

                    <!--SWAPP in/out Button-->
                    <div v-if="$route.params.direction.toUpperCase() === 'OUT'">
                        <v-btn v-if="manualSwappReason" @click="manuallySwappInOut"
                               block class="mt-4" color="primary" :height="buttonSizeDefault">
                            SWAPP {{ $route.params.direction }} {{ $route.params.direction === 'in' ? 'to' : 'from' }}
                            this site
                        </v-btn>
                    </div>
                    <div v-else>
                        <v-btn v-if="selectedSite.siteName" @click="manuallySwappInOut"
                               block class="mt-4" color="primary" :height="buttonSizeDefault">
                            SWAPP {{ $route.params.direction }} {{ $route.params.direction === 'in' ? 'to' : 'from' }}
                            this site
                        </v-btn>
                    </div>

                </v-card>

            </v-col>

        </div>

        <!--
        Display QR Code Result Message -->
        <div class="text-center ma-4" v-if="showSwappMessage">

            <app-text category="text-default-bold" class="justify-center mb-4">You have been</app-text>
            <app-text category="text-large" class="primary--text justify-center">SWAPP'd
                <span class="text-uppercase">{{ $route.params.direction }}</span></app-text>

            <v-icon class="success--text" size="256">icons8-checkmark-yes</v-icon>

            <app-text category="text-default" class="justify-center text-uppercase">
                {{ $route.params.direction === 'in' ? 'to' : 'from' }}
            </app-text>

            <div v-if="locationDetails != null && locationDetails != undefined">
                <app-text category="text-large" class="primary--text justify-center text-uppercase mt-4">
                    {{ locationDetails.locationName }}
                </app-text>
            </div>

            <!-- Home button -->
            <div class="mt-4">

                <!--If SWAPPing in, go to the Dashboard-->
                <v-btn v-if="$route.params.direction === 'in'" @click.native="MIX_go('/')"
                       color="primary" :height="buttonSizeDefault">
                    Home
                </v-btn>

                <!--If SWAPPing out, go to the Home page-->
                <v-btn v-else @click.native="MIX_go('/')" color="primary" :height="buttonSizeDefault">
                    Home
                </v-btn>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Swapp",

    data: () => ({
        locationDetails: [], // store the qr code location result
        form: {}, // store the curr user details
        hasLocationSelectionError: false,
        isManualSwapp: false,
        isTroubleSwappingVisible: false,
        locationsData: [],
        locationsDataRenderable: [],
        selectedLocation: '',
        selectedSite: [],
        showSwappMessage: false, // show qr code reader/result
        sitesData: [],
        userData: {},

        manualSwappReasons: ['Already left site', 'Cannot find QR code', 'Camera not working', 'Unable to scan QR code'],
        manualSwappReason: '',
    }),

    computed: {
        ...mapGetters({
            GET_QR_Result: 'qr_code_reader_store/GET_getResult', // import qr code result from store
            GET_currentUser: 'GET_currentUser',
        }),

        //check route direction value and return
        returnSwappValue() {
            const t = this

            if (t.$route.params.direction === 'in') {
                return 1
            } else {
                return 0
            }
        },

        /**
         * Swapp Type Message
         *
         * Returns a message for the manual SWAPP type button based on the selection of manual SWAPPing.
         *
         * @return {String} - the text for the button
         */
        swappTypeMessage() {
            const t = this
            let message = ''

            if (t.isTroubleSwappingVisible) {
                message = `${'SWAPP ' + t.$route.params.direction + ' with QR code'}`
            } else {
                message = `${'Can\'t SWAPP ' + t.$route.params.direction}?`
            }

            return message
        },

        troubleSwappingTitle() {
            const t = this
            let message = ''

            if (t.$route.params.direction.toUpperCase() === 'IN') {
                message = 'Having Trouble SWAPPing in?'
            } else if (t.$route.params.direction.toUpperCase() === 'OUT') {
                message = `Having Trouble SWAPPING out from ${t.userData.lastLocation.locationName}?`
            }

            return message
        }

    },

    methods: {

        /**
         * Get Sites Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')

            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.sitesData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only push documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document)
                    }
                })
            })
        },

        /**
         * Get Locations Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites > Locations collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getLocationsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('locations')

            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.locationsData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only push documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.locationsData.push(document)
                    }
                })
            })
        },

        /**
         * Get User Collection Data
         *
         * Clear the table data to avoid duplications.
         * Fetch the current user's data from the users collection.
         *
         * @returns {Promise<void>}
         */
        async getUserCollectionData() {
            const t = this

            // Get collection data for the current user
            const collection = t.$firebase.db.collection('users').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            if (doc.exists) {
                t.userData = doc.data()
            } else {
                console.log('error fetch user document')
            }

        },

        /**
         * Handle Site Selection
         *
         * Triggered by a Site selection, sets the currently selected site.
         */
        handleSiteSelection() {
            const t = this
            let locationsArray = []

            t.selectedLocation = ''

            // If a site has more than one location
            if (t.selectedSite?.locations?.length > 1) {

                // Iterate over the selected site's locations
                t.selectedSite.locations.forEach(siteLocation => {

                    // Iterate over the locations data
                    t.locationsData.forEach(location => {

                        // If any of the locations match the siteLocation, add it to the array
                        if (siteLocation === location.id) {
                            locationsArray.push(location)
                        }
                    })
                })

                // Set the locations to be renderable in the select field
                t.locationsDataRenderable = locationsArray
            }

            // If a site only has a single location, render it
            else {
                t.locationsDataRenderable = []
                t.selectedLocation = t.selectedSite?.locations[0]
            }
        },

        /**
         * Manually Swapp In
         *
         * Set to manual swapping and call to getSiteLocation (site>location) with the manually selected location.
         * As the user should be SWAPPing out of the same location they're SWAPPed in to,
         * don't ask for the location, take it from the user data instead.
         *
         * @returns {Promise<void>}
         */
        async manuallySwappInOut() {
            const t = this
            let locationId = ''

            // If SWAPPing out, take the last location id from the user's data
            if (t.$route.params.direction.toUpperCase() === 'OUT') {
                locationId = t.userData.lastLocation.locationId
            }
            else if (t.selectedLocation.id) {
                locationId = t.selectedLocation.id
            }
            else {
                locationId = t.selectedLocation
            }

            // If no location has been selected, display an error
            if (!locationId) {
                t.hasLocationSelectionError = true
            } else {
                t.isManualSwapp = true
                await t.getSiteLocation(locationId)
            }
        },

        /**
         * Get Site Location
         *
         * Using either a manual ID or the result from the QR code, get the related location data.
         * If it reads ok, set locationDetails which will call the corresponding watch function.
         *
         * @param manualLocationId - the ID of the location from a manual selection
         * @returns {Promise<void>}
         */
        async getSiteLocation(manualLocationId) {
            const t = this

            // If SWAPPing manually, use the manual location id
            if (manualLocationId) {

                const locationResult = await t.MIX_readDocumentById('locations', manualLocationId);

                if (locationResult.code === 1) {
                    t.locationDetails = locationResult.data;
                } else {
                    console.log('locationResult : ' + locationResult.message)
                }
            }

            // If SWAPPing with the QR code get the QR results
            else if (t.GET_QR_Result !== null || t.GET_QR_Result !== '' || t.GET_QR_Result !== undefined) {

                const locationResult = await t.MIX_readDocumentById('locations', t.GET_QR_Result);

                if (locationResult.code === 1) {
                    t.locationDetails = locationResult.data;
                } else {
                    console.log('locationResult : ' + locationResult.message)
                }
            }
        },

        /**
         * SWAPP
         *
         * Populate the form based on the values selected.
         * Save record to DB.
         *
         * @returns {Promise<void>}
         */
		async swapp() {
			const t = this
			const fields = {}

			fields.lastLocation = {}
			fields.lastSite = {}

			// t.form = t.GET_currentUser; // assign current logged in user to our local var form
			fields.lastLocation.locationId = t.locationDetails.id; // set locationId
			fields.lastLocation.locationName = t.locationDetails.locationName; // set locationName
			fields.lastSite.siteId = t.locationDetails.site.id; // set siteId
			fields.lastSite.siteName = t.locationDetails.site.siteName; // setSiteName
			fields.swappStatus = t.returnSwappValue; // set Swapp status
			fields.swappMethod = t.isManualSwapp ? 'Manual' : 'QRphone';
			fields.lastLocation.manualSwappReason = t.manualSwappReason

			//update the swapp status in the user document
			const updateDocumentResult = await t.MIX_updateDocumentFieldsById('users', t.GET_currentUser.id, fields)

			if (updateDocumentResult.code === 1) {
				t.MIX_alert(1, 'Swapp Status Updated', updateDocumentResult.data, null);
				t.showSwappMessage = true; // show success message
			} else {
				t.MIX_alert(-1, 'ERROR - Could not update Status', null, updateDocumentResult.error);
				t.showSwappMessage = false; // show error message
			}
		}
    },

    watch: {

        // Watch for a change in the QR CODE RESULT
        GET_QR_Result: {
            handler() {
                this.getSiteLocation(); // trigger get location details based on qr code result
            }, deep: true
        },

        // Watch for a change in the location result
        locationDetails: {
            handler() {
                this.swapp(); // trigger update swapp status when a location is returned
            },
            deep: true
        }
    },

    /**
     * Mounted
     *
     * Pre-load the required data before components render to the screen.
     *
     * @returns {Promise<void>}
     */
    async mounted() {
        const t = this

        // Get collection data
        await t.getSitesCollectionData()
        await t.getLocationsCollectionData()
        await t.getUserCollectionData()

        t.MIX_setQrCode(''); // prevents cached values for qr codes
    }
}
</script>
