import Vue from "vue";

const alert_mixins = {
  data: () => ({
    alertTimeout: 5000, // change the timeout if you want it to appear for longer / shorter
    alertColor: 'error',
  }),
  methods: {
    MIX_alert(code, message, data, error) {
      var payload = {};
      switch (code) {
        case -1:
          this.alertColor = 'error'; // edit this colour in vuetify theme
          break;
        case 0:
          this.alertColor = 'warning'; // edit this colour in vuetify theme
          break;
        case 1:
          this.alertColor = 'success'; // edit this colour in vuetify theme
          break;
        case 2:
          this.alertColor = 'info'; // edit this colour in vuetify theme
      }
      payload.color = this.alertColor;
      payload.timeout = this.alertTimeout;
      payload.message = message;
      payload.data = data;
      payload.error = error;
      // payload.positionTop = positionTop;
      // payload.positionBot = positionBot;
      payload.show = true;
      this.$store.commit("alertbox_store/setAlert", payload);
    }
  },
//   // ! this function is not working
//   // TODO
//   mix_hideAlert() {
//     var payload = {};
//     payload.show = true;
//     this.$store.commit("alertbox_store/setAlert", payload);
//   }
}

export default {
  install (Vue, options) {
    Vue.mixin(alert_mixins)
  }
};




