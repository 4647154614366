<!--
HOME

Renders the home screen which contains the following:
	- Named greeting
	- Welcome instructional message
	- Complete Profile (user must have usualSite and schedule)
	- Covid Screening questions
	- SWAPP button (in or out, depending on status)
	- Schedule (overview of week with link to update)
	- Log IT Support
	- (Admin only) Users Awaiting Approval

-->
<template>
    <v-row class="ma-2" no-gutters>

        <!--
        Welcome User
         - Render welcome message 'Welcome [first name]'
         -->
        <v-col class="pa-2 pb-0" cols="12" xs="12" sm="12" md="12" lg="12">
            <app-text category="text-large" class="primary--text">Welcome
                <span class="accent--text">{{ GET_currentUser.userName.split(" ")[0] }}</span>
            </app-text>
        </v-col>

        <!--Left side-->
        <v-col class="px-2 pa-4" :cols="splitWindowWidth">

            <!--
            Getting started
             - Render the welcome message
            -->
            <div v-if="$vuetify.breakpoint.width >= 590
		                || ($vuetify.breakpoint.width < 590 && isGettingStartedMessageVisible)"
                 class="pa-0">

                <v-card flat class="pa-4">

                    <!--
                    Title | Close
                    -->
                    <div class="d-flex align-center mb-4">

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text">Getting Started</app-text>

                        <v-spacer/>

                        <!--Close icon-->
                        <v-btn v-if="$vuetify.breakpoint.width < 600" @click="handleHideWelcomeText"
                               icon class="pa-0" color="primary" depressed>
                            <v-icon class="icons8-multiply" color="primary"/>
                        </v-btn>

                    </div>

                    <!--Greeting-->
                    <app-text category="text-default" class="mb-4">
                        Welcome to the {{ $t('global.companyName') }} Safe Workplace App (SWAPP).
                        We are just getting started, so you can expect more features soon.
                    </app-text>

                    <!--Instructions-->
                    <app-text category="text-default">
                        To begin, SWAPP In at your place of work using the button below,
                        and don't forget to SWAPP Out when you leave.
                    </app-text>

                    <!--Instructions-->
                    <app-text v-if="$vuetify.breakpoint.width >= 840" category="text-default-bold" class="mt-4">
                        Please note: You must log in via mobile to be able to SWAPP in and out
                    </app-text>

                </v-card>

            </div>

            <!--Complete profile-->
            <div v-if="GET_currentUser.userLevel !== 'VU' && (!GET_currentUser.usualSite || scheduleError)"
                 class="mt-4 pa-0">

                <v-card flat class="d-flex flex-column pa-4">

                    <!--Icon | Title -->
                    <div class="d-flex">

                        <!--Icon-->
                        <v-icon class="icons8-private ma-n1" color="error" size="48"/>

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text ml-4">
                            Complete Your Profile
                        </app-text>

                    </div>

                    <!--Message-->
                    <app-text category="text-default" class="mt-4">
                        Please update your profile before you continue.
                    </app-text>

                    <!--Button-->
                    <div class="text-right mt-4">
                        <v-btn @click.native="MIX_go('/userProfile')"
                               color="primary"
                               :height="buttonSizeDefault">
                            <app-text category="text-default">Update Profile</app-text>
                        </v-btn>
                    </div>

                </v-card>

            </div>

            <!--
            Covid Screening Questions
             - Mobile users must successfully answer the Covid questions before accessing the site
             - If the Covid questions are active, the 'disabled' property of the SWAPP IN button must
             be toggled to include the '!isSwappEnabled' condition
            -->
            <covid-questions v-on:isSwappEnabled="emittedIsSwappEnabled"/>

            <!--
            SWAPP In
             - Render the SWAPP IN section with QR code and button
            -->
            <div v-if="GET_currentUser.swappStatus === 0 && $vuetify.breakpoint.width < 840"
                 class="pa-0 mt-4">
                <v-card class="d-flex flex-column ma-0 pa-4" flat>

                    <!--Icon | Title-->
                    <div class="d-flex">

                        <!--QR code-->
                        <v-icon class="icons8-qr-code ma-n1" color="success" size="48"/>

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text ml-4">
                            SWAPP In
                        </app-text>

                    </div>

                    <!--Message-->
                    <app-text category="text-default" class="mt-4">
                        When you arrive at Site you must SWAPP In at a location.
                    </app-text>

                    <!--Button-->
                    <!--
                    If the Covid questions are active, the 'disabled' property of this button must be toggled to
                    include the '!isSwappEnabled' condition
                    -->
					<!-- If user is a Visitor, remove the need to fill out schedule before using SWAPP  -->
					<div v-if="GET_currentUser.userLevel === 'VU'" class="text-right mt-4">
						<v-btn @click.native="MIX_go('/swapp/in')"
							   color="primary"
							   :disabled="!isSwappEnabled"
							   :height="buttonSizeDefault">
							<app-text category="text-default">SWAPP IN</app-text>
						</v-btn>
					</div>

                    <div v-else class="text-right mt-4">
                        <v-btn @click.native="MIX_go('/swapp/in')"
                               color="primary"
                               :disabled="scheduleError || !isSwappEnabled"
                               :height="buttonSizeDefault">
                            <app-text category="text-default">SWAPP IN</app-text>
                        </v-btn>
                    </div>

                </v-card>
            </div>

            <!--
            SWAPP Out
             - Render the SWAPP OUT section with QR code and button
            -->
            <div v-if="GET_currentUser.swappStatus === 1 && $vuetify.breakpoint.width < 840"
                 class="pa-0 mt-4">
                <v-card class="d-flex flex-column ma-0 pa-4" flat>

                    <!--Icon | Title-->
                    <div class="d-flex">

                        <!--QR code-->
                        <v-icon class="icons8-qr-code ma-n1" color="success" size="48"/>

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text ml-4">
                            SWAPP Out
                        </app-text>

                    </div>

                    <!--Message-->
                    <app-text category="text-default" class="mt-4">
                        When you leave a Site you must SWAPP out of the Location.
                    </app-text>

                    <!--Button-->
                    <div class="mt-4 text-right">
                        <v-btn @click.native="MIX_go('/swapp/out')"
                               color="primary"
                               :height="buttonSizeDefault">
                            <app-text category="text-default">SWAPP OUT</app-text>
                        </v-btn>
                    </div>

                </v-card>
            </div>

            <!--Schedule-->
            <div v-if="GET_currentUser.userLevel !== 'VU'" class="pa-0 mt-4">
                <v-expansion-panels flat>
                    <v-expansion-panel>

                        <!--Icon | Title | Text -->
                        <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                            <!--Icon | Title -->
                            <div class="d-flex">

                                <!--Icon-->
                                <v-icon class="icons8-schedule ma-n1" color="primary" size="48"/>

                                <!--Title-->
                                <app-text category="text-medium-bold" class="primary--text ml-4">
                                    Schedule
                                </app-text>

                            </div>

                            <!--Text-->
                            <app-text v-if="!scheduleError" category="text-default-bold" class="mt-4">
                                <span class="font-weight-regular">Tomorrow - </span>{{ tomorrowsScheduleData }}
                            </app-text>
                            <app-text v-else category="text-default" class="mt-4">
                                {{ scheduleError }}
                            </app-text>

                            <app-text v-if="!scheduleError" category="text-default" class="mt-4">
                                (Click to see more)
                            </app-text>

                        </v-expansion-panel-header>

                        <!--Mon-Fri schedule-->
                        <v-expansion-panel-content class="expansion-panel-content-container">

                            <!--Mon-Fri-->
                            <div style="display: grid; grid-template-columns: auto 1fr;">
                                <span class="grey--text mr-4">Mon:</span>{{ scheduleData.monday }}<br>
                                <span class="grey--text">Tue:</span>{{ scheduleData.tuesday }}<br>
                                <span class="grey--text">Wed:</span>{{ scheduleData.wednesday }}<br>
                                <span class="grey--text">Thu:</span>{{ scheduleData.thursday }}<br>
                                <span class="grey--text">Fri:</span>{{ scheduleData.friday }}<br>
                            </div>

                            <!--Update Button-->
                            <div class="mt-4 text-right">
                                <v-btn @click.native="MIX_go('/userSchedule')"
                                       color="primary"
                                       :height="buttonSizeDefault">
                                    <app-text category="text-default">Update Schedule</app-text>
                                </v-btn>
                            </div>

                        </v-expansion-panel-content>

                    </v-expansion-panel>
                </v-expansion-panels>
            </div>

            <!--IT Support-->
            <div v-if="GET_currentUser.userLevel !== 'VU'" class="pa-0 mt-4">
                <v-card flat class="d-flex flex-column pa-4 ma-0">

                    <!--Icon | Title-->
                    <div class="d-flex">

                        <!--Icon-->
                        <v-icon class="icons8-computer-support ma-n1" color="primary" size="48"/>

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text ml-4">
                            Log IT Support
                        </app-text>

                    </div>

                    <!--Message-->
                    <app-text category="text-default" class="mt-4">
                        Request technical support.
                    </app-text>

                    <!--Button-->
                    <div class="mt-4 text-right">
                        <v-btn @click.native="MIX_go('/itSupport')"
                               color="primary" :height="buttonSizeDefault">
                            <app-text category="text-default">Request Support</app-text>
                        </v-btn>
                    </div>

                </v-card>
            </div>

        </v-col>

        <!--Right side (Awaiting approval)-->
        <v-col v-if="GET_currentUser.userLevel === 'SA'"
               :class="$vuetify.breakpoint.width < 840 ? 'mb-4 px-2' : 'px-2 pa-4'" :cols="splitWindowWidth">
            <v-expansion-panels flat>
                <v-expansion-panel>

                    <!--Icon | Title | Text -->
                    <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                        <!--Icon | Title -->
                        <div class="d-flex">

                            <!--Icon-->
                            <v-icon class="icons8-user-clock ma-n1" color="primary" size="48"/>

                            <!--Title-->
                            <app-text category="text-medium-bold" class="primary--text ml-4">
                                Awaiting Approval
                            </app-text>

                        </div>

                        <!--Message-->
                        <app-text category="text-default" class="mt-4">
                            {{ usersAwaitingApprovalMessage }}
                        </app-text>

                    </v-expansion-panel-header>

                    <!--Awaiting approval-->
                    <v-expansion-panel-content class="expansion-panel-content-container">

                        <!--Users-->
                        <div v-for="user in usersAwaitingApproval" class="mb-4">

                            <v-divider class="mb-4"/>

                            <app-text category="text-default-bold">{{ user.userName }}</app-text>
                            <app-text category="text-default">{{ user.userJobTitle }}</app-text>
                            <app-text category="text-default">{{ user.userEmail }}</app-text>
                            <app-text category="text-default">{{ user.userTelephone }}</app-text>

                        </div>

                        <v-divider class="my-4"/>

                        <!--Action button-->
                        <div class="d-flex">

                            <v-spacer/>

                            <v-btn @click="MIX_go('staff-directory')" color="primary" depressed :height="buttonSizeDefault">
                                <app-text category="text-default">Show Accounts</app-text>
                            </v-btn>

                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>

    </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import covidQuestions from "./commonComponents/covidQuestions";

export default {
    name: "Home",

    components: {
        covidQuestions,
    },

    data: () => ({
        collection: 'answers',
        collectionItemTitle: 'Answer',
        isErrorMessageVisible: false,
        isGettingStartedMessageVisible: true,
        isPhotoUploadDisabled: false,
        isSwappEnabled: false,
        scheduleData: [],
        scheduleError: '',
        sitesData: [],
        tomorrowsScheduleData: {},
        usersCollectionData: [],
        usersUsualSite: '',

        update: 0,
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Split Window Width
         *
         * Split the layout either 50/50 or 100 based on the screen width.
         *
         * @returns width - number of columns each side (left and right) should span
         */
        splitWindowWidth() {
            const t = this
            let width

            if (t.$vuetify.breakpoint.width <= 840) {
                width = 12
            } else {
                width = 6
            }

            return width
        },

        /**
         * Users Awaiting Approval Message
         *
         * Take the number of users awaiting approval and return a grammatically correct string to render.
         *
         * @returns message - message to render to screen
         */
        usersAwaitingApprovalMessage() {
            const t = this
            let message

            if (t.usersCollectionData.length === 1) {
                message = `You have ${t.usersAwaitingApproval.length} account awaiting approval`
            } else {
                message = `You have ${t.usersAwaitingApproval.length} accounts awaiting approval`
            }

            return message
        },

        /**
         * Users Awaiting Approval
         *
         * Filter all users and return an array containing just those with a 'PENDING' status, awaiting approval.
         *
         * @returns array - array containing users with a 'PENDING' status
         */
        usersAwaitingApproval() {
            const t = this

            return t.usersCollectionData.filter(user => user.userStatus.toUpperCase() === 'PENDING')
        }
    },

    methods: {
        /**
         * Check Local Storage
         *
         * Check the local storage for the presence of 'isGettingStartedMessageVisible'.
         * If a value is held, it will be to NOT render the message.
         */
        checkLocalStorage() {
            const t = this

            if (localStorage.isGettingStartedMessageVisible) {
                t.isGettingStartedMessageVisible = false
            }
        },

        /**
         * Handle Hide Welcome Text
         *
         * If the welcome message has been closed, add to local storage to not render it again.
         */
        handleHideWelcomeText() {
            const t = this

            localStorage.isGettingStartedMessageVisible = 'hide'
            t.isGettingStartedMessageVisible = false
        },

        /**
         * Get Sites Collection
         *
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Reset sites to avoid duplications
                t.sitesData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document)
                    }
                })
            })
        },

        /**
         * Get Schedule Collection
         *
         * Fetch the current user's data from the Schedule collection.
         * If there is a schedule present, call for tomorrows data to render.
         * If there is no schedule present, set a message.
         *
         * @returns {Promise<void>}
         */
        async getScheduleCollectionData() {
            const t = this

            // Get collection data for the current user
            const collection = t.$firebase.db.collection('schedule').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            // If the data exists, set it and call for tomorrow's schedule
            if (doc.exists) {
                t.scheduleData = doc.data()
                t.getTomorrowsScheduleData(doc.data())
            }

            // If the data does not exist, set an error
            else {
                t.scheduleError = 'Sorry, couldn\'t find your schedule'
            }
        },

        /**
         * Get Tomorrow's Schedule Data
         *
         * The schedule card's text needs to show where the user is the next day (tomorrow).
         * Iterate over the schedule data and show the next working day (mon-fri)
         *
         * @param scheduleData - the current users work schedule
         */
        getTomorrowsScheduleData(scheduleData) {
            const t = this

            // Get the day tomorrow
            const tomorrowsDate = t.$moment().add(1, 'day').format('dddd')

            // If tomorrow doesn't fall on the weekend
            if (['Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(tomorrowsDate)) {

                // Iterate over the schedule
                for (const [key, value] of Object.entries(scheduleData)) {

                    // If tomorrow is in the schedule data, set the value
                    if (key.toUpperCase() === tomorrowsDate.toUpperCase()) {
                        t.tomorrowsScheduleData = value
                    }
                }
            }

            // If tomorrow falls on the weekend, set it to Monday
            else {
                t.tomorrowsScheduleData = scheduleData.monday
            }
        },

        /**
         * Toggle Error Message Visibility
         */
        toggleErrorMessageVisibility() {
            const t = this

            t.isErrorMessageVisible = true

            setTimeout(() => {
                t.isErrorMessageVisible = false
            }, 3000)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} submitted`, null, null)
            } else {
                t.MIX_alert(-1, `Error submitting ${t.collectionItemTitle}`, null, null)
            }
        },

        /**
         * Get Users Collection Data
         *
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapshot => {

                // Rest data to avoid duplications
                t.usersCollectionData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

					if (t.GET_currentUser.id === document.id) {

						// Set usual site so the Complete Profile card doesn't hang
						if (document.usualSite) {
							t.GET_currentUser.usualSite = document.usualSite
						}

						// Update the user
						t.$store.commit('SET_currentUser', document)
					}

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.usersCollectionData.push(document)
                    }

                })
            })
        },

        /**
         * Emitted Is Swapp Enabled
         *
         * Payload is emitted from covidQuestions.vue and is a boolean based on the answers to the questions.
         * In this component, it is used to determine whether or not the SWAPP IN button is clickable.
         */
        emittedIsSwappEnabled(payload) {
            const t = this

            t.isSwappEnabled = payload
        }

    },

    watch: {
        /**
         * Photo Upload Result
         *
         * On upload of a profile picture, add its storage path to the current user's document.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this
                const photoUploadResult = this.GET_photoUploadResult

                // If there is data, update the current user's profile picture
                if (photoUploadResult !== {}) {

                    // Save to the document with: collection | user id | image path
                    const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                        'users', photoUploadResult.docLink, {profilePicFileURL: photoUploadResult.fileURL})

                    // Render confirmation box
                    if (updatePhotosResult.code === 1) {
                        t.isPhotoUploadDisabled = true
                        this.MIX_alert(1, 'Photo Added', updatePhotosResult.data, null)
                    } else {
                        this.MIX_alert(-1, 'Error Adding Photo', null, updatePhotosResult.error)
                    }
                }
            }, deep: true
        },
    },

    /**
     * Mounted
     *
     * Pre-load the required data before components render to the screen.
     *
     * @returns {Promise<void>}
     */
    async mounted() {
        const t = this

        // Get collection data
        await t.getScheduleCollectionData()
        await t.getSitesCollectionData()
        await t.getUsersCollectionData()

        // Check local storage for UI preferences
        t.checkLocalStorage()
    },
};
</script>

<style>
.expansion-panel-content-container > div {
    padding: 16px;
}
</style>
