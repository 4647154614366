// * DATE PICKER STORE MODULE
const datePicker_store = {
    namespaced: true, //to be case sencitive
    // * STATE
    state: {
        datePicker: { show: false, field: '' },
        datePickerResult: {
            field: '',
            value: ''
        } // date will be returned here
    },
    // * MUTATIONS
    mutations:{
        MUT_datePicker(state, data) { state.datePicker = data },
        MUT_datePickerResult(state, data) { state.datePickerResult = data }
    },
    // * GETTERS
    getters:{
        GET_datePicker(state) { return state.datePicker },
        GET_datePickerResult(state) { return state.datePickerResult }
    },
    // * ACTIONS
    actions: {
        ACT_datePicker({commit}, payload){ commit('MUT_datePicker', payload) },
        ACT_datePickerResult({commit}, payload){ commit('MUT_datePickerResult', payload) }
    }
};
export default datePicker_store;